export const API_ENDPOINT_HTTP =
	import.meta.env.VITE_APP_API_BASE_URL ?? "http://localhost:8000";

export const API_ENDPOINT_WS =
	import.meta.env.VITE_APP_API_ENDPOINT_WS ?? "ws://localhost:8000";

export const IS_DEV = import.meta.env.DEV;

export const CLERK_PUBLISHABLE_KEY =
	import.meta.env.VITE_APP_CLERK_PUBLISHABLE_KEY ??
	new Error("Missing Clerk Publishable Key");
