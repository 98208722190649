import { FileUploadsDrawer } from "@/components/FileUploadsDrawer";
import { Layout } from "@/components/Layout";
import { PageErrorBoundaryFallback } from "@/components/PageErrorBoundaryFallback";
import { API_ENDPOINT_HTTP, CLERK_PUBLISHABLE_KEY, IS_DEV } from "@/config";
import { AppProvider } from "@/contexts/AppContext";
import "@/index.css";
import { FeedChannel } from "@/pages/FeedChannel";
import { Library } from "@/pages/Library";
import { ManageFeeds } from "@/pages/ManageFeeds";
import { Research } from "@/pages/Research";
import { Search } from "@/pages/Search";
import { Tables } from "@/pages/Tables";
import { ViewPublicChat } from "@/pages/ViewPublicChat";
import {
	ClerkProvider,
	RedirectToSignIn,
	SignedIn,
	SignedOut,
	useAuth,
	useUser,
} from "@clerk/clerk-react";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Toaster } from "sonner";

if (!IS_DEV) {
	Sentry.init({
		dsn: "https://50cd796e3f9bffb42b53324657fe45f8@o4504119928291328.ingest.us.sentry.io/4507584710180864",
		integrations: [
			Sentry.browserTracingIntegration(),
			// Sentry.replayIntegration(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			"localhost",
			/^https:\/\/greenmantle-api.village\.dev/,
			/^https:\/\/greenmantle-dev.village\.dev/,
		],
		// Session Replay
		// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
axios.defaults.baseURL = API_ENDPOINT_HTTP;

let usedInterceptor = false;

// from https://github.com/nextauthjs/next-auth/discussions/3550
export function useInterceptor() {
	const { getToken } = useAuth();

	if (usedInterceptor) return;

	axios.interceptors.request.use(async (config) => {
		const token = await getToken();
		config.headers.Authorization = `Bearer ${token}`;

		return config;
	});

	usedInterceptor = true;
}

const Root = () => {
	useInterceptor();

	const { user } = useUser();
	const identified = useRef(false);

	useEffect(() => {
		if (user && !identified.current) {
			identified.current = true;

			Sentry.setUser({
				id: user.id,
				email: user.primaryEmailAddress?.emailAddress,
			});
		}
	}, [user]);

	return (
		<BrowserRouter>
			<AppProvider>
				<TooltipProvider>
					<Toaster />
					<FileUploadsDrawer />
					<Layout>
						<Routes>
							<Route path="/" element={<Navigate to="/search" replace />} />
							<Route
								path="/search"
								element={
									<ErrorBoundary
										fallback={(props) => (
											<PageErrorBoundaryFallback {...props} />
										)}
										showDialog
									>
										<Search />
									</ErrorBoundary>
								}
							/>
							<Route
								path="/research/:activeChatId?"
								element={
									<ErrorBoundary
										fallback={(props) => (
											<PageErrorBoundaryFallback {...props} />
										)}
										showDialog
									>
										<Research />
									</ErrorBoundary>
								}
							/>
							<Route
								path="/tables"
								element={
									<ErrorBoundary
										fallback={(props) => (
											<PageErrorBoundaryFallback {...props} />
										)}
										showDialog
									>
										<Tables />
									</ErrorBoundary>
								}
							/>
							<Route
								path="/share/:chatId"
								element={
									<ErrorBoundary
										fallback={(props) => (
											<PageErrorBoundaryFallback {...props} />
										)}
										showDialog
									>
										<ViewPublicChat />
									</ErrorBoundary>
								}
							/>
							<Route
								path="/library/:activeFolderId?"
								element={
									<ErrorBoundary
										fallback={(props) => (
											<PageErrorBoundaryFallback {...props} />
										)}
										showDialog
									>
										<Library />
									</ErrorBoundary>
								}
							/>
							<Route
								path="/feeds"
								element={
									<ErrorBoundary
										fallback={(props) => (
											<PageErrorBoundaryFallback {...props} />
										)}
										showDialog
									>
										<ManageFeeds />
									</ErrorBoundary>
								}
							/>
							<Route
								path="/feed/:feedChannelId/:feedItemId?"
								element={
									<ErrorBoundary
										fallback={(props) => (
											<PageErrorBoundaryFallback {...props} />
										)}
										showDialog
									>
										<FeedChannel />
									</ErrorBoundary>
								}
							/>
						</Routes>
					</Layout>
				</TooltipProvider>
			</AppProvider>
		</BrowserRouter>
	);
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
			<SignedOut>
				<RedirectToSignIn />
			</SignedOut>
			<SignedIn>
				<Root />
			</SignedIn>
		</ClerkProvider>
	</React.StrictMode>,
);
