type FaviconArgs = {
	url: string;
	alt: string;
} & React.HTMLAttributes<HTMLImageElement>;

export const Favicon = ({ url, alt, ...props }: FaviconArgs) => {
	return (
		<img
			{...props}
			src={`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=256`}
			alt={alt}
		/>
	);
};
