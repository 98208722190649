import { Favicon } from "@/components/Favicon";
import type { FeedChannel } from "@api/schemas";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";

export const FeedLink = observer(
	({
		feedChannel,
	}: {
		feedChannel: FeedChannel;
	}) => {
		return (
			<NavLink
				className={({ isActive }) =>
					clsx(
						"group relative flex w-full min-w-0 shrink-0 items-center justify-between gap-1 truncate rounded-lg p-1 text-xs",
						isActive
							? "bg-neutral-200 text-neutral-900"
							: "text-neutral-600 hover:bg-neutral-200 hover:text-neutral-900",
					)
				}
				to={`/feed/${feedChannel.feed_channel_id}`}
				key={feedChannel.feed_channel_id}
			>
				{feedChannel.feed_channel_link ? (
					<Favicon
						url={feedChannel.feed_channel_link}
						alt={feedChannel.file_name}
						className="h-6 w-6 shrink-0 rounded-md bg-neutral-200"
					/>
				) : (
					<div className="h-6 w-6 shrink-0 rounded-md bg-neutral-300" />
				)}
				<div className="min-w-0 grow truncate rounded-md border border-transparent">
					{feedChannel.file_name}
				</div>
			</NavLink>
		);
	},
);
