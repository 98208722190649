import { LibraryPDFViewer } from "@/components/PDFViewer";
import { Drawer, DrawerContent } from "@/components/ui/drawer";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable";
import { LibraryProvider, useLibraryContext } from "@/contexts/LibraryContext";
import { DirectoryListing } from "@/pages/Library/DirectoryListing";
import { useMediaQuery } from "@uidotdev/usehooks";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

const _Library = observer(() => {
	const libraryContext = useLibraryContext();
	const { selectedUpload } = libraryContext;
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

	if (isSmallDevice) {
		return (
			<div className="h-full w-full min-w-0 grow">
				<DirectoryListing />
				<Drawer
					open={!!selectedUpload}
					onOpenChange={(open) => {
						if (!open) {
							runInAction(() => {
								libraryContext.activeUploadId = null;
							});
						}
					}}
				>
					<DrawerContent className="h-[90%]">
						<div
							className={
								"relative mt-4 flex h-full min-h-0 flex-col items-center"
							}
						>
							{!!selectedUpload && <LibraryPDFViewer upload={selectedUpload} />}
						</div>
					</DrawerContent>
				</Drawer>
			</div>
		);
	}

	return (
		// Important: the min-w-0 and relative classes are necessary to make the
		// resizable panels work correctly with the toggleable sidebar
		<div className="relative flex h-full w-full flex-col items-center">
			<ResizablePanelGroup direction="horizontal">
				<ResizablePanel minSize={25} order={1}>
					<div className="h-full w-full min-w-0 grow">
						<DirectoryListing />
					</div>
				</ResizablePanel>
				{selectedUpload && (
					<>
						<ResizableHandle />
						<ResizablePanel minSize={25} order={2}>
							<div className="relative flex h-full min-h-0 w-full min-w-0 flex-col items-center">
								<LibraryPDFViewer upload={selectedUpload} />
							</div>
						</ResizablePanel>
					</>
				)}
			</ResizablePanelGroup>
		</div>
	);
});

export const Library = () => {
	return (
		<LibraryProvider>
			<Helmet>
				<title>Library - Village</title>
			</Helmet>
			<_Library />
		</LibraryProvider>
	);
};
