import { UploadCoverImage } from "@/components/UploadCoverImage";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { useAppContext } from "@/contexts/AppContext";
import type {
	AnyDirectoryNode,
	FolderDirectoryNode,
	UploadDirectoryNode,
} from "@/contexts/AppContext/TreeHandlers";
import {
	DocumentSelectorProvider,
	useDocumentSelectorContext,
} from "@/contexts/SourceSelectorContext";
import type { FolderId, UploadId } from "@/idGenerators";
import { formatTitle } from "@/lib/utils";
import type { Folder, Upload } from "@api/schemas";
import { FolderOpen, FolderSimple } from "@phosphor-icons/react";
import clsx from "clsx";
import { autorun, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { type NodeApi, type NodeRendererProps, Tree } from "react-arborist";
import useResizeObserver from "use-resize-observer";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import { Skeleton } from "./ui/skeleton";

const UploadPreview = observer(() => {
	const documentSelectorContext = useDocumentSelectorContext();

	const upload = documentSelectorContext.previewedUpload;

	if (!upload) {
		return (
			<div className="flex h-full min-h-64 w-full flex-col items-center justify-center space-y-2 overflow-y-scroll border-l bg-neutral-100 p-2 text-neutral-500 text-sm">
				Select an upload to preview it here
			</div>
		);
	}

	return (
		<div
			className="flex h-full min-h-64 flex-col overflow-y-scroll border-l p-4"
			key={upload.upload_id}
		>
			<div className="p-4">
				<UploadCoverImage
					size={512}
					upload_id={upload.upload_id as UploadId}
					upload_status={upload.upload_status}
					className={() => "mx-auto h-64 max-w-full rounded"}
				/>
			</div>

			<div>
				<h1 className="font-semibold text-base text-neutral-800">
					{formatTitle({
						title: upload.upload_title,
						subtitle: upload.upload_subtitle,
						filename: upload.file_name,
					})}
				</h1>
			</div>
			<div className="mt-2 flex flex-col">
				<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
					<h3>Title</h3>
					<span className="text-right font-semibold">
						{upload.upload_title}
					</span>
				</div>
				<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
					<h3>Subtitle</h3>
					<span className="text-right font-semibold">
						{upload.upload_subtitle}
					</span>
				</div>
				<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
					<h3>Filename</h3>
					<span className="text-right font-semibold">{upload.file_name}</span>
				</div>
				<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
					<h3>Publisher</h3>
					<span className="text-right font-semibold">
						{upload.upload_publisher}
					</span>
				</div>
				<div className="flex justify-between space-x-1 py-1 text-neutral-950 text-xs">
					<h3>Year Published</h3>
					<span className="text-right font-semibold">
						{upload.upload_year_published}
					</span>
				</div>
			</div>
		</div>
	);
});

export const FolderNode = observer(
	({
		node,
		folder,
	}: { node: NodeApi<FolderDirectoryNode>; folder: Folder }) => {
		const documentSelectorContext = useDocumentSelectorContext();
		const appContext = useAppContext();

		// const children = node.select;

		const descendants = appContext.getFolderDescendants(
			folder.folder_id as FolderId,
		);
		const numDescendantsSelected = descendants.uploadIds.filter((descendant) =>
			documentSelectorContext.selectedUploadIds.has(descendant),
		).length;

		return (
			<div
				className={clsx(
					"group flex h-full w-full items-center rounded-md px-2 py-1",
				)}
			>
				<div className="flex grow items-center gap-1.5">
					<Checkbox
						checked={numDescendantsSelected === descendants.uploadIds.length}
						onCheckedChange={(checked) => {
							if (checked) {
								for (const descendant of descendants.uploadIds) {
									documentSelectorContext.selectedUploadIds.add(
										descendant as UploadId,
									);
								}
							} else {
								runInAction(() => {
									for (const descendant of descendants.uploadIds) {
										documentSelectorContext.selectedUploadIds.delete(
											descendant as UploadId,
										);
									}
								});
							}
						}}
						className={clsx(
							numDescendantsSelected === descendants.uploadIds.length
								? "opacity-100"
								: "opacity-0 group-hover:opacity-100",
						)}
					/>
					<button
						type="button"
						onClick={() => {
							node.toggle();
						}}
						className="flex w-4 items-center justify-center"
					>
						{node.isOpen ? (
							<FolderOpen
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						) : (
							<FolderSimple
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						)}
					</button>
					<span className="text-sm">{folder.file_name}</span>
				</div>
			</div>
		);
	},
);

export const UploadNode = observer(
	({
		node,
		upload,
	}: { node: NodeApi<UploadDirectoryNode>; upload: Upload }) => {
		const documentSelectorContext = useDocumentSelectorContext();
		const isSelected = documentSelectorContext.selectedUploadIds.has(
			upload.upload_id as UploadId,
		);

		const onSelect = () => {
			runInAction(() => {
				documentSelectorContext.previewedUploadId =
					upload.upload_id as UploadId;
			});
		};

		return (
			<div
				className={clsx(
					"group flex h-full w-full items-center rounded-md px-2 py-1",
					node.isSelected ? "bg-blue-100" : "hover:bg-blue-50",
				)}
				key={upload.upload_id}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<div className="flex min-w-0 grow items-center gap-1.5 truncate pr-2 text-left">
					<Checkbox
						checked={isSelected}
						onCheckedChange={(checked) => {
							if (checked) {
								runInAction(() => {
									documentSelectorContext.selectedUploadIds.add(
										upload.upload_id as UploadId,
									);
								});
							} else {
								runInAction(() => {
									documentSelectorContext.selectedUploadIds.delete(
										upload.upload_id as UploadId,
									);
								});
							}
						}}
						className={clsx(
							isSelected ? "opacity-100" : "opacity-0 group-hover:opacity-100",
						)}
					/>
					<div className="flex w-4 shrink-0 justify-center">
						<UploadCoverImage
							className={(uploadStatus) =>
								clsx(
									"h-4 max-w-6 rounded-xs",
									uploadStatus === "ready" && "shadow-lg",
								)
							}
							upload_id={upload.upload_id as UploadId}
							upload_status={upload.upload_status}
							size={128}
						/>
					</div>

					<div className="min-w-0 truncate">
						<h2 className="flex min-w-0 items-center gap-1 truncate text-sm leading-4">
							{formatTitle({
								title: upload.upload_title,
								subtitle: upload.upload_subtitle,
								filename: upload.file_name,
							})}
						</h2>
					</div>
				</div>
			</div>
		);
	},
);

const LibraryTreeNode = observer(
	({ node, style, dragHandle }: NodeRendererProps<AnyDirectoryNode>) => {
		return (
			<div
				style={style}
				ref={dragHandle}
				className="mr-2 ml-2 flex h-8 items-center pt-0.5"
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						node.toggle();
					}
				}}
			>
				{(new Array(node.level).fill(0) as number[]).map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="mr-2 ml-0.5 h-8 w-9 shrink-0 border-neutral-200 border-r-2"
					/>
				))}
				{node.data.item.type === "folder" ? (
					<FolderNode node={node} folder={node.data.item.data} />
				) : (
					<UploadNode node={node} upload={node.data.item.data} />
				)}
			</div>
		);
	},
);

interface MultiSelectProps {
	selectedUploadIds: Set<UploadId>;
	onChange: React.Dispatch<UploadId[]>;
	popoverTrigger: React.ReactNode;
	className?: string;
}

const _UploadSelector: React.FC<MultiSelectProps> = observer(
	({ selectedUploadIds, onChange, popoverTrigger, className, ...props }) => {
		const appContext = useAppContext();
		const documentSelectorContext = useDocumentSelectorContext();
		const [open, setOpen] = React.useState(false);
		const { ref, height } = useResizeObserver<HTMLDivElement>();

		const [uploadsQuery, setUploadsQuery] = useState("");

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const matchedUploadIds = useMemo(() => {
			return appContext.searchUploadsByMetadata(uploadsQuery);
		}, [uploadsQuery]);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			return autorun(() => {
				onChange(Array.from(documentSelectorContext.selectedUploadIds));
			});
		}, [documentSelectorContext]);

		return (
			<Dialog open={open} onOpenChange={setOpen} {...props}>
				<DialogTrigger asChild>
					<Button
						variant="ghost"
						aria-expanded={open}
						onClick={() => setOpen(!open)}
						className={"w-full p-0"}
					>
						{popoverTrigger}
					</Button>
				</DialogTrigger>
				<DialogContent
					className="flex max-w-screen-md overflow-hidden p-0"
					style={{
						width: "var(--radix-popover-content-available-width)",
						height: "var(--radix-popover-content-available-height)",
					}}
				>
					<div className="flex h-[50vh] w-screen">
						<div className="flex max-h-full min-h-0 w-2/3 flex-col">
							<div className="w-full px-2 pt-2">
								<Input
									placeholder="Search uploads..."
									value={uploadsQuery}
									onChange={(e) => {
										setUploadsQuery(e.target.value);
									}}
								/>
							</div>
							<div className="max-h-full min-h-0 grow" ref={ref}>
								{appContext.rootDirectoryNodes ? (
									<Tree
										data={appContext.rootDirectoryNodes}
										idAccessor={(node) => node.node_id}
										childrenAccessor={(node) =>
											appContext.treeChildrenAccessor(node)
										}
										openByDefault={false}
										width={"100%"}
										height={height}
										rowHeight={32}
										paddingTop={0}
										paddingBottom={0}
										padding={8}
										indent={0}
										disableEdit
										disableDrag
										disableDrop
										searchTerm={uploadsQuery}
										searchMatch={(node) => {
											if (node.data.item.type === "upload") {
												return matchedUploadIds.has(
													node.data.item.data.upload_id as UploadId,
												);
											}
											return false;
										}}
									>
										{LibraryTreeNode}
									</Tree>
								) : (
									<div className="mt-2 flex flex-col gap-1 p-2">
										<div className="flex items-center gap-2">
											<Skeleton className="h-6 w-6" />
											<Skeleton className="h-6 w-72" />
										</div>
										<div className="flex items-center gap-2">
											<Skeleton className="h-6 w-6" />
											<Skeleton className="h-6 w-72" />
										</div>
										<div className="flex items-center gap-2">
											<Skeleton className="h-6 w-6" />
											<Skeleton className="h-6 w-72" />
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="flex w-1/3">
							<UploadPreview />
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	},
);

export const UploadSelector: React.FC<MultiSelectProps> = (props) => {
	return (
		<DocumentSelectorProvider selectedUploadIds={props.selectedUploadIds}>
			<_UploadSelector {...props} />
		</DocumentSelectorProvider>
	);
};
