import { FeedItemViewer } from "@/components/FeedItemViewer";
import { ResearchPDFViewer } from "@/components/PDFViewer";
import { Drawer, DrawerContent } from "@/components/ui/drawer";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable";
import { type PDFAnnotation, useChatContext } from "@/contexts/ChatContext";
import type { SearchLibraryResultWithUpload } from "@/contexts/SearchContext";
import type { FeedItemId } from "@/idGenerators";
import { Chat } from "@/pages/Research/Chat";
import type { SearchFeedItemsResultOutput as SearchFeedItemsResult } from "@api/schemas";
import { useMediaQuery } from "@uidotdev/usehooks";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

const ActiveSearchResult = observer(
	({
		activeSearchResult,
	}: {
		activeSearchResult:
			| SearchLibraryResultWithUpload
			| SearchFeedItemsResult
			| PDFAnnotation;
	}) => {
		if (activeSearchResult.type === "library") {
			return (
				<ResearchPDFViewer
					upload={activeSearchResult.upload}
					highlight={{
						textToHighlight: {
							textStart: activeSearchResult.chunk_text,
						},
						pageIndicesToSearch: activeSearchResult.chunk_page_indices,
					}}
				/>
			);
		}

		if (activeSearchResult.type === "pdf-annotation") {
			return (
				<ResearchPDFViewer
					upload={activeSearchResult.upload}
					highlight={{
						textToHighlight: {
							textStart: activeSearchResult.textToHighlight.textStart,
							textEnd: activeSearchResult.textToHighlight.textEnd,
						},
						pageIndicesToSearch: activeSearchResult.pageIndicesToSearch,
					}}
				/>
			);
		}

		if (activeSearchResult.type === "feed_items") {
			return (
				<div className="relative flex h-full max-h-full min-h-0 w-full min-w-0 flex-col items-center">
					<FeedItemViewer
						feedItemId={activeSearchResult.feed_item_id as FeedItemId}
					/>
				</div>
			);
		}

		return <></>;
	},
);

const _ActiveResearchLayout = observer(() => {
	const chatContext = useChatContext();
	const { activeSearchResult } = chatContext;
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

	if (isSmallDevice) {
		return (
			<div className="h-full w-full grow">
				<Chat />
				<Drawer
					open={!!activeSearchResult}
					onOpenChange={(open) => {
						if (!open) {
							runInAction(() => {
								chatContext.activeSearchResult = null;
							});
						}
					}}
				>
					<DrawerContent className="h-[90%]">
						<div
							className={
								"relative mt-4 flex h-full min-h-0 flex-col items-center"
							}
						>
							{activeSearchResult ? (
								<ActiveSearchResult activeSearchResult={activeSearchResult} />
							) : (
								<></>
							)}
						</div>
					</DrawerContent>
				</Drawer>
			</div>
		);
	}

	return (
		// Important: the min-w-0 and relative classes are necessary to make the
		// resizable panels work correctly with the toggleable sidebar
		<div className="relative flex h-full min-w-0 grow flex-col items-center">
			<ResizablePanelGroup direction="horizontal">
				<ResizablePanel minSize={25} order={1}>
					<div className="h-full w-full min-w-0 grow">
						<Chat />
					</div>
				</ResizablePanel>
				{activeSearchResult ? (
					<>
						<ResizableHandle />
						<ResizablePanel minSize={25} order={2}>
							<div className="relative flex h-full min-h-0 w-full min-w-0 flex-col items-center">
								<ActiveSearchResult activeSearchResult={activeSearchResult} />
							</div>
						</ResizablePanel>
					</>
				) : (
					<></>
				)}
			</ResizablePanelGroup>
		</div>
	);
});

export const ActiveResearchLayout = () => {
	const chatContext = useChatContext();
	return (
		<>
			<Helmet>
				<title>{chatContext.chatTitle}</title>
			</Helmet>
			<_ActiveResearchLayout />
		</>
	);
};
