import { Favicon } from "@/components/Favicon";
import { FeedSelector } from "@/components/FeedSelector";
import { UploadCoverImage } from "@/components/UploadCoverImage";
import { UploadSelector } from "@/components/UploadSelector";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { useAppContext } from "@/contexts/AppContext";
import { SearchModeMeta, useSearchContext } from "@/contexts/SearchContext";
import type { UploadId } from "@/idGenerators";
import { formatAuthors, formatTitle } from "@/lib/utils";
import { SearchMode } from "@api/schemas";
import {
	ArrowRight,
	Books,
	Rss,
	SlidersHorizontal,
	X,
} from "@phosphor-icons/react";
import { Close } from "@radix-ui/react-popover";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";

export const SearchSettings = observer(() => {
	const [_, setURLSearchParams] = useSearchParams();
	const searchContext = useSearchContext();
	const { includedUploadIds, includedFeedChannelIds } = searchContext;
	const appContext = useAppContext();

	return (
		<Popover>
			<PopoverTrigger className="mt-2 mb-2 flex items-center gap-1 text-neutral-500 text-sm hover:text-black hover:underline">
				<SlidersHorizontal className="text-base" />{" "}
				{includedUploadIds.size > 0 ? (
					<span className="flex items-center text-nowrap">
						Searching {includedUploadIds.size} selected uploads
						{includedUploadIds.size > 1 ? "s" : ""} with{" "}
						<span className="ml-1 flex items-center gap-1 rounded-md border px-1 py-0.5 text-sm">
							{SearchModeMeta[searchContext.searchMode].icon}
							<span className="text-xs">
								{SearchModeMeta[searchContext.searchMode].title}
							</span>
						</span>
					</span>
				) : (
					<div className="flex w-full items-center text-left">
						{appContext.sortedIndexedUploads === null ? (
							<p className="text-nowrap">Searching all uploads with</p>
						) : (
							<p className="text-nowrap">
								Searching all {appContext.sortedIndexedUploads.length} uploads
								with
							</p>
						)}{" "}
						<span className="ml-1 flex max-w-max items-center gap-1 rounded-md border px-1 py-0.5 text-sm">
							{SearchModeMeta[searchContext.searchMode].icon}
							<span className="text-xs">
								{SearchModeMeta[searchContext.searchMode].title}
							</span>
						</span>
					</div>
				)}
			</PopoverTrigger>
			<PopoverContent align="start" className="mt-0 w-[28rem] rounded-lg p-4">
				<div className="flex items-center justify-between gap-8">
					<Label className="text-neutral-800 text-sm">Search mode</Label>
					<Select
						value={searchContext.searchMode}
						onValueChange={(v) => {
							runInAction(() => {
								searchContext.searchMode = v as SearchMode;
							});
						}}
					>
						<SelectTrigger className="w-48 bg-white">
							<SelectValue placeholder="Search type">
								<div className="flex items-center gap-3">
									<span className="text-xl">
										{SearchModeMeta[searchContext.searchMode].icon}
									</span>{" "}
									<span className="text-sm">
										{SearchModeMeta[searchContext.searchMode].title}
									</span>
								</div>
							</SelectValue>
						</SelectTrigger>
						<SelectContent>
							<SelectItem value={SearchMode.keyword}>
								<div className="flex items-center gap-4 px-2">
									<span className="text-2xl">
										{SearchModeMeta[SearchMode.keyword].icon}
									</span>
									<div>
										<h2 className="font-semibold">
											{SearchModeMeta[SearchMode.keyword].title}
										</h2>
										<p className="text-neutral-500">
											{SearchModeMeta[SearchMode.keyword].description}
										</p>
									</div>
								</div>
							</SelectItem>
							<SelectItem value={SearchMode.hybrid}>
								<div className="flex items-center gap-4 px-2">
									<span className="text-2xl">
										{SearchModeMeta[SearchMode.hybrid].icon}
									</span>
									<div>
										<h2 className="font-semibold">
											{SearchModeMeta[SearchMode.hybrid].title}
										</h2>
										<p className="text-neutral-500">
											{SearchModeMeta[SearchMode.hybrid].description}
										</p>
									</div>
								</div>
							</SelectItem>
						</SelectContent>
					</Select>
				</div>

				<div className="mt-4 flex flex-col rounded-lg border">
					<div
						className={clsx(
							"flex w-full items-center justify-between gap-2 bg-neutral-50 p-2 text-sm",
							searchContext.includeLibrary ? "rounded-t-lg" : "rounded-lg",
						)}
					>
						<Label>Include uploads</Label>
						<Switch
							checked={searchContext.includeLibrary}
							onCheckedChange={(checked) => {
								runInAction(() => {
									searchContext.includeLibrary = checked;
								});
							}}
						/>
					</div>
					{searchContext.includeLibrary && (
						<div className="flex flex-col border-t p-2">
							<Label className="text-neutral-500 text-sm">
								Only search these uploads
							</Label>
							<UploadSelector
								popoverTrigger={
									<div className="flex h-8 w-full items-center gap-1 whitespace-nowrap rounded-md border border-neutral-200 bg-white px-2 py-1 text-neutral-700 text-sm shadow-sm ring-offset-white placeholder:text-neutral-500 focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:ring-offset-neutral-950 dark:focus:ring-neutral-300 dark:placeholder:text-neutral-400 [&>span]:line-clamp-1">
										<Books className="shrink-0 text-lg" weight="duotone" />
										<span>Select uploads...</span>
									</div>
								}
								selectedUploadIds={includedUploadIds}
								onChange={(uploadIds) => {
									runInAction(() => {
										searchContext.includedUploadIds = new Set(uploadIds);
									});
								}}
							/>

							<div className="relative mt-1 rounded-lg border border-neutral-200 shadow-sm">
								<div className="flex w-full flex-col gap-0.5">
									{includedUploadIds.size > 0 ? (
										<div className="py-1">
											{[...includedUploadIds].map((upload_id) => {
												const upload = appContext.getUploadById(
													upload_id as UploadId,
												);

												if (!upload) {
													return null;
												}

												return (
													<div
														key={upload_id}
														className="flex w-full min-w-0 items-center gap-2 rounded-md px-2 py-0.5"
													>
														<div>
															<UploadCoverImage
																size={128}
																className={(uploadStatus) =>
																	clsx(
																		"h-6 max-w-6 rounded-xs",
																		uploadStatus === "ready" && "shadow",
																	)
																}
																upload_id={upload_id as UploadId}
																upload_status={upload.upload_status}
															/>
														</div>
														<div className="grow truncate">
															<h2 className="min-w-0 truncate text-neutral-700 text-sm leading-4">
																{formatTitle({
																	title: upload.upload_title,
																	subtitle: upload.upload_subtitle,
																	filename: upload.file_name,
																})}
															</h2>
															<h3 className="min-w-0 truncate text-neutral-500 text-sm leading-4">
																{formatAuthors(upload.upload_authors ?? [])}
																{upload.upload_year_published &&
																	`, ${upload.upload_year_published}`}
															</h3>
														</div>
														<button
															type="button"
															className="shrink-0 rounded-md p-1.5 text-neutral-500 hover:bg-neutral-100 hover:text-black"
															onClick={() => {
																runInAction(() => {
																	searchContext.includedUploadIds = new Set(
																		[...includedUploadIds].filter(
																			(s) => s !== upload_id,
																		),
																	);
																});
															}}
														>
															<X />
														</button>
													</div>
												);
											})}
										</div>
									) : (
										<div className="flex w-full items-center justify-center rounded-lg bg-neutral-50 p-4 text-neutral-500 text-sm">
											Searching all uploads
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="mt-4 flex flex-col rounded-lg border">
					<div
						className={clsx(
							"flex w-full items-center justify-between gap-2 bg-neutral-50 p-2 text-sm",
							searchContext.includeFeeds ? "rounded-t-lg" : "rounded-lg",
						)}
					>
						<Label>Include feeds</Label>
						<Switch
							checked={searchContext.includeFeeds}
							onCheckedChange={(checked) => {
								runInAction(() => {
									searchContext.includeFeeds = checked;
								});
							}}
						/>
					</div>
					{searchContext.includeFeeds && (
						<div className="flex flex-col border-t p-2">
							<Label className="text-neutral-500 text-sm">
								Only search these feeds
							</Label>
							<FeedSelector
								popoverTrigger={
									<div className="flex h-8 w-full items-center gap-1.5 whitespace-nowrap rounded-md border border-neutral-200 bg-white px-2 py-1 text-neutral-700 text-sm shadow-sm ring-offset-white placeholder:text-neutral-500 focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:ring-offset-neutral-950 dark:focus:ring-neutral-300 dark:placeholder:text-neutral-400 [&>span]:line-clamp-1">
										<Rss className="shrink-0 text-lg" weight="duotone" />
										<span>Select feeds...</span>
									</div>
								}
								selectedFeedChannelIds={includedFeedChannelIds}
								onChange={(channelIds) => {
									runInAction(() => {
										searchContext.includedFeedChannelIds = new Set(channelIds);
									});
								}}
							/>

							<div className="relative mt-1 rounded-lg border border-neutral-200 shadow-sm">
								<div className="flex w-full flex-col gap-0.5">
									{includedFeedChannelIds.size > 0 ? (
										<div className="py-1">
											{[...includedFeedChannelIds].map((channelId) => {
												const channel =
													appContext.feedChannelsById?.get(channelId);

												const numItems =
													appContext.sortedFeedItemsByChannel?.get(channelId)
														?.length ?? 0;

												if (!channel) {
													return null;
												}

												return (
													<div
														key={channelId}
														className="flex w-full min-w-0 items-center gap-2 rounded-md px-2 py-1"
													>
														{channel.feed_channel_link ? (
															<Favicon
																url={channel.feed_channel_link}
																alt={channel.file_name}
																className="h-8 w-8 shrink-0 rounded-md bg-neutral-100"
															/>
														) : (
															<div className="h-8 w-8 shrink-0 rounded-md bg-neutral-200" />
														)}
														<div className="grow truncate">
															<h2 className="min-w-0 truncate text-neutral-700 text-sm leading-4">
																{channel.file_name}
															</h2>
															<h3 className="text-neutral-500 text-sm">
																{numItems}
																{numItems !== 0 ? " articles" : " article"}
															</h3>
														</div>
														<button
															type="button"
															className="shrink-0 rounded-md p-1.5 text-neutral-500 hover:bg-neutral-100 hover:text-black"
															onClick={() => {
																runInAction(() => {
																	searchContext.includedFeedChannelIds.delete(
																		channelId,
																	);
																});
															}}
														>
															<X />
														</button>
													</div>
												);
											})}
										</div>
									) : (
										<div className="flex w-full items-center justify-center rounded-lg bg-neutral-50 p-4 text-neutral-500 text-sm">
											Searching all feeds
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="mt-4 flex justify-end gap-4">
					<Close type="button" asChild>
						<Button variant="ghost">Cancel</Button>
					</Close>
					<Close asChild>
						<Button
							className="flex items-center gap-2"
							onClick={() =>
								searchContext.handleSearch(setURLSearchParams, false)
							}
						>
							Rerun search <ArrowRight weight="bold" />
						</Button>
					</Close>
				</div>
			</PopoverContent>
		</Popover>
	);
});
