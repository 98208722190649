import { API_ENDPOINT_WS, IS_DEV } from "@/config";
import {
	type CommandKPage,
	getLastCmdKPage,
	openCmdKPage,
	popCmdKPage,
	pushCmdKPage,
	setCmdKOpen,
} from "@/contexts/AppContext/CmdK";
import {
	addFeedChannelAction,
	deleteFeedChannelsAction,
	feedChannelsById,
	feedItemsById,
	searchFeedItemsByMetadata,
	sortedFeedChannels,
	sortedFeedItemsByChannel,
} from "@/contexts/AppContext/Feeds";
import {
	deleteChatAction,
	deleteChatLocally,
	formatChatPublicLink,
	navigateToNewChat,
	renameChatAction,
	renameChatLocally,
	sortedChats,
	toggleChatPublicAction,
} from "@/contexts/AppContext/Research";
import {
	folderImmediateChildren,
	getFolderDescendants,
	rootDirectoryNodes,
	treeChildrenAccessor,
	treeMoveHandler,
	treeMoveHandlerAction,
} from "@/contexts/AppContext/TreeHandlers";
import {
	createFolderAction,
	createUpload,
	deleteFolderAction,
	deleteMultiple,
	deleteUploadAction,
	downloadOriginalUploadFile,
	downloadUploadPdf,
	renameFolderAction,
	searchUploadsByMetadata,
	sortedIndexedUploads,
	updateUploadMetadataAction,
} from "@/contexts/AppContext/Uploads";
import {
	attemptReconnect,
	handleDirectoryUpdate,
} from "@/contexts/AppContext/WorkspaceUpdates";
import { createTableAction } from "@/contexts/AppContext/tables";
import type {
	ChatId,
	FeedChannelId,
	FeedItemId,
	FolderId,
	TableId,
	UploadId,
} from "@/idGenerators";
import { bootstrapSession } from "@api/fastAPI";
import type {
	ChatMetadata,
	DirectoryUpdateEvent,
	FeedChannel,
	FeedItemMetadata,
	Folder,
	TableMetadata,
	Upload,
} from "@api/schemas";
import { useAuth, useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import flexsearch from "flexsearch";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createContext, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

interface Workspace {
	userId: string;
	uploads: Map<UploadId, Upload>;
	folders: Map<FolderId, Folder>;
	chats: Map<ChatId, ChatMetadata>;
	feedChannels: Map<FeedChannelId, FeedChannel>;
	feedItems: Map<FeedItemId, FeedItemMetadata>;
	tables: Map<TableId, TableMetadata>;
}

export class AppState {
	workspace: Workspace | null = null;

	uploadsFlexsearchIndex = new flexsearch.Document({
		tokenize: "full",
		document: {
			id: "upload_id",
			index: ["upload_title", "upload_subtitle", "upload_authors", "file_name"],
		},
	});
	feedItemsFlexsearchIndex = new flexsearch.Document({
		tokenize: "full",
		document: {
			id: "feed_item_id",
			index: [
				"file_name",
				"feed_item_subtitle",
				"feed_item_description",
				"feed_item_link",
				"feed_item_author",
			],
		},
	});

	pdfScale = 1;
	showSidebar = true;

	newChatUploadIds: Set<UploadId> = new Set();
	newChatFeedChannelIds: Set<FeedChannelId> = new Set();

	navigate: ReturnType<typeof useNavigate>;
	getToken: ReturnType<typeof useAuth>["getToken"];

	// Command K
	cmdKOpen = false;
	cmdKPages: CommandKPage[] = [];
	showAddFeedDialog = false;

	// WebSocket for workspace-level updates
	ws: WebSocket | null = null;
	wsConnected = false;
	reconnectAttempts = 0;
	reconnectTimeoutId: Timer | null = null;

	// Files currently being uploaded by the current user, not persisted across sessions
	recentUploads: Map<UploadId, Upload> = new Map();
	get recentUploadsArray() {
		return Array.from(this.recentUploads.values()).sort((a, b) =>
			a.upload_id.localeCompare(b.upload_id),
		);
	}

	constructor({
		navigate,
		getToken,
	}: {
		navigate: ReturnType<typeof useNavigate>;
		getToken: ReturnType<typeof useAuth>["getToken"];
	}) {
		this.navigate = navigate;
		this.getToken = getToken;
		makeAutoObservable(this);
		makePersistable(this, {
			name: "AppState",
			properties: ["pdfScale", "showSidebar"],
			storage: window.localStorage,
		});
	}

	async init({
		userId,
		isReconnect,
	}: {
		userId: string;
		isReconnect: boolean;
	}) {
		const token = await this.getToken();

		if (!token) {
			Sentry.captureMessage("No token found in AppContext", "error");
			toast.error("Unable to authenticate. Please refresh the page.");
			return;
		}

		bootstrapSession()
			.then((res) => {
				runInAction(() => {
					for (const upload of res.data.uploads) {
						this.uploadsFlexsearchIndex.add(upload);
					}
					for (const item of res.data.feed_items) {
						this.feedItemsFlexsearchIndex.add(item);
					}
					this.workspace = {
						uploads: new Map(
							res.data.uploads.map((upload) => [
								upload.upload_id as UploadId,
								upload,
							]),
						),
						folders: new Map(
							res.data.folders.map((folder) => [
								folder.folder_id as FolderId,
								folder,
							]),
						),
						chats: new Map(
							res.data.chats.map((chat) => [chat.chat_id as ChatId, chat]),
						),
						feedChannels: new Map(
							res.data.feed_channels.map((feedChannel) => [
								feedChannel.feed_channel_id as FeedChannelId,
								feedChannel,
							]),
						),
						feedItems: new Map(
							res.data.feed_items.map((feedItem) => [
								feedItem.feed_item_id as FeedItemId,
								feedItem,
							]),
						),
						tables: new Map(
							res.data.tables.map((table) => [
								table.table_id as TableId,
								table,
							]),
						),
						userId,
					};
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to initialize session. Please refresh the page.");
				}
			});

		const ws = new WebSocket(
			`${API_ENDPOINT_WS}/uploads/workspace_updates?token=${token}`,
		);

		ws.onopen = () => {
			runInAction(() => {
				this.ws = ws;
				this.wsConnected = true;
				if (isReconnect) {
					this.reconnectAttempts = 0;
					toast.success("Reconnected to workspace.");
				}
			});
		};

		ws.onmessage = (event) => {
			try {
				const data: DirectoryUpdateEvent = JSON.parse(event.data);
				this.#handleDirectoryUpdate(data);
			} catch (e) {
				console.error("Error parsing websocket response JSON:", e);
				return;
			}
		};

		ws.onclose = (e) => {
			// 1000 is the code for "normal closure"
			if (e.code === 1000) {
				return;
			}
			runInAction(() => {
				this.wsConnected = false;
				this.ws = null;
			});

			this.#attemptReconnect(userId);
		};

		ws.onerror = (error) => {
			Sentry.captureException(error);
			this.#attemptReconnect(userId);
		};
	}

	/*
	Workspace updates channel methods
	*/
	#attemptReconnect = attemptReconnect.bind(this);
	#handleDirectoryUpdate = handleDirectoryUpdate.bind(this);

	/*
	Command K-related methods
	*/
	popCmdKPage = popCmdKPage.bind(this);
	pushCmdKPage = pushCmdKPage.bind(this);
	setCmdKOpen = setCmdKOpen.bind(this);
	openCmdKPage = openCmdKPage.bind(this);
	get lastCmdKPage() {
		return getLastCmdKPage.bind(this)();
	}

	/* 
	Library-related methods
	*/
	get workspaceHasLoaded() {
		return this.workspace !== null;
	}
	get sortedIndexedUploads() {
		return sortedIndexedUploads.bind(this)();
	}
	getUploadById(uploadId: UploadId) {
		return this.workspace?.uploads.get(uploadId) ?? null;
	}
	getFolderById(folderId: FolderId) {
		return this.workspace?.folders.get(folderId) ?? null;
	}
	updateUploadMetadata = updateUploadMetadataAction.bind(this);
	createFolder = createFolderAction.bind(this);
	deleteFolder = deleteFolderAction.bind(this);
	deleteMultiple = deleteMultiple.bind(this);
	createUpload = createUpload.bind(this);
	deleteUpload = deleteUploadAction.bind(this);
	renameFolder = renameFolderAction.bind(this);
	downloadUploadPdf = downloadUploadPdf.bind(this);
	downloadOriginalUploadFile = downloadOriginalUploadFile.bind(this);
	searchUploadsByMetadata = searchUploadsByMetadata.bind(this);

	/* 
	Library tree-related methods
	*/
	treeChildrenAccessor = treeChildrenAccessor.bind(this);
	getFolderDescendants = getFolderDescendants.bind(this);
	treeMoveHandlerAction = treeMoveHandlerAction.bind(this);
	treeMoveHandler = treeMoveHandler.bind(this);
	get rootDirectoryNodes() {
		return rootDirectoryNodes.bind(this)();
	}
	get folderImmediateChildren() {
		return folderImmediateChildren.bind(this)();
	}

	/* 
	Research-related methods
	*/
	addChatLocally(chat: ChatMetadata) {
		this.workspace?.chats.set(chat.chat_id as ChatId, chat);
	}
	deleteChat = deleteChatAction.bind(this);
	renameChatLocally = renameChatLocally.bind(this);
	renameChat = renameChatAction.bind(this);
	toggleChatPublic = toggleChatPublicAction.bind(this);
	deleteChatLocally = deleteChatLocally.bind(this);
	get sortedChats() {
		return sortedChats.bind(this)();
	}
	navigateToNewChat = navigateToNewChat.bind(this);
	formatChatPublicLink = formatChatPublicLink.bind(this);

	/* 
	Feed-related methods
	*/
	addFeedChannel = addFeedChannelAction.bind(this);
	deleteFeedChannels = deleteFeedChannelsAction.bind(this);
	get feedItemsById() {
		return feedItemsById.bind(this)();
	}
	get feedChannelsById() {
		return feedChannelsById.bind(this)();
	}
	get sortedFeedChannels() {
		return sortedFeedChannels.bind(this)();
	}
	get sortedFeedItemsByChannel() {
		return sortedFeedItemsByChannel.bind(this)();
	}
	searchFeedItemsByMetadata = searchFeedItemsByMetadata.bind(this);

	/* 
	Table-related methods
	*/
	createTable = createTableAction.bind(this);
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const AppContext = createContext<AppState>(null as any);

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error("useAppContext must be used within an AppProvider");
	}

	return context;
};

let didInit = false;

export const AppProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const navigate = useNavigate();
	const { getToken } = useAuth();
	const { user } = useUser();
	const appState = useRef(new AppState({ navigate, getToken }));
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

	useEffect(() => {
		runInAction(() => {
			appState.current.showSidebar = !isSmallDevice;
		});
	}, [isSmallDevice]);

	useEffect(
		function init() {
			if (!user) {
				return;
			}

			if (!didInit) {
				didInit = true;
				// get uploads and chats
				appState.current.init({ userId: user.id, isReconnect: false });
			}
		},
		[user],
	);

	return (
		<AppContext.Provider value={appState.current}>
			{children}
		</AppContext.Provider>
	);
};
