import { useSearchContext } from "@/contexts/SearchContext";
import type {
	SearchLibraryResultOutput as SearchLibraryResult,
	Upload,
} from "@api/schemas";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

export const ResultWithinLibraryGroup = observer(
	({
		result,
		upload,
	}: {
		result: SearchLibraryResult;
		upload: Upload;
	}) => {
		const searchContext = useSearchContext();

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const onSelect = useCallback(() => {
			runInAction(() => {
				searchContext.activeSearchResult = {
					...result,
					upload: upload,
				};
			});
		}, [result, upload]);

		return (
			<div
				key={result.chunk_id}
				className={clsx(
					"group cursor-pointer rounded-md border",

					searchContext.activeSearchResult?.type === "library" &&
						searchContext.activeSearchResult?.chunk_id === result.chunk_id
						? "border-blue-300 bg-blue-50 ring-2 ring-blue-100"
						: "hover:border-blue-200 hover:bg-blue-50",
				)}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<p className="line-clamp-4 rounded-t-md px-1.5 py-0.5 text-neutral-600 text-sm">
					{result.chunk_text}
				</p>
				<div
					className={clsx(
						"rounded-b-md border-t bg-neutral-50 px-1.5 py-0.5 text-neutral-600 text-xs",
						searchContext.activeSearchResult?.type === "library" &&
							searchContext.activeSearchResult?.chunk_id === result.chunk_id
							? "bg-blue-100 "
							: "",
					)}
				>
					{result.chunk_page_indices.length > 1 ? (
						<>
							Pages {result.chunk_page_indices[0] + 1}-
							{result.chunk_page_indices[result.chunk_page_indices.length - 1] +
								1}
						</>
					) : (
						<>Page {result.chunk_page_indices[0] + 1}</>
					)}
				</div>
			</div>
		);
	},
);
