import { useAppContext } from "@/contexts/AppContext";
import { PDFAnnotation, useChatContext } from "@/contexts/ChatContext";
import { UploadLink } from "@/plugins/UploadLink";
import type { Annotation as AnnotationType } from "@api/schemas";
import { Mark, mergeAttributes } from "@tiptap/core";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { Plugin } from "@tiptap/pm/state";
import { EditorContent, Extension, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useClickAway } from "@uidotdev/usehooks";
import clsx from "clsx";
import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { createContext, useContext, useRef, useState } from "react";
import { usePopper } from "react-popper";

const ANNOTATION_POPUP_CLASSNAME = "annotation-popup";

export class AnnotationState {
	openAnnotation: {
		annotationId: string;
		ref: HTMLLinkElement;
	} | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	setOpenAnnotation(x: { annotationId: string; ref: HTMLLinkElement } | null) {
		this.openAnnotation = x;
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const AnnotationContext = createContext<AnnotationState>(null as any);

export const useAnnotation = () => {
	return useContext(AnnotationContext);
};

export const AnnotationProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const annotationState = useRef(new AnnotationState());
	return (
		<AnnotationContext.Provider value={annotationState.current}>
			{children}
		</AnnotationContext.Provider>
	);
};

/**
 * Adapted from https://github.com/ueberdosis/tiptap/blob/main/packages/extension-link/src/helpers/clickHandler.ts
 * Probably want to convert highlights to a node though...
 */
export function annotationHighlightClickHandler(
	annotationState: AnnotationState,
): Plugin {
	return new Plugin({
		props: {
			handleClick: (_view, _pos, event) => {
				if (event.button !== 0 || !event.target) {
					return false;
				}

				const a = event.target as HTMLElement;
				const isInPopup = !!a.closest(`.${ANNOTATION_POPUP_CLASSNAME}`);

				if (isInPopup) {
					return;
				}

				const annotationId = a.getAttribute("annotation-id");
				if (!annotationId) {
					annotationState.setOpenAnnotation(null);
					return false;
				}
				console.log("annotationId", annotationId);
				annotationState.setOpenAnnotation({
					annotationId,
					ref: a as HTMLLinkElement,
				});
				return true;
			},
		},
	});
}

const AnnotationPopper = observer(
	({
		openAnnotation,
		children,
	}: {
		openAnnotation: { annotationId: string; ref: HTMLLinkElement };
		children: React.ReactNode;
	}) => {
		const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
			null,
		);
		const { styles, attributes } = usePopper(
			openAnnotation.ref,
			popperElement,
			{
				placement: "bottom-start",
				modifiers: [{ name: "preventOverflow", enabled: true }],
			},
		);

		return (
			<div
				// @ts-ignore
				ref={setPopperElement}
				style={styles.popper}
				{...attributes.popper}
				className={clsx(
					"z-50 my-1 w-full max-w-sm rounded-lg border bg-white px-4 text-sm shadow",
					ANNOTATION_POPUP_CLASSNAME,
				)}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				{children}
			</div>
		);
	},
);

const TABLE_STYLES =
	"prose-table:border prose-table:border-collapse prose-th:bg-neutral-100 prose-td:border prose-td:px-2 prose-th:px-2 prose-th:border prose-headings:font-semibold prose-td:text-left prose-th:text-left prose-td:align-top prose-th:align-top prose-table:mb-2";

export const AnnotationComponent = observer(
	({ annotation }: { annotation: AnnotationType }) => {
		const chatContext = useChatContext();
		const appContext = useAppContext();
		const annotationState = useAnnotation();
		const ref = useClickAway((e) => {
			if (e.target instanceof HTMLAnchorElement) {
				// this is handled by the plugin's handleClick
				if (
					annotation.annotation_id ===
					annotationState.openAnnotation?.annotationId
				) {
					return;
				}
				annotationState.setOpenAnnotation(null);
			}
			annotationState.setOpenAnnotation(null);
		});
		const editor = useEditor(
			{
				extensions: [
					StarterKit,
					Table,
					TableCell,
					TableRow,
					TableHeader,
					Link,
					UploadLink.configure({
						handleUploadLinkClick: ({
							uploadId,
							chunkId,
							textStart,
							textEnd,
						}) => {
							const retrievedChunk = chatContext.retrievedChunks?.get(chunkId);
							runInAction(() => {
								chatContext.activeSearchResult = new PDFAnnotation({
									// biome-ignore lint/style/noNonNullAssertion: <explanation>
									upload: appContext.getUploadById(uploadId)!,
									textToHighlight: {
										textStart,
										textEnd,
									},
									pageIndicesToSearch: retrievedChunk?.chunk_page_indices ?? [],
								});
							});
						},
					}),
				],
				content: annotation.content,
				editable: false,
			},
			[annotation.content],
		);

		if (
			annotationState.openAnnotation === null ||
			annotationState.openAnnotation.annotationId !== annotation.annotation_id
		) {
			return null;
		}

		return (
			// @ts-expect-error
			<div ref={ref}>
				<AnnotationPopper openAnnotation={annotationState.openAnnotation}>
					<EditorContent
						editor={editor}
						className={clsx(
							"prose-sm group mt-2 mb-0 prose-ol:list-decimal prose-ul:list-disc py-0",
							TABLE_STYLES,
						)}
					/>
				</AnnotationPopper>
			</div>
		);
	},
);

interface AnnotationHighlightOptions {
	annotationState: AnnotationState;
}

const AnnotationHighlight = Mark.create<AnnotationHighlightOptions>({
	name: "annotationHighlight",
	priority: 1001, // Higher priority than Link (1000) to process the nodes first
	addOptions() {
		return {
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			annotationState: null as any,
		};
	},
	parseHTML() {
		return [
			{
				tag: 'a[href^="#annotation_"]',
			},
		];
	},
	addAttributes() {
		return {
			href: {},
		};
	},
	renderHTML({ HTMLAttributes }) {
		const annotationId = HTMLAttributes.href?.split("#")[1];
		return [
			"a",
			mergeAttributes(HTMLAttributes, {
				"annotation-id": annotationId,
			}),
			0,
		];
	},
	addProseMirrorPlugins() {
		return [annotationHighlightClickHandler(this.options.annotationState)];
	},
});

// const AnnotationContent = Node.create({
// 	name: "annotationContent",
// 	group: "block",
// 	content: "block",
// 	parseHTML() {
// 		return [{ tag: "annotation" }];
// 	},
// 	addAttributes() {
// 		return {
// 			id: {},
// 		};
// 	},
// 	renderHTML({ HTMLAttributes }) {
// 		return ["annotation", mergeAttributes(HTMLAttributes), 0];
// 	},
// 	addNodeView() {
// 		return ReactNodeViewRenderer(AnnotationComponent);
// 	},
// });

interface AnnotationOptions {
	annotationHighlight: AnnotationHighlightOptions;
}

export const Annotation = Extension.create<AnnotationOptions>({
	name: "annotation",
	addExtensions() {
		return [
			AnnotationHighlight.configure({
				annotationState: this.options.annotationHighlight.annotationState,
			}),
			// AnnotationContent,
		];
	},
});
