import { ViewOnlyChatProvider } from "@/contexts/ChatContext";
import type { ChatId } from "@/idGenerators";
import { ActiveResearchLayout } from "@/pages/Research/ActiveResearchLayout";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

export const ViewPublicChat = observer(() => {
	const chatId = useParams().chatId;

	if (!chatId) {
		return <div>Chat not found</div>;
	}

	return (
		<div className="relative flex h-full min-h-0 w-full border-t">
			<ViewOnlyChatProvider chatId={chatId as ChatId}>
				<ActiveResearchLayout />
			</ViewOnlyChatProvider>
		</div>
	);
});
