import { ShowSidebarButton } from "@/components/ShowSidebarButton";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { IS_DEV } from "@/config";
import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import { exportConversation } from "@api/fastAPI";
import { ArrowLineRight, DownloadSimple, Eye } from "@phosphor-icons/react";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";

export const ChatHeader = observer(() => {
	const chatContext = useChatContext();
	const appContext = useAppContext();

	const { activeSearchResult, chatTitle } = chatContext;

	return (
		<div
			className={clsx(
				"absolute inset-0 z-10 flex h-14 w-full shrink-0 items-center justify-between gap-2 border-b bg-white/90 px-2 backdrop-blur",
			)}
		>
			<div className="flex min-w-0 items-center gap-2 truncate">
				{!appContext.showSidebar && <ShowSidebarButton />}

				{chatContext.chatInitialized && (
					<>
						<h2 className="min-w-0 truncate py-2 pl-2 font-semibold text-neutral-700 leading-4">
							{chatTitle ?? "New conversation"}
						</h2>

						{chatContext.viewOnly && (
							<Tooltip>
								<TooltipTrigger className="flex shrink-0 select-none items-center gap-1.5 rounded-full bg-neutral-100 py-1 pr-3 pl-2 text-neutral-500 text-sm">
									<Eye weight="bold" className="text-base" /> View-only
								</TooltipTrigger>
								<TooltipContent side="bottom">
									This is a view-only conversation shared with you.
								</TooltipContent>
							</Tooltip>
						)}
					</>
				)}

				{IS_DEV && (
					<Tooltip>
						<TooltipTrigger
							type="button"
							className="ml-2 shrink-0 rounded-md p-2 hover:bg-neutral-100"
							onClick={() => {
								exportConversation({
									chat_id: chatContext.chatId,
								}).then((response) => {
									const url = window.URL.createObjectURL(
										new Blob([JSON.stringify(response.data.chat, null, 2)], {
											type: "application/json",
										}),
									);
									const link = document.createElement("a");
									link.href = url;
									link.setAttribute(
										"download",
										`${chatContext.chatTitle}.json`,
									);
									document.body.appendChild(link);
									link.click();
								});
							}}
						>
							<DownloadSimple weight="bold" />
						</TooltipTrigger>
						<TooltipContent side="bottom">Export chat JSON</TooltipContent>
					</Tooltip>
				)}
			</div>
			{activeSearchResult && (
				<button
					type="button"
					className="rounded-lg p-2 text-neutral-500 text-xl hover:bg-neutral-100 hover:text-neutral-900"
					onClick={() => {
						runInAction(() => {
							chatContext.activeSearchResult = null;
						});
					}}
				>
					<ArrowLineRight weight="bold" />
				</button>
			)}
		</div>
	);
});
