/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import useSwr from 'swr'
import type {
  Arguments,
  Key,
  SWRConfiguration
} from 'swr'
import useSWRMutation from 'swr/mutation'
import type {
  SWRMutationConfiguration
} from 'swr/mutation'
import type {
  BodyUploadToFolder,
  BodyUploadToRoot,
  BootstrapSessionResponse,
  ChatRenameRequest,
  CreateFolderRequest,
  CreateTableRequest,
  CrossrefResponse,
  DeleteChatParams,
  DeleteFeedChannelRequest,
  DeleteFeedChannelResponse,
  DeleteFeedItemRequest,
  DeleteFeedItemResponse,
  DeleteFolderRequest,
  DeleteMultipleFilesRequest,
  DeleteUploadRequest,
  DummyResearch200,
  DummyResearchBody,
  ExportConversationParams,
  ExportConversationResponse,
  FeedItem,
  GetFeedItemParams,
  GetLatestParams,
  GoogleBooksResponse,
  GuessRssUrlParams,
  HTTPValidationError,
  IndexFeedRequest,
  InferUploadMetadataRequest,
  InferUploadMetadataResponse,
  MoveDirectoryNodesRequest,
  PreviewRssFeedParams,
  PreviewRssFeedResponse,
  QueryCrossrefRequest,
  QueryGoogleBooksRequest,
  RefetchFeedChannelParams,
  RenameFolderRequest,
  SearchFeedItemsRequest,
  SearchFeedItemsResponse,
  SearchLibraryRequest,
  SearchLibraryResponse,
  SearchRequest,
  SearchResponseOutput,
  TableMetadata,
  ToggleChatPublicRequest,
  UpdateUploadMetadata,
  ViewPublicChatResponse
} from './schemas'



  
  /**
 * @summary Search Library Handler
 */
export const searchLibraryHandler = (
    searchLibraryRequest: SearchLibraryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchLibraryResponse>> => {
    return axios.default.post(
      `/search/search_uploads`,
      searchLibraryRequest,options
    );
  }



export const getSearchLibraryHandlerMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: SearchLibraryRequest }): Promise<AxiosResponse<SearchLibraryResponse>> => {
    return searchLibraryHandler(arg, options);
  }
}
export const getSearchLibraryHandlerMutationKey = () => [`/search/search_uploads`] as const;

export type SearchLibraryHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof searchLibraryHandler>>>
export type SearchLibraryHandlerMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Search Library Handler
 */
export const useSearchLibraryHandler = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof searchLibraryHandler>>, TError, Key, SearchLibraryRequest, Awaited<ReturnType<typeof searchLibraryHandler>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getSearchLibraryHandlerMutationKey();
  const swrFn = getSearchLibraryHandlerMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Search Feed Items Handler
 */
export const searchFeedItemsHandler = (
    searchFeedItemsRequest: SearchFeedItemsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchFeedItemsResponse>> => {
    return axios.default.post(
      `/search/search_feed_items`,
      searchFeedItemsRequest,options
    );
  }



export const getSearchFeedItemsHandlerMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: SearchFeedItemsRequest }): Promise<AxiosResponse<SearchFeedItemsResponse>> => {
    return searchFeedItemsHandler(arg, options);
  }
}
export const getSearchFeedItemsHandlerMutationKey = () => [`/search/search_feed_items`] as const;

export type SearchFeedItemsHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof searchFeedItemsHandler>>>
export type SearchFeedItemsHandlerMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Search Feed Items Handler
 */
export const useSearchFeedItemsHandler = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof searchFeedItemsHandler>>, TError, Key, SearchFeedItemsRequest, Awaited<ReturnType<typeof searchFeedItemsHandler>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getSearchFeedItemsHandlerMutationKey();
  const swrFn = getSearchFeedItemsHandlerMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Search Handler
 */
export const searchHandler = (
    searchRequest: SearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchResponseOutput>> => {
    return axios.default.post(
      `/search`,
      searchRequest,options
    );
  }



export const getSearchHandlerMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: SearchRequest }): Promise<AxiosResponse<SearchResponseOutput>> => {
    return searchHandler(arg, options);
  }
}
export const getSearchHandlerMutationKey = () => [`/search`] as const;

export type SearchHandlerMutationResult = NonNullable<Awaited<ReturnType<typeof searchHandler>>>
export type SearchHandlerMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Search Handler
 */
export const useSearchHandler = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof searchHandler>>, TError, Key, SearchRequest, Awaited<ReturnType<typeof searchHandler>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getSearchHandlerMutationKey();
  const swrFn = getSearchHandlerMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Download Pdf
 */
export const downloadPdf = (
    uploadId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.get(
      `/uploads/pdf/${uploadId}`,options
    );
  }



export const getDownloadPdfKey = (uploadId: string,) => [`/uploads/pdf/${uploadId}`] as const;

export type DownloadPdfQueryResult = NonNullable<Awaited<ReturnType<typeof downloadPdf>>>
export type DownloadPdfQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Download Pdf
 */
export const useDownloadPdf = <TError = AxiosError<HTTPValidationError>>(
  uploadId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof downloadPdf>>, TError> & { swrKey?: Key, enabled?: boolean }, axios?: AxiosRequestConfig }
) => {
  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(uploadId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDownloadPdfKey(uploadId) : null);
  const swrFn = () => downloadPdf(uploadId, axiosOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Download Original
 */
export const downloadOriginal = (
    uploadId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.get(
      `/uploads/original/${uploadId}`,options
    );
  }



export const getDownloadOriginalKey = (uploadId: string,) => [`/uploads/original/${uploadId}`] as const;

export type DownloadOriginalQueryResult = NonNullable<Awaited<ReturnType<typeof downloadOriginal>>>
export type DownloadOriginalQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Download Original
 */
export const useDownloadOriginal = <TError = AxiosError<HTTPValidationError>>(
  uploadId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof downloadOriginal>>, TError> & { swrKey?: Key, enabled?: boolean }, axios?: AxiosRequestConfig }
) => {
  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(uploadId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDownloadOriginalKey(uploadId) : null);
  const swrFn = () => downloadOriginal(uploadId, axiosOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Get Cover
 */
export const getCover = (
    uploadId: string,
    height: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.get(
      `/uploads/cover_image/${uploadId}/${height}.jpg`,options
    );
  }



export const getGetCoverKey = (uploadId: string,
    height: number,) => [`/uploads/cover_image/${uploadId}/${height}.jpg`] as const;

export type GetCoverQueryResult = NonNullable<Awaited<ReturnType<typeof getCover>>>
export type GetCoverQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Cover
 */
export const useGetCover = <TError = AxiosError<HTTPValidationError>>(
  uploadId: string,
    height: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getCover>>, TError> & { swrKey?: Key, enabled?: boolean }, axios?: AxiosRequestConfig }
) => {
  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(uploadId && height)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetCoverKey(uploadId,height) : null);
  const swrFn = () => getCover(uploadId,height, axiosOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Update Upload Metadata
 */
export const updateUploadMetadata = (
    updateUploadMetadata: UpdateUploadMetadata, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/uploads/update_metadata`,
      updateUploadMetadata,options
    );
  }



export const getUpdateUploadMetadataMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: UpdateUploadMetadata }): Promise<AxiosResponse<unknown>> => {
    return updateUploadMetadata(arg, options);
  }
}
export const getUpdateUploadMetadataMutationKey = () => [`/uploads/update_metadata`] as const;

export type UpdateUploadMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof updateUploadMetadata>>>
export type UpdateUploadMetadataMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Update Upload Metadata
 */
export const useUpdateUploadMetadata = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof updateUploadMetadata>>, TError, Key, UpdateUploadMetadata, Awaited<ReturnType<typeof updateUploadMetadata>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateUploadMetadataMutationKey();
  const swrFn = getUpdateUploadMetadataMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Create Folder
 */
export const createFolder = (
    createFolderRequest: CreateFolderRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/uploads/create_folder`,
      createFolderRequest,options
    );
  }



export const getCreateFolderMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: CreateFolderRequest }): Promise<AxiosResponse<unknown>> => {
    return createFolder(arg, options);
  }
}
export const getCreateFolderMutationKey = () => [`/uploads/create_folder`] as const;

export type CreateFolderMutationResult = NonNullable<Awaited<ReturnType<typeof createFolder>>>
export type CreateFolderMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Create Folder
 */
export const useCreateFolder = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof createFolder>>, TError, Key, CreateFolderRequest, Awaited<ReturnType<typeof createFolder>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateFolderMutationKey();
  const swrFn = getCreateFolderMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Delete Folder
 */
export const deleteFolder = (
    deleteFolderRequest: DeleteFolderRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/uploads/delete_folder`,
      deleteFolderRequest,options
    );
  }



export const getDeleteFolderMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: DeleteFolderRequest }): Promise<AxiosResponse<unknown>> => {
    return deleteFolder(arg, options);
  }
}
export const getDeleteFolderMutationKey = () => [`/uploads/delete_folder`] as const;

export type DeleteFolderMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFolder>>>
export type DeleteFolderMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Delete Folder
 */
export const useDeleteFolder = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof deleteFolder>>, TError, Key, DeleteFolderRequest, Awaited<ReturnType<typeof deleteFolder>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteFolderMutationKey();
  const swrFn = getDeleteFolderMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Rename Folder
 */
export const renameFolder = (
    renameFolderRequest: RenameFolderRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/uploads/rename_folder`,
      renameFolderRequest,options
    );
  }



export const getRenameFolderMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: RenameFolderRequest }): Promise<AxiosResponse<unknown>> => {
    return renameFolder(arg, options);
  }
}
export const getRenameFolderMutationKey = () => [`/uploads/rename_folder`] as const;

export type RenameFolderMutationResult = NonNullable<Awaited<ReturnType<typeof renameFolder>>>
export type RenameFolderMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Rename Folder
 */
export const useRenameFolder = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof renameFolder>>, TError, Key, RenameFolderRequest, Awaited<ReturnType<typeof renameFolder>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getRenameFolderMutationKey();
  const swrFn = getRenameFolderMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Move Directory Nodes
 */
export const moveDirectoryNodes = (
    moveDirectoryNodesRequest: MoveDirectoryNodesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/uploads/move_directory_nodes`,
      moveDirectoryNodesRequest,options
    );
  }



export const getMoveDirectoryNodesMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: MoveDirectoryNodesRequest }): Promise<AxiosResponse<unknown>> => {
    return moveDirectoryNodes(arg, options);
  }
}
export const getMoveDirectoryNodesMutationKey = () => [`/uploads/move_directory_nodes`] as const;

export type MoveDirectoryNodesMutationResult = NonNullable<Awaited<ReturnType<typeof moveDirectoryNodes>>>
export type MoveDirectoryNodesMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Move Directory Nodes
 */
export const useMoveDirectoryNodes = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof moveDirectoryNodes>>, TError, Key, MoveDirectoryNodesRequest, Awaited<ReturnType<typeof moveDirectoryNodes>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getMoveDirectoryNodesMutationKey();
  const swrFn = getMoveDirectoryNodesMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Delete Upload
 */
export const deleteUpload = (
    deleteUploadRequest: DeleteUploadRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/uploads/delete_upload`,
      deleteUploadRequest,options
    );
  }



export const getDeleteUploadMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: DeleteUploadRequest }): Promise<AxiosResponse<unknown>> => {
    return deleteUpload(arg, options);
  }
}
export const getDeleteUploadMutationKey = () => [`/uploads/delete_upload`] as const;

export type DeleteUploadMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUpload>>>
export type DeleteUploadMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Delete Upload
 */
export const useDeleteUpload = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof deleteUpload>>, TError, Key, DeleteUploadRequest, Awaited<ReturnType<typeof deleteUpload>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteUploadMutationKey();
  const swrFn = getDeleteUploadMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Delete Multiple Files
 */
export const deleteMultipleFiles = (
    deleteMultipleFilesRequest: DeleteMultipleFilesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/uploads/delete_multiple_files`,
      deleteMultipleFilesRequest,options
    );
  }



export const getDeleteMultipleFilesMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: DeleteMultipleFilesRequest }): Promise<AxiosResponse<unknown>> => {
    return deleteMultipleFiles(arg, options);
  }
}
export const getDeleteMultipleFilesMutationKey = () => [`/uploads/delete_multiple_files`] as const;

export type DeleteMultipleFilesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMultipleFiles>>>
export type DeleteMultipleFilesMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Delete Multiple Files
 */
export const useDeleteMultipleFiles = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof deleteMultipleFiles>>, TError, Key, DeleteMultipleFilesRequest, Awaited<ReturnType<typeof deleteMultipleFiles>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteMultipleFilesMutationKey();
  const swrFn = getDeleteMultipleFilesMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Upload To Root
 */
export const uploadToRoot = (
    bodyUploadToRoot: BodyUploadToRoot, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {const formData = new FormData();
formData.append('file', bodyUploadToRoot.file)
formData.append('upload_id', bodyUploadToRoot.upload_id)
formData.append('infer_metadata', bodyUploadToRoot.infer_metadata.toString())

    return axios.default.post(
      `/uploads/upload_to_root`,
      formData,options
    );
  }



export const getUploadToRootMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: BodyUploadToRoot }): Promise<AxiosResponse<unknown>> => {
    return uploadToRoot(arg, options);
  }
}
export const getUploadToRootMutationKey = () => [`/uploads/upload_to_root`] as const;

export type UploadToRootMutationResult = NonNullable<Awaited<ReturnType<typeof uploadToRoot>>>
export type UploadToRootMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Upload To Root
 */
export const useUploadToRoot = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof uploadToRoot>>, TError, Key, BodyUploadToRoot, Awaited<ReturnType<typeof uploadToRoot>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUploadToRootMutationKey();
  const swrFn = getUploadToRootMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Upload To Folder
 */
export const uploadToFolder = (
    bodyUploadToFolder: BodyUploadToFolder, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {const formData = new FormData();
formData.append('file', bodyUploadToFolder.file)
formData.append('upload_id', bodyUploadToFolder.upload_id)
formData.append('folder_id', bodyUploadToFolder.folder_id)
formData.append('infer_metadata', bodyUploadToFolder.infer_metadata.toString())

    return axios.default.post(
      `/uploads/upload_to_folder`,
      formData,options
    );
  }



export const getUploadToFolderMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: BodyUploadToFolder }): Promise<AxiosResponse<unknown>> => {
    return uploadToFolder(arg, options);
  }
}
export const getUploadToFolderMutationKey = () => [`/uploads/upload_to_folder`] as const;

export type UploadToFolderMutationResult = NonNullable<Awaited<ReturnType<typeof uploadToFolder>>>
export type UploadToFolderMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Upload To Folder
 */
export const useUploadToFolder = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof uploadToFolder>>, TError, Key, BodyUploadToFolder, Awaited<ReturnType<typeof uploadToFolder>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUploadToFolderMutationKey();
  const swrFn = getUploadToFolderMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Dummy Research
 */
export const dummyResearch = (
    dummyResearchBody: DummyResearchBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DummyResearch200>> => {
    return axios.default.post(
      `/research/dummy`,
      dummyResearchBody,options
    );
  }



export const getDummyResearchMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: DummyResearchBody }): Promise<AxiosResponse<DummyResearch200>> => {
    return dummyResearch(arg, options);
  }
}
export const getDummyResearchMutationKey = () => [`/research/dummy`] as const;

export type DummyResearchMutationResult = NonNullable<Awaited<ReturnType<typeof dummyResearch>>>
export type DummyResearchMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Dummy Research
 */
export const useDummyResearch = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof dummyResearch>>, TError, Key, DummyResearchBody, Awaited<ReturnType<typeof dummyResearch>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDummyResearchMutationKey();
  const swrFn = getDummyResearchMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Query Crossref
 */
export const queryCrossref = (
    queryCrossrefRequest: QueryCrossrefRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CrossrefResponse>> => {
    return axios.default.post(
      `/uploads/query_crossref`,
      queryCrossrefRequest,options
    );
  }



export const getQueryCrossrefMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: QueryCrossrefRequest }): Promise<AxiosResponse<CrossrefResponse>> => {
    return queryCrossref(arg, options);
  }
}
export const getQueryCrossrefMutationKey = () => [`/uploads/query_crossref`] as const;

export type QueryCrossrefMutationResult = NonNullable<Awaited<ReturnType<typeof queryCrossref>>>
export type QueryCrossrefMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Query Crossref
 */
export const useQueryCrossref = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof queryCrossref>>, TError, Key, QueryCrossrefRequest, Awaited<ReturnType<typeof queryCrossref>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getQueryCrossrefMutationKey();
  const swrFn = getQueryCrossrefMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Query Google Books
 */
export const queryGoogleBooks = (
    queryGoogleBooksRequest: QueryGoogleBooksRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GoogleBooksResponse>> => {
    return axios.default.post(
      `/uploads/query_google_books`,
      queryGoogleBooksRequest,options
    );
  }



export const getQueryGoogleBooksMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: QueryGoogleBooksRequest }): Promise<AxiosResponse<GoogleBooksResponse>> => {
    return queryGoogleBooks(arg, options);
  }
}
export const getQueryGoogleBooksMutationKey = () => [`/uploads/query_google_books`] as const;

export type QueryGoogleBooksMutationResult = NonNullable<Awaited<ReturnType<typeof queryGoogleBooks>>>
export type QueryGoogleBooksMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Query Google Books
 */
export const useQueryGoogleBooks = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof queryGoogleBooks>>, TError, Key, QueryGoogleBooksRequest, Awaited<ReturnType<typeof queryGoogleBooks>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getQueryGoogleBooksMutationKey();
  const swrFn = getQueryGoogleBooksMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Infer Upload Metadata
 */
export const inferUploadMetadata = (
    inferUploadMetadataRequest: InferUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InferUploadMetadataResponse>> => {
    return axios.default.post(
      `/uploads/infer_metadata`,
      inferUploadMetadataRequest,options
    );
  }



export const getInferUploadMetadataMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: InferUploadMetadataRequest }): Promise<AxiosResponse<InferUploadMetadataResponse>> => {
    return inferUploadMetadata(arg, options);
  }
}
export const getInferUploadMetadataMutationKey = () => [`/uploads/infer_metadata`] as const;

export type InferUploadMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof inferUploadMetadata>>>
export type InferUploadMetadataMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Infer Upload Metadata
 */
export const useInferUploadMetadata = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof inferUploadMetadata>>, TError, Key, InferUploadMetadataRequest, Awaited<ReturnType<typeof inferUploadMetadata>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getInferUploadMetadataMutationKey();
  const swrFn = getInferUploadMetadataMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Delete Chat
 */
export const deleteChat = (
    params: DeleteChatParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.delete(
      `/research/delete_chat`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteChatMutationFetcher = (params: DeleteChatParams, options?: AxiosRequestConfig) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<unknown>> => {
    return deleteChat(params, options);
  }
}
export const getDeleteChatMutationKey = (params: DeleteChatParams,) => [`/research/delete_chat`, ...(params ? [params]: [])] as const;

export type DeleteChatMutationResult = NonNullable<Awaited<ReturnType<typeof deleteChat>>>
export type DeleteChatMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Delete Chat
 */
export const useDeleteChat = <TError = AxiosError<HTTPValidationError>>(
  params: DeleteChatParams, options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof deleteChat>>, TError, Key, Arguments, Awaited<ReturnType<typeof deleteChat>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteChatMutationKey(params);
  const swrFn = getDeleteChatMutationFetcher(params, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Rename Chat
 */
export const renameChat = (
    chatRenameRequest: ChatRenameRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/research/rename_chat`,
      chatRenameRequest,options
    );
  }



export const getRenameChatMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: ChatRenameRequest }): Promise<AxiosResponse<unknown>> => {
    return renameChat(arg, options);
  }
}
export const getRenameChatMutationKey = () => [`/research/rename_chat`] as const;

export type RenameChatMutationResult = NonNullable<Awaited<ReturnType<typeof renameChat>>>
export type RenameChatMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Rename Chat
 */
export const useRenameChat = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof renameChat>>, TError, Key, ChatRenameRequest, Awaited<ReturnType<typeof renameChat>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getRenameChatMutationKey();
  const swrFn = getRenameChatMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary View Public Chat
 */
export const viewPublicChat = (
    chatId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ViewPublicChatResponse>> => {
    return axios.default.get(
      `/research/view_public_chat/${chatId}`,options
    );
  }



export const getViewPublicChatKey = (chatId: string,) => [`/research/view_public_chat/${chatId}`] as const;

export type ViewPublicChatQueryResult = NonNullable<Awaited<ReturnType<typeof viewPublicChat>>>
export type ViewPublicChatQueryError = AxiosError<HTTPValidationError>

/**
 * @summary View Public Chat
 */
export const useViewPublicChat = <TError = AxiosError<HTTPValidationError>>(
  chatId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof viewPublicChat>>, TError> & { swrKey?: Key, enabled?: boolean }, axios?: AxiosRequestConfig }
) => {
  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(chatId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getViewPublicChatKey(chatId) : null);
  const swrFn = () => viewPublicChat(chatId, axiosOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Toggle Chat Public
 */
export const toggleChatPublic = (
    toggleChatPublicRequest: ToggleChatPublicRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/research/toggle_chat_public`,
      toggleChatPublicRequest,options
    );
  }



export const getToggleChatPublicMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: ToggleChatPublicRequest }): Promise<AxiosResponse<unknown>> => {
    return toggleChatPublic(arg, options);
  }
}
export const getToggleChatPublicMutationKey = () => [`/research/toggle_chat_public`] as const;

export type ToggleChatPublicMutationResult = NonNullable<Awaited<ReturnType<typeof toggleChatPublic>>>
export type ToggleChatPublicMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Toggle Chat Public
 */
export const useToggleChatPublic = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof toggleChatPublic>>, TError, Key, ToggleChatPublicRequest, Awaited<ReturnType<typeof toggleChatPublic>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getToggleChatPublicMutationKey();
  const swrFn = getToggleChatPublicMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Export Conversation
 */
export const exportConversation = (
    params: ExportConversationParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExportConversationResponse>> => {
    return axios.default.get(
      `/research/export_conversation`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getExportConversationKey = (params: ExportConversationParams,) => [`/research/export_conversation`, ...(params ? [params]: [])] as const;

export type ExportConversationQueryResult = NonNullable<Awaited<ReturnType<typeof exportConversation>>>
export type ExportConversationQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Export Conversation
 */
export const useExportConversation = <TError = AxiosError<HTTPValidationError>>(
  params: ExportConversationParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof exportConversation>>, TError> & { swrKey?: Key, enabled?: boolean }, axios?: AxiosRequestConfig }
) => {
  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getExportConversationKey(params) : null);
  const swrFn = () => exportConversation(params, axiosOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Bootstrap Session
 */
export const bootstrapSession = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BootstrapSessionResponse>> => {
    return axios.default.get(
      `/user/bootstrap_session`,options
    );
  }



export const getBootstrapSessionKey = () => [`/user/bootstrap_session`] as const;

export type BootstrapSessionQueryResult = NonNullable<Awaited<ReturnType<typeof bootstrapSession>>>
export type BootstrapSessionQueryError = AxiosError<unknown>

/**
 * @summary Bootstrap Session
 */
export const useBootstrapSession = <TError = AxiosError<unknown>>(
   options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof bootstrapSession>>, TError> & { swrKey?: Key, enabled?: boolean }, axios?: AxiosRequestConfig }
) => {
  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBootstrapSessionKey() : null);
  const swrFn = () => bootstrapSession(axiosOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Guess the RSS feed URL from a given URL by fetching the HTML
and looking for the RSS feed link in the header
 * @summary Guess Rss Url
 */
export const guessRssUrl = (
    params: GuessRssUrlParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    return axios.default.post(
      `/feeds/guess_rss_url`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGuessRssUrlMutationFetcher = (params: GuessRssUrlParams, options?: AxiosRequestConfig) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<string>> => {
    return guessRssUrl(params, options);
  }
}
export const getGuessRssUrlMutationKey = (params: GuessRssUrlParams,) => [`/feeds/guess_rss_url`, ...(params ? [params]: [])] as const;

export type GuessRssUrlMutationResult = NonNullable<Awaited<ReturnType<typeof guessRssUrl>>>
export type GuessRssUrlMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Guess Rss Url
 */
export const useGuessRssUrl = <TError = AxiosError<HTTPValidationError>>(
  params: GuessRssUrlParams, options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof guessRssUrl>>, TError, Key, Arguments, Awaited<ReturnType<typeof guessRssUrl>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getGuessRssUrlMutationKey(params);
  const swrFn = getGuessRssUrlMutationFetcher(params, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Preview an RSS feed by fetching the feed and returning the parsed feed
 * @summary Preview Rss Feed
 */
export const previewRssFeed = (
    params: PreviewRssFeedParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PreviewRssFeedResponse>> => {
    return axios.default.post(
      `/feeds/preview_rss_feed`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPreviewRssFeedMutationFetcher = (params: PreviewRssFeedParams, options?: AxiosRequestConfig) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<PreviewRssFeedResponse>> => {
    return previewRssFeed(params, options);
  }
}
export const getPreviewRssFeedMutationKey = (params: PreviewRssFeedParams,) => [`/feeds/preview_rss_feed`, ...(params ? [params]: [])] as const;

export type PreviewRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof previewRssFeed>>>
export type PreviewRssFeedMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Preview Rss Feed
 */
export const usePreviewRssFeed = <TError = AxiosError<HTTPValidationError>>(
  params: PreviewRssFeedParams, options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof previewRssFeed>>, TError, Key, Arguments, Awaited<ReturnType<typeof previewRssFeed>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getPreviewRssFeedMutationKey(params);
  const swrFn = getPreviewRssFeedMutationFetcher(params, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Index a new RSS feed into Vespa and Postgres
 * @summary Index Rss Feed
 */
export const indexRssFeed = (
    indexFeedRequest: IndexFeedRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/feeds/index_rss_feed`,
      indexFeedRequest,options
    );
  }



export const getIndexRssFeedMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: IndexFeedRequest }): Promise<AxiosResponse<unknown>> => {
    return indexRssFeed(arg, options);
  }
}
export const getIndexRssFeedMutationKey = () => [`/feeds/index_rss_feed`] as const;

export type IndexRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof indexRssFeed>>>
export type IndexRssFeedMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Index Rss Feed
 */
export const useIndexRssFeed = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof indexRssFeed>>, TError, Key, IndexFeedRequest, Awaited<ReturnType<typeof indexRssFeed>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getIndexRssFeedMutationKey();
  const swrFn = getIndexRssFeedMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Refetch a feed channel by fetching the feed again and updating the feed items
 * @summary Refetch Feed Channel
 */
export const refetchFeedChannel = (
    params: RefetchFeedChannelParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.post(
      `/feeds/refetch_feed_channel`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRefetchFeedChannelMutationFetcher = (params: RefetchFeedChannelParams, options?: AxiosRequestConfig) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<unknown>> => {
    return refetchFeedChannel(params, options);
  }
}
export const getRefetchFeedChannelMutationKey = (params: RefetchFeedChannelParams,) => [`/feeds/refetch_feed_channel`, ...(params ? [params]: [])] as const;

export type RefetchFeedChannelMutationResult = NonNullable<Awaited<ReturnType<typeof refetchFeedChannel>>>
export type RefetchFeedChannelMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Refetch Feed Channel
 */
export const useRefetchFeedChannel = <TError = AxiosError<HTTPValidationError>>(
  params: RefetchFeedChannelParams, options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof refetchFeedChannel>>, TError, Key, Arguments, Awaited<ReturnType<typeof refetchFeedChannel>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getRefetchFeedChannelMutationKey(params);
  const swrFn = getRefetchFeedChannelMutationFetcher(params, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Retrieve the full feed item by ID
 * @summary Get Feed Item
 */
export const getFeedItem = (
    params: GetFeedItemParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FeedItem>> => {
    return axios.default.get(
      `/feeds/feed_item`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGetFeedItemKey = (params: GetFeedItemParams,) => [`/feeds/feed_item`, ...(params ? [params]: [])] as const;

export type GetFeedItemQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItem>>>
export type GetFeedItemQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Feed Item
 */
export const useGetFeedItem = <TError = AxiosError<HTTPValidationError>>(
  params: GetFeedItemParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getFeedItem>>, TError> & { swrKey?: Key, enabled?: boolean }, axios?: AxiosRequestConfig }
) => {
  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetFeedItemKey(params) : null);
  const swrFn = () => getFeedItem(params, axiosOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Mark a feed item as deleted
 * @summary Delete Feed Item
 */
export const deleteFeedItem = (
    deleteFeedItemRequest: DeleteFeedItemRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DeleteFeedItemResponse>> => {
    return axios.default.delete(
      `/feeds/delete_feed_item`,{data:
      deleteFeedItemRequest, ...options}
    );
  }



export const getDeleteFeedItemMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: DeleteFeedItemRequest }): Promise<AxiosResponse<DeleteFeedItemResponse>> => {
    return deleteFeedItem(arg, options);
  }
}
export const getDeleteFeedItemMutationKey = () => [`/feeds/delete_feed_item`] as const;

export type DeleteFeedItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFeedItem>>>
export type DeleteFeedItemMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Delete Feed Item
 */
export const useDeleteFeedItem = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof deleteFeedItem>>, TError, Key, DeleteFeedItemRequest, Awaited<ReturnType<typeof deleteFeedItem>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteFeedItemMutationKey();
  const swrFn = getDeleteFeedItemMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Mark a feed channel as deleted
 * @summary Delete Feed Channels
 */
export const deleteFeedChannels = (
    deleteFeedChannelRequest: DeleteFeedChannelRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DeleteFeedChannelResponse>> => {
    return axios.default.delete(
      `/feeds/delete_feed_channel`,{data:
      deleteFeedChannelRequest, ...options}
    );
  }



export const getDeleteFeedChannelsMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: DeleteFeedChannelRequest }): Promise<AxiosResponse<DeleteFeedChannelResponse>> => {
    return deleteFeedChannels(arg, options);
  }
}
export const getDeleteFeedChannelsMutationKey = () => [`/feeds/delete_feed_channel`] as const;

export type DeleteFeedChannelsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFeedChannels>>>
export type DeleteFeedChannelsMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Delete Feed Channels
 */
export const useDeleteFeedChannels = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof deleteFeedChannels>>, TError, Key, DeleteFeedChannelRequest, Awaited<ReturnType<typeof deleteFeedChannels>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteFeedChannelsMutationKey();
  const swrFn = getDeleteFeedChannelsMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Create Table
 */
export const createTable = (
    createTableRequest: CreateTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableMetadata>> => {
    return axios.default.post(
      `/tables/create`,
      createTableRequest,options
    );
  }



export const getCreateTableMutationFetcher = ( options?: AxiosRequestConfig) => {
  return (_: Key, { arg }: { arg: CreateTableRequest }): Promise<AxiosResponse<TableMetadata>> => {
    return createTable(arg, options);
  }
}
export const getCreateTableMutationKey = () => [`/tables/create`] as const;

export type CreateTableMutationResult = NonNullable<Awaited<ReturnType<typeof createTable>>>
export type CreateTableMutationError = AxiosError<HTTPValidationError>

/**
 * @summary Create Table
 */
export const useCreateTable = <TError = AxiosError<HTTPValidationError>>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof createTable>>, TError, Key, CreateTableRequest, Awaited<ReturnType<typeof createTable>>> & { swrKey?: string }, axios?: AxiosRequestConfig}
) => {

  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateTableMutationKey();
  const swrFn = getCreateTableMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Get Latest
 */
export const getLatest = (
    params: GetLatestParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.get(
      `/tables/get_latest`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGetLatestKey = (params: GetLatestParams,) => [`/tables/get_latest`, ...(params ? [params]: [])] as const;

export type GetLatestQueryResult = NonNullable<Awaited<ReturnType<typeof getLatest>>>
export type GetLatestQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Latest
 */
export const useGetLatest = <TError = AxiosError<HTTPValidationError>>(
  params: GetLatestParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLatest>>, TError> & { swrKey?: Key, enabled?: boolean }, axios?: AxiosRequestConfig }
) => {
  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLatestKey(params) : null);
  const swrFn = () => getLatest(params, axiosOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary Ping
 */
export const ping = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.default.get(
      `/ping`,options
    );
  }



export const getPingKey = () => [`/ping`] as const;

export type PingQueryResult = NonNullable<Awaited<ReturnType<typeof ping>>>
export type PingQueryError = AxiosError<unknown>

/**
 * @summary Ping
 */
export const usePing = <TError = AxiosError<unknown>>(
   options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof ping>>, TError> & { swrKey?: Key, enabled?: boolean }, axios?: AxiosRequestConfig }
) => {
  const {swr: swrOptions, axios: axiosOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPingKey() : null);
  const swrFn = () => ping(axiosOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

