import { Favicon } from "@/components/Favicon";
import { FeedSelector } from "@/components/FeedSelector";
import { UploadCoverImage } from "@/components/UploadCoverImage";
import { UploadSelector } from "@/components/UploadSelector";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/AppContext";
import type { FeedChannelId, UploadId } from "@/idGenerators";
import { formatAuthors, formatTitle } from "@/lib/utils";
import {
	ArrowUp,
	Books,
	CircleNotch,
	Paperclip,
	Rss,
	X,
} from "@phosphor-icons/react";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const ChatInput = observer(
	({
		onSubmit,
		isPending,
	}: {
		onSubmit: ({
			content,
			focusUploadIds,
			focusFeedChannelIds,
		}: {
			content: string;
			focusUploadIds: Set<UploadId>;
			focusFeedChannelIds: Set<FeedChannelId>;
		}) => void;
		isPending: boolean;
	}) => {
		const [content, setContent] = useState("");
		const appContext = useAppContext();
		const { newChatUploadIds, newChatFeedChannelIds } = appContext;
		const setFocusUploadIds = (focusUploadIds: UploadId[]) => {
			runInAction(() => {
				appContext.newChatUploadIds = new Set(focusUploadIds);
			});
		};
		const setFocusFeedChannelIds = (feedChannelIds: FeedChannelId[]) => {
			runInAction(() => {
				appContext.newChatFeedChannelIds = new Set(feedChannelIds);
			});
		};

		return (
			<div className="flex w-full min-w-0 items-center justify-center bg-transparent px-4">
				<div className="mb-4 w-full max-w-2xl rounded-xl border border-neutral-200 shadow-md focus-within:border-blue-300 focus-within:ring-1 focus-within:ring-blue-100">
					{newChatUploadIds.size > 0 && (
						<div className="px-2 pt-2">
							<h2 className="text-neutral-500 text-sm">Uploads</h2>
							<div className="flex w-full gap-2 overflow-y-scroll pt-2">
								{[...newChatUploadIds].map((uploadId) => {
									const upload = appContext.getUploadById(uploadId);
									if (!upload) {
										return null;
									}
									return (
										<div
											key={uploadId}
											className="group relative flex max-w-48 items-center gap-2 rounded-lg border bg-neutral-100 p-2"
										>
											<div>
												<UploadCoverImage
													size={128}
													upload_id={upload.upload_id as UploadId}
													upload_status={upload.upload_status}
													className={(uploadStatus) =>
														clsx(
															"h-6 max-w-6 rounded-xs",
															uploadStatus === "ready" && "shadow",
														)
													}
												/>
											</div>
											<div className="grow truncate text-left">
												<h2 className="min-w-0 truncate text-neutral-700 text-sm leading-4">
													{formatTitle({
														title: upload.upload_title,
														subtitle: upload.upload_subtitle,
														filename: upload.file_name,
													})}
												</h2>
												<h3 className="min-w-0 truncate text-neutral-500 text-sm leading-4">
													{formatAuthors(upload.upload_authors ?? [])}
													{upload.upload_year_published &&
														`, ${upload.upload_year_published}`}
												</h3>
											</div>
											<button
												type="button"
												className="-top-2 -right-2 absolute rounded-full border bg-neutral-50 p-0.5 text-neutral-500 text-sm opacity-0 hover:bg-neutral-100 hover:text-neutral-700 group-hover:opacity-100"
												onClick={(e) => {
													e.stopPropagation();
													runInAction(() => {
														appContext.newChatUploadIds.delete(uploadId);
													});
												}}
											>
												<X weight="bold" />
											</button>
										</div>
									);
								})}
							</div>
						</div>
					)}
					{newChatFeedChannelIds.size > 0 && (
						<div className="px-2 pt-3">
							<h2 className="text-neutral-500 text-sm">Channels</h2>
							<div className="flex w-full gap-2 overflow-y-scroll pt-2">
								{[...newChatFeedChannelIds].map((feedChannelId) => {
									const channel =
										appContext.feedChannelsById?.get(feedChannelId);
									if (!channel) {
										return null;
									}
									return (
										<div
											key={feedChannelId}
											className="group relative flex max-w-48 items-center gap-2 rounded-lg border bg-neutral-100 p-2"
										>
											{channel.feed_channel_link ? (
												<Favicon
													url={channel.feed_channel_link}
													alt={channel.file_name}
													className="h-8 w-8 shrink-0 rounded bg-neutral-100"
												/>
											) : (
												<div className="h-8 w-8 shrink-0 bg-neutral-200" />
											)}
											<div className="grow truncate text-left">
												<h2 className="min-w-0 truncate text-neutral-700 text-sm leading-4">
													{channel.file_name}
												</h2>
												<h3 className="min-w-0 truncate text-neutral-500 text-sm leading-4">
													{channel.feed_channel_subtitle}
												</h3>
											</div>
											<button
												type="button"
												className="-top-2 -right-2 absolute rounded-full border bg-neutral-50 p-0.5 text-neutral-500 text-sm opacity-0 hover:bg-neutral-100 hover:text-neutral-700 group-hover:opacity-100"
												onClick={(e) => {
													e.stopPropagation();
													runInAction(() => {
														appContext.newChatFeedChannelIds.delete(
															feedChannelId,
														);
													});
												}}
											>
												<X weight="bold" />
											</button>
										</div>
									);
								})}
							</div>
						</div>
					)}
					<textarea
						className="w-full resize-none rounded-xl px-4 py-3 outline-none"
						rows={2}
						placeholder="Ask a question..."
						value={content}
						onChange={(e) => setContent(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								onSubmit({
									content,
									focusUploadIds: newChatUploadIds,
									focusFeedChannelIds: newChatFeedChannelIds,
								});
								setContent("");
							}
						}}
						// biome-ignore lint/a11y/noAutofocus: <explanation>
						autoFocus
					/>
					<div className="flex w-full justify-between px-2 pb-2">
						<Popover>
							<PopoverTrigger>
								<Tooltip>
									<TooltipTrigger className="p-2">
										<Paperclip
											weight="bold"
											className="text-neutral-500 text-xl"
										/>
									</TooltipTrigger>
									<TooltipContent>Attach documents</TooltipContent>
								</Tooltip>
							</PopoverTrigger>
							<PopoverContent
								align="start"
								className="max-w-max rounded-lg p-1"
							>
								<UploadSelector
									popoverTrigger={
										<div className="flex w-full items-center gap-2 p-2">
											<Books
												className="text-base text-neutral-500"
												weight="duotone"
											/>
											Select from library
										</div>
									}
									selectedUploadIds={newChatUploadIds}
									onChange={setFocusUploadIds}
								/>
								<FeedSelector
									popoverTrigger={
										<div className="flex w-full items-center gap-2 p-2">
											<Rss
												className="text-base text-neutral-500"
												weight="duotone"
											/>
											Select feeds
										</div>
									}
									selectedFeedChannelIds={newChatFeedChannelIds}
									onChange={setFocusFeedChannelIds}
								/>
							</PopoverContent>
						</Popover>
						<button
							className={clsx(
								"rounded-full bg-emerald-500 p-2 text-white text-xl",
								isPending && "animate-spin cursor-not-allowed",
							)}
							type="button"
							onClick={() => {
								onSubmit({
									content,
									focusUploadIds: newChatUploadIds,
									focusFeedChannelIds: newChatFeedChannelIds,
								});
								setContent("");
							}}
							disabled={isPending}
						>
							{isPending ? (
								<CircleNotch weight="bold" />
							) : (
								<ArrowUp weight="bold" />
							)}
						</button>
					</div>
				</div>
			</div>
		);
	},
);
