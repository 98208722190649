import type { JSONContent } from "@tiptap/core";
import Blockquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Code from "@tiptap/extension-code";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import {
	type MarkdownSerializerState,
	MarkdownSerializer as ProseMirrorMarkdownSerializer,
	defaultMarkdownSerializer,
} from "@tiptap/pm/markdown";
import type { Mark, Node, Schema } from "@tiptap/pm/model";
import omit from "lodash.omit";
import uniq from "lodash.uniq";

type MarkSerializerSpec = {
	/// The string that should appear before a piece of content marked
	/// by this mark, either directly or as a function that returns an
	/// appropriate string.
	open:
		| string
		| ((
				state: MarkdownSerializerState,
				mark: Mark,
				parent: Node,
				index: number,
		  ) => string);
	/// The string that should appear after a piece of content marked by
	/// this mark.
	close:
		| string
		| ((
				state: MarkdownSerializerState,
				mark: Mark,
				parent: Node,
				index: number,
		  ) => string);
	/// When `true`, this indicates that the order in which the mark's
	/// opening and closing syntax appears relative to other mixable
	/// marks can be varied. (For example, you can say `**a *b***` and
	/// `*a **b***`, but not `` `a *b*` ``.)
	mixable?: boolean;
	/// When enabled, causes the serializer to move enclosing whitespace
	/// from inside the marks to outside the marks. This is necessary
	/// for emphasis marks as CommonMark does not permit enclosing
	/// whitespace inside emphasis marks, see:
	/// http:///spec.commonmark.org/0.26/#example-330
	expelEnclosingWhitespace?: boolean;
	/// Can be set to `false` to disable character escaping in a mark. A
	/// non-escaping mark has to have the highest precedence (must
	/// always be the innermost mark).
	escape?: boolean;
};

const tableMap = new WeakMap();

// @ts-ignore
function isInTable(node) {
	return tableMap.has(node);
}

// @ts-ignore
export function renderHardBreak(state, node, parent, index) {
	const br = isInTable(parent) ? "<br>" : "\\\n";
	for (let i = index + 1; i < parent.childCount; i += 1) {
		if (parent.child(i).type !== node.type) {
			state.write(br);
			return;
		}
	}
}

// @ts-ignore
function isInBlockTable(node) {
	return tableMap.get(node);
}

// @ts-ignore
function containsOnlyText(node) {
	if (node.childCount === 1) {
		const child = node.child(0);
		return child.isText && child.marks.length === 0;
	}

	return false;
}

// @ts-ignore
function containsParagraphWithOnlyText(cell) {
	if (cell.childCount === 1) {
		const child = cell.child(0);
		if (child.type.name === "paragraph") {
			return containsOnlyText(child);
		}
	}

	return false;
}

// @ts-ignore
function getRowsAndCells(table) {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const cells: any[] = [];
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const rows: any[] = [];
	// @ts-ignore
	table.descendants((n) => {
		if (n.type.name === "tableCell" || n.type.name === "tableHeader") {
			cells.push(n);
			return false;
		}

		if (n.type.name === "tableRow") {
			rows.push(n);
		}

		return true;
	});
	return { rows, cells };
}

// @ts-ignore
function setIsInBlockTable(table, value) {
	tableMap.set(table, value);

	const { rows, cells } = getRowsAndCells(table);
	for (const row of rows) {
		tableMap.set(row, value);
	}
	for (const cell of cells) {
		tableMap.set(cell, value);
		if (cell.childCount && cell.child(0).type.name === "paragraph")
			tableMap.set(cell.child(0), value);
	}
}

// @ts-ignore
export function shouldRenderHTMLTable(table) {
	const { rows, cells } = getRowsAndCells(table);

	const cellChildCount = Math.max(...cells.map((cell) => cell.childCount));
	const maxColspan = Math.max(...cells.map((cell) => cell.attrs.colspan));
	const maxRowspan = Math.max(...cells.map((cell) => cell.attrs.rowspan));

	const rowChildren = rows.map((row) =>
		uniq(getChildren(row).map((cell) => cell.type.name)),
	);
	const cellTypeInFirstRow = rowChildren[0];
	const cellTypesInOtherRows = uniq(rowChildren.slice(1).map(([type]) => type));

	// if the first row has headers, and there are no headers anywhere else, render markdown table
	if (
		!(
			cellTypeInFirstRow.length === 1 &&
			cellTypeInFirstRow[0] === "tableHeader" &&
			cellTypesInOtherRows.length === 1 &&
			cellTypesInOtherRows[0] === "tableCell"
		)
	) {
		return true;
	}

	if (cellChildCount === 1 && maxColspan === 1 && maxRowspan === 1) {
		// if all rows contain only one paragraph each and no rowspan/colspan, render markdown table
		const children = uniq(cells.map((cell) => cell.child(0).type.name));
		if (children.length === 1 && children[0] === "paragraph") {
			return false;
		}
	}

	return true;
}

// @ts-ignore
function unsetIsInBlockTable(table) {
	tableMap.delete(table);

	const { rows, cells } = getRowsAndCells(table);
	for (const row of rows) {
		tableMap.delete(row);
	}
	for (const cell of cells) {
		tableMap.delete(cell);
		if (cell.childCount) tableMap.delete(cell.child(0));
	}
}

// see https://github.com/gitlabhq/gitlabhq/blob/master/app/assets/javascripts/content_editor/services/serialization_helpers.js
// @ts-ignore
export function renderTable(state, node) {
	state.flushClose();
	setIsInBlockTable(node, shouldRenderHTMLTable(node));

	if (isInBlockTable(node)) renderTagOpen(state, "table");

	state.renderContent(node);

	if (isInBlockTable(node)) renderTagClose(state, "table");

	// ensure at least one blank line after any table
	state.closeBlock(node);
	state.flushClose();

	unsetIsInBlockTable(node);
}

// @ts-ignore
export function renderTableCell(state, node) {
	if (!isInBlockTable(node) || containsParagraphWithOnlyText(node)) {
		state.renderInline(node.child(0));
	} else {
		state.renderContent(node);
	}
}

const defaultAttrs = {
	td: { colspan: 1, rowspan: 1, colwidth: null },
	th: { colspan: 1, rowspan: 1, colwidth: null },
};

const defaultIgnoreAttrs = ["uploadMarkdown", "uploadMapKey"];

const ignoreAttrs = {
	dd: ["isTerm"],
	dt: ["isTerm"],
};

// @ts-ignore
const shouldIgnoreAttr = (tagName, attrKey, attrValue) =>
	// @ts-ignore
	ignoreAttrs[tagName]?.includes(attrKey) ||
	defaultIgnoreAttrs.includes(attrKey) ||
	// @ts-ignore
	defaultAttrs[tagName]?.[attrKey] === attrValue;

function htmlEncode(str = "") {
	return str
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/'/g, "&#39;")
		.replace(/"/g, "&#34;");
}

// @ts-ignore
export function openTag(tagName, attrs) {
	let str = `<${tagName}`;

	str += Object.entries(attrs || {})
		.map(([key, value]) => {
			if (shouldIgnoreAttr(tagName, key, value)) return "";

			return ` ${key}="${htmlEncode(value?.toString())}"`;
		})
		.join("");

	return `${str}>`;
}

// @ts-ignore
export function closeTag(tagName) {
	return `</${tagName}>`;
}

// @ts-ignore
function renderTagOpen(state, tagName, attrs = undefined) {
	state.ensureNewLine();
	state.write(openTag(tagName, attrs));
}

// @ts-ignore
function renderTagClose(state, tagName, insertNewline = true) {
	state.write(closeTag(tagName));
	if (insertNewline) state.ensureNewLine();
}

// @ts-ignore
function renderTableRowAsHTML(state, node) {
	renderTagOpen(state, "tr");

	// @ts-ignore
	node.forEach((cell, _, i) => {
		const tag = cell.type.name === "tableHeader" ? "th" : "td";

		renderTagOpen(
			state,
			tag,
			// @ts-ignore
			omit(cell.attrs, "uploadMapKey", "uploadMarkdown"),
		);

		if (!containsParagraphWithOnlyText(cell)) {
			state.closeBlock(node);
			state.flushClose();
		}

		state.render(cell, node, i);
		state.flushClose(1);

		renderTagClose(state, tag);
	});

	renderTagClose(state, "tr");
}

// @ts-ignore
function renderTableHeaderRowAsMarkdown(state, node, cellWidths) {
	state.flushClose(1);

	state.write("|");
	// @ts-ignore
	node.forEach((cell, _, i) => {
		if (i) state.write("|");

		state.write(cell.attrs.align === "center" ? ":" : "-");
		state.write(state.repeat("-", cellWidths[i]));
		state.write(
			cell.attrs.align === "center" || cell.attrs.align === "right" ? ":" : "-",
		);
	});
	state.write("|");

	state.closeBlock(node);
}

// @ts-ignore
function renderTableRowAsMarkdown(state, node, isHeaderRow = false) {
	const cellWidths: number[] = [];

	state.flushClose(1);

	state.write("| ");
	// @ts-ignore
	node.forEach((cell, _, i) => {
		if (i) state.write(" | ");

		const { length } = state.out;
		state.render(cell, node, i);
		cellWidths.push(state.out.length - length);
	});
	state.write(" |");

	state.closeBlock(node);

	if (isHeaderRow) renderTableHeaderRowAsMarkdown(state, node, cellWidths);
}

// @ts-ignore
export function renderTableRow(state, node) {
	if (isInBlockTable(node)) {
		renderTableRowAsHTML(state, node);
	} else {
		renderTableRowAsMarkdown(
			state,
			node,
			node.child(0).type.name === "tableHeader",
		);
	}
}

// @ts-ignore
function getChildren(node) {
	const children = [];
	for (let i = 0; i < node.childCount; i += 1) {
		children.push(node.child(i));
	}
	return children;
}

// @ts-ignore
export function renderOrderedList(state, node) {
	const { parens } = node.attrs;
	const start = node.attrs.start || 1;
	const maxW = String(start + node.childCount - 1).length;
	const space = state.repeat(" ", maxW + 2);
	const delimiter = parens ? ")" : ".";
	// @ts-ignore
	state.renderList(node, space, (i) => {
		const nStr = String(start + i);
		return `${state.repeat(" ", maxW - nStr.length) + nStr}${delimiter} `;
	});
}

// @ts-ignore
export function isPlainURL(link, parent, index, side) {
	if (link.attrs.title || !/^\w+:/.test(link.attrs.href)) return false;
	const content = parent.child(index + (side < 0 ? -1 : 0));
	if (
		!content.isText ||
		content.text !== link.attrs.href ||
		content.marks[content.marks.length - 1] !== link
	)
		return false;
	if (index === (side < 0 ? 1 : parent.childCount - 1)) return true;
	const next = parent.child(index + (side < 0 ? -2 : 1));
	return !link.isInSet(next.marks);
}

const serializerMarks: { [mark: string]: MarkSerializerSpec } = {
	...defaultMarkdownSerializer.marks,
	[Bold.name]: defaultMarkdownSerializer.marks.strong,
	[Strike.name]: {
		open: "~~",
		close: "~~",
		mixable: true,
		expelEnclosingWhitespace: true,
	},
	[Italic.name]: {
		open: "_",
		close: "_",
		mixable: true,
		expelEnclosingWhitespace: true,
	},
	[Code.name]: defaultMarkdownSerializer.marks.code,
};

// see https://github.com/justinmoon/tiptap-markdown-demo
const serializerNodes: {
	[key: string]: (
		state: MarkdownSerializerState,
		node: Node,
		parent: Node,
		index: number,
	) => void;
} = {
	...defaultMarkdownSerializer.nodes,
	[Paragraph.name]: defaultMarkdownSerializer.nodes.paragraph,
	[BulletList.name]: defaultMarkdownSerializer.nodes.bullet_list,
	[ListItem.name]: defaultMarkdownSerializer.nodes.list_item,
	[OrderedList.name]: renderOrderedList,
	[Blockquote.name]: (state, node) => {
		if (node.attrs.multiline) {
			state.write(">>>");
			state.ensureNewLine();
			state.renderContent(node);
			state.ensureNewLine();
			state.write(">>>");
			state.closeBlock(node);
		} else {
			state.wrapBlock("> ", null, node, () => state.renderContent(node));
		}
	},
	[Table.name]: (state, node) => {
		tableMap.set(node, true);
		renderTagOpen(state, "table");
		state.renderContent(node);
		renderTagClose(state, "table");
	},
	[Table.name]: renderTable,
	[TableCell.name]: renderTableCell,
	[TableHeader.name]: renderTableCell,
	[TableRow.name]: renderTableRow,
};

// see https://github.com/justinmoon/tiptap-markdown-demo/blob/master/src/Tiptap.jsx#L69
export function tiptapToMarkdown(schema: Schema, content: JSONContent) {
	const proseMirrorDocument = schema.nodeFromJSON(content);

	const serializer = new ProseMirrorMarkdownSerializer(
		serializerNodes,
		serializerMarks,
	);

	return serializer.serialize(proseMirrorDocument, {
		tightLists: true,
	});
}
