import { Favicon } from "@/components/Favicon";
import { UploadCoverImage } from "@/components/UploadCoverImage";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import type { FeedChannelId, UploadId } from "@/idGenerators";
import { formatAuthors, formatDate, formatTitle } from "@/lib/utils";
import type {
	FeedChannel,
	SearchFeedItemsResultOutput as SearchFeedItemsResult,
	SearchLibraryResultOutput as SearchLibraryResult,
	SearchResultsActionOutput as SearchResultsAction,
	Upload,
} from "@api/schemas";
import { CaretRight, Copy, MagnifyingGlass } from "@phosphor-icons/react";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import Markdown from "react-markdown";
import { toast } from "sonner";

export const ResultWithinGroup = observer(
	({
		result,
		upload,
	}: {
		result: SearchLibraryResult;
		upload: Upload;
	}) => {
		const chatContext = useChatContext();

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const onSelect = useCallback(() => {
			runInAction(() => {
				chatContext.activeSearchResult = {
					...result,
					upload: upload,
				};
			});
		}, [result, upload]);

		return (
			<div
				key={result.chunk_id}
				className={clsx(
					"group cursor-pointer rounded-md border",

					chatContext.activeSearchResult?.type === "library" &&
						chatContext.activeSearchResult?.chunk_id === result.chunk_id
						? "border-blue-300 bg-blue-50"
						: "hover:bg-blue-50",
				)}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<p className="line-clamp-3 rounded-t-md px-1.5 py-0.5 text-neutral-600 text-sm">
					{result.chunk_text}
				</p>
				<div
					className={clsx(
						"rounded-b-md border-t bg-neutral-50 px-1.5 py-0.5 text-neutral-600 text-xs",
						chatContext.activeSearchResult?.type === "library" &&
							chatContext.activeSearchResult?.chunk_id === result.chunk_id
							? "bg-blue-100"
							: "group-hover:bg-blue-100",
					)}
				>
					{result.chunk_page_indices.length > 1 ? (
						<>
							Pages {result.chunk_page_indices[0] + 1}-
							{result.chunk_page_indices[result.chunk_page_indices.length - 1] +
								1}
						</>
					) : (
						<>Page {result.chunk_page_indices[0] + 1}</>
					)}
				</div>
			</div>
		);
	},
);

const SearchResultGroup = observer(
	({
		upload,
		results,
	}: {
		upload: Upload;
		results: SearchLibraryResult[];
	}) => {
		const [open, setOpen] = useState(false);

		return (
			<Collapsible
				open={open}
				onOpenChange={setOpen}
				className="w-full min-w-0"
			>
				<CollapsibleTrigger className="group flex w-full min-w-0 grow items-center justify-between gap-2 truncate rounded-lg px-2 py-1.5 text-left hover:bg-neutral-100">
					<div
						className={clsx(
							"mr-1 transform text-lg text-neutral-400 transition-transform group-hover:text-neutral-900",
							open ? "rotate-90" : "rotate-0",
						)}
					>
						<CaretRight weight="bold" />
					</div>
					<div className="flex w-0 min-w-0 grow items-center gap-2 truncate">
						<UploadCoverImage
							size={128}
							upload_id={upload.upload_id as UploadId}
							upload_status={upload.upload_status}
							className={(uploadStatus) =>
								clsx(
									"h-9 max-w-8 rounded-xs",
									uploadStatus === "ready" && "shadow",
								)
							}
						/>
						<div className="w-full min-w-0 shrink grow truncate">
							<h2 className="w-full min-w-0 truncate text-sm leading-4">
								{formatTitle({
									title: upload.upload_title,
									subtitle: upload.upload_subtitle,
									filename: upload.file_name,
								})}
							</h2>
							<h3 className="flex items-center text-neutral-500 text-sm leading-4">
								{formatAuthors(upload.upload_authors ?? [])}
								{upload.upload_year_published &&
									`, ${upload.upload_year_published}`}
							</h3>
						</div>
					</div>
					<h3 className="mt-0.5 min-w-5 max-w-max shrink-0 rounded-full bg-blue-50 px-1.5 py-0.5 text-center font-semibold text-blue-500 text-xs leading-4">
						{results.length}
					</h3>
				</CollapsibleTrigger>
				<CollapsibleContent className="mt-1 flex w-full min-w-0 flex-col gap-2">
					{results.map((result) => (
						<ResultWithinGroup
							key={result.chunk_id}
							result={result}
							upload={upload}
						/>
					))}
				</CollapsibleContent>
			</Collapsible>
		);
	},
);

const SearchFeedItemsResultGroup = observer(
	({
		feedChannel,
		results,
	}: {
		feedChannel: FeedChannel;
		results: SearchFeedItemsResult[];
	}) => {
		const [open, setOpen] = useState(true);

		return (
			<Collapsible open={open} onOpenChange={setOpen}>
				<CollapsibleTrigger className="group flex w-full min-w-0 grow items-center justify-between gap-2 truncate rounded-lg px-2 py-1.5 text-left hover:bg-neutral-100">
					<div
						className={clsx(
							"transform text-lg text-neutral-400 transition-transform group-hover:text-neutral-900",
							open ? "rotate-90" : "rotate-0",
						)}
					>
						<CaretRight weight="bold" />
					</div>
					<div className="flex min-w-0 grow items-center gap-2 truncate">
						{feedChannel.feed_channel_link ? (
							<Favicon
								url={feedChannel.feed_channel_link || ""}
								alt={feedChannel.file_name || ""}
								className="h-8 w-8 shrink-0 rounded-md bg-neutral-200"
							/>
						) : (
							<div className="h-8 w-8 shrink-0 rounded-md bg-neutral-200" />
						)}

						<div className="min-w-0 truncate">
							<h2 className="min-w-0 truncate text-sm leading-4">
								{feedChannel.file_name}
							</h2>
							<h3 className="mt-1 min-w-0 truncate text-neutral-500 text-sm leading-4">
								{feedChannel.feed_channel_subtitle}
								{feedChannel.feed_channel_pub_date &&
									`, ${formatDate(feedChannel.feed_channel_pub_date)}`}
							</h3>
						</div>
					</div>

					<h3 className="mt-0.5 min-w-5 max-w-max shrink-0 rounded-full bg-blue-50 px-1.5 py-0.5 text-center font-semibold text-blue-500 text-xs leading-4">
						{results.length}
					</h3>
				</CollapsibleTrigger>
				<CollapsibleContent>
					<div className="mt-2 flex flex-col gap-2 pb-1">
						{results.map((result) => (
							<ResultWithinFeedItemsGroup
								key={result.feed_item_chunk_id}
								result={result}
							/>
						))}
					</div>
				</CollapsibleContent>
			</Collapsible>
		);
	},
);

const ResultWithinFeedItemsGroup = observer(
	({
		result,
	}: {
		result: SearchFeedItemsResult;
	}) => {
		const chatContext = useChatContext();
		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const onSelect = useCallback(() => {
			runInAction(() => {
				chatContext.activeSearchResult = {
					...result,
				};
			});
		}, [result]);

		return (
			<div
				key={result.feed_item_chunk_id}
				className={clsx(
					"group flex w-full cursor-pointer items-start justify-center overflow-hidden rounded-md border border-neutral-200",

					chatContext.activeSearchResult?.type === "feed_items" &&
						chatContext.activeSearchResult?.feed_item_chunk_id ===
							result.feed_item_chunk_id
						? "border-blue-300 bg-blue-50 ring-2 ring-blue-100"
						: "hover:border-blue-200 hover:bg-blue-50",
				)}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<Markdown className="prose prose-neutral prose-sm h-28 w-[175%] min-w-0 shrink-0 scale-50 opacity-70">
					{result.feed_item_chunk_text}
				</Markdown>
			</div>
		);
	},
);

export const ChatSearchResults = observer(
	({ searchResultsAction }: { searchResultsAction: SearchResultsAction }) => {
		const [open, setOpen] = useState(false);
		const appContext = useAppContext();

		const libraryResults =
			searchResultsAction.search_response.library_results.reduce(
				(acc, result) => {
					const uploadResults = acc.get(result.upload_id);

					if (!uploadResults) {
						acc.set(result.upload_id, [result]);
					} else {
						acc.set(result.upload_id, [...uploadResults, result]);
					}
					return acc;
				},
				new Map<string, SearchLibraryResult[]>(),
			);

		const feedResults =
			searchResultsAction.search_response.feed_items_results?.reduce(
				(acc, result) => {
					const feedChannelResults = acc.get(result.feed_channel_id);

					if (!feedChannelResults) {
						acc.set(result.feed_channel_id, [result]);
					} else {
						acc.set(result.feed_channel_id, [...feedChannelResults, result]);
					}
					return acc;
				},
				new Map<string, SearchFeedItemsResult[]>(),
			) ?? new Map<string, SearchFeedItemsResult[]>();

		return (
			<Collapsible
				open={open}
				onOpenChange={setOpen}
				className="w-full min-w-0"
			>
				<CollapsibleTrigger className="mt-2 flex w-full max-w-max cursor-pointer items-center gap-2 rounded-full bg-neutral-100 py-1 pr-4 pl-2.5 text-sm hover:bg-neutral-200">
					<MagnifyingGlass weight="bold" className="text-neutral-500" />
					<Tooltip>
						<TooltipTrigger className="min-w-0 truncate text-neutral-500">
							{searchResultsAction.search_response.query}
						</TooltipTrigger>
						<TooltipContent>
							{searchResultsAction.search_response.query}
						</TooltipContent>
					</Tooltip>
					<div
						className={clsx(
							"ml-2 transform text-neutral-500 transition-transform hover:text-neutral-900",
							open ? "rotate-90" : "rotate-0",
						)}
					>
						<CaretRight weight="bold" />
					</div>
				</CollapsibleTrigger>
				<CollapsibleContent className="mt-2 flex w-full min-w-0 flex-col gap-0.5">
					{[...libraryResults].map(([groupId, results]) => {
						const upload = appContext.getUploadById(groupId as UploadId);
						if (!upload) {
							return null;
						}
						return (
							<SearchResultGroup
								key={groupId}
								upload={upload}
								results={results as SearchLibraryResult[]}
							/>
						);
					})}
					{[...feedResults].map(([groupId, results]) => {
						const feedChannel = appContext.feedChannelsById?.get(
							groupId as FeedChannelId,
						);
						if (!feedChannel) {
							return null;
						}
						return (
							<SearchFeedItemsResultGroup
								key={groupId}
								feedChannel={feedChannel}
								results={results as SearchFeedItemsResult[]}
							/>
						);
					})}
					<div className="flex max-w-max items-center gap-0.5 text-base">
						<button
							type="button"
							className="rounded-lg p-1.5 text-neutral-500 hover:bg-neutral-100 hover:text-neutral-800"
							onClick={() => {
								const libraryText = [...libraryResults]
									.map(([groupId, results]) => {
										const uploadResults = results as SearchLibraryResult[];
										const upload = appContext.getUploadById(
											groupId as UploadId,
										);
										if (!upload) {
											return "";
										}

										return (
											`**${upload.file_name}${upload.upload_subtitle ? `, ${upload.upload_subtitle}` : ""}**\n\n` +
											`*${formatAuthors(upload.upload_authors ?? []).trim()}${upload.upload_year_published ? `, ${upload.upload_year_published}` : ""}*\n\n` +
											`${uploadResults
												.map(
													(result) =>
														`> "${result.chunk_text}" (${result.chunk_page_indices[0] + 1}${result.chunk_page_indices.length > 1 ? `-${result.chunk_page_indices[result.chunk_page_indices.length - 1] + 1}` : ""})`,
												)
												.join("\n\n")}`
										);
									})
									.join("\n\n");

								const feedsText = [...feedResults]
									.map(([groupId, results]) => {
										const feedResults = results as SearchFeedItemsResult[];
										const feedChannel = appContext.feedChannelsById?.get(
											groupId as FeedChannelId,
										);
										if (!feedChannel) {
											return "";
										}

										return (
											`**${feedChannel.file_name}${feedChannel.feed_channel_subtitle ? `, ${feedChannel.feed_channel_subtitle}` : ""}**\n\n` +
											`${feedResults
												.map(
													(result) =>
														`\`\`\`\`\n${result.feed_item_chunk_text}\n\`\`\`\``,
												)
												.join("\n\n")}`
										);
									})
									.join("\n\n");

								navigator.clipboard.writeText(
									`### Execute search: "${searchResultsAction.search_response.query}"\n${libraryText}\n\n${feedsText}`,
								);
								toast.success("Copied search results to clipboard");
							}}
						>
							<Copy weight="bold" />
						</button>
					</div>
				</CollapsibleContent>
			</Collapsible>
		);
	},
);
