import { FileArrowUp } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useDropzone } from "react-dropzone";

export const FileUploaderBox: React.FC<{
	onDrop: (files?: File[]) => void;
	description: string;
}> = observer((props) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (acceptedFiles) => {
			if (acceptedFiles.length > 0) {
				props.onDrop(acceptedFiles);
			}
		},
	});
	return (
		<div
			className={`flex w-full cursor-pointer select-none flex-col items-center justify-center rounded-lg border py-12 text-neutral-700 ${
				isDragActive
					? "border bg-neutral-100 outline outline-neutral-200"
					: "border-neutral-100 bg-neutral-100 hover:border-neutral-200 hover:outline hover:outline-neutral-200"
			}`}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			<FileArrowUp className="mr-1 text-4xl" />
			<h1 className="mt-2">
				<span className="font-semibold text-text-1">Drag and drop</span> or{" "}
				<span className="font-semibold text-text-1">click</span> to upload
			</h1>
			<h2 className="mt-2 text-sm">{props.description}</h2>
		</div>
	);
});
