import type { AppState } from "@/contexts/AppContext";
import { createSyncedAction } from "@/contexts/SyncedActions";
import {
	type FeedChannelId,
	type FeedItemId,
	newFeedChannelId,
	newFeedItemId,
} from "@/idGenerators";
import { deleteFeedChannels, indexRssFeed } from "@api/fastAPI";
import type {
	FeedChannel,
	FeedItemMetadata,
	ParsedFeedChannel,
} from "@api/schemas";
import { toast } from "sonner";

export const addFeedChannelAction = createSyncedAction<
	AppState,
	{ feedChannelPreview: ParsedFeedChannel },
	{ channel: FeedChannel; items: FeedItemMetadata[] },
	void
>({
	async local({ feedChannelPreview }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		const feedChannelId = newFeedChannelId();
		const now = new Date().toISOString();

		// Create channel
		const channel: FeedChannel = {
			feed_channel_id: feedChannelId,
			file_name: feedChannelPreview.feed_channel_title,
			feed_channel_subtitle: feedChannelPreview.feed_channel_subtitle,
			feed_channel_description: feedChannelPreview.feed_channel_description,
			feed_channel_link: feedChannelPreview.feed_channel_link,
			feed_channel_rss_endpoint: feedChannelPreview.feed_rss_link,
			feed_channel_image_url: feedChannelPreview.feed_channel_image_url,
			feed_channel_image_link: feedChannelPreview.feed_channel_image_link,
			feed_channel_image_title: feedChannelPreview.feed_channel_image_title,
			feed_channel_language: feedChannelPreview.feed_channel_language,
			feed_channel_last_build_date: null,
			feed_channel_pub_date: feedChannelPreview.feed_channel_pub_date,
			feed_channel_refetching: true,
			file_created_at: now,
			file_updated_at: now,
			file_deleted: false,
			file_creator_id: this.workspace.userId,
			file_type: "feed_channel",
		};

		// Create items
		const items: FeedItemMetadata[] = feedChannelPreview.items.map((item) => {
			const feedItemId = newFeedItemId();
			return {
				feed_item_id: feedItemId,
				feed_channel_id: feedChannelId,
				feed_item_description: item.feed_item_description,
				feed_item_link: item.feed_item_link,
				feed_item_published_at: item.feed_item_pub_date,
				feed_item_created_at: now,
				feed_item_updated_at: now,
				feed_item_author: item.feed_item_author,
				feed_item_guid: item.feed_item_guid,
				feed_item_og_image: null,
				feed_item_pub_date: item.feed_item_pub_date,
				feed_item_wordcount: null,
				feed_item_date_created: now,
				feed_item_status: "pending",
				file_id: feedItemId,
				file_creator_id: this.workspace?.userId ?? null,
				file_created_at: now,
				file_updated_at: now,
				file_deleted: false,
				file_name: item.feed_item_title ?? "Untitled article",
				file_type: "feed_item",
			};
		});

		// Add channel and items to workspace
		this.workspace.feedChannels.set(feedChannelId as FeedChannelId, channel);
		for (const item of items) {
			this.workspace.feedItems.set(item.feed_item_id as FeedItemId, item);
			this.feedItemsFlexsearchIndex.add(item);
		}

		this.navigate(`/feed/${feedChannelId}`);

		return { channel, items };
	},
	async remote(_, { channel, items }) {
		indexRssFeed({
			feed_channel: channel,
			feed_items_metadata: items,
		});
	},
	rollback(_, { channel, items }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		// Remove channel and items from workspace
		this.workspace.feedChannels.delete(
			channel.feed_channel_id as FeedChannelId,
		);
		for (const item of items) {
			this.workspace.feedItems.delete(item.feed_item_id as FeedItemId);
		}
	},
});

export const deleteFeedChannelsAction = createSyncedAction<
	AppState,
	{ feedChannelIds: FeedChannelId[] },
	void,
	void
>({
	async local({ feedChannelIds }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		for (const id of feedChannelIds) {
			const channel = this.workspace.feedChannels.get(id);
			if (!channel) {
				throw new Error("Channel not found");
			}
			channel.file_deleted = true;
		}
	},
	async remote({ feedChannelIds }) {
		await deleteFeedChannels({ feed_channel_ids: feedChannelIds });
	},
	rollback({ feedChannelIds }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		for (const id of feedChannelIds) {
			const channel = this.workspace.feedChannels.get(id);
			if (!channel) {
				throw new Error("Channel not found");
			}
			channel.file_deleted = false;
		}
	},
	onRemoteSuccess({ feedChannelIds }) {
		toast.success(
			`Deleted ${feedChannelIds.length} feed channel${feedChannelIds.length > 1 ? "s" : ""}`,
		);
	},
});

export function feedItemsById(this: AppState) {
	if (!this.workspace) {
		return null;
	}
	return this.workspace.feedItems;
}

export function feedChannelsById(this: AppState) {
	if (!this.workspace) {
		return null;
	}
	return this.workspace.feedChannels;
}

export function sortedFeedChannels(this: AppState) {
	if (!this.workspace) {
		return null;
	}
	return Array.from(this.workspace.feedChannels.values())
		.filter((x) => !x.file_deleted)
		.sort((a, b) => {
			return a.file_name.localeCompare(b.file_name);
		});
}

export function sortedFeedItemsByChannel(this: AppState) {
	if (!this.workspace) {
		return null;
	}
	const feedItemsByChannel = new Map<FeedChannelId, FeedItemMetadata[]>();

	for (const feedChannel of this.workspace.feedChannels.values()) {
		feedItemsByChannel.set(feedChannel.feed_channel_id as FeedChannelId, []);
	}

	for (const feedItem of this.workspace.feedItems.values()) {
		const channel = feedItem.feed_channel_id as FeedChannelId;
		const items = feedItemsByChannel.get(channel) || [];
		items.push(feedItem);
		feedItemsByChannel.set(channel, items);
	}

	// sort items by ID (ULIDs are date-sortable)
	for (const items of feedItemsByChannel.values()) {
		items.sort((a, b) => {
			const aDateStr = a.feed_item_pub_date ?? a.file_created_at;
			const bDateStr = b.feed_item_pub_date ?? b.file_created_at;

			const aDate = new Date(aDateStr);
			const bDate = new Date(bDateStr);

			return aDate > bDate ? -1 : 1;
		});
	}

	return feedItemsByChannel;
}

export function searchFeedItemsByMetadata(this: AppState, query: string) {
	const searchResults = this.feedItemsFlexsearchIndex.search(query);

	return new Set(
		searchResults.flatMap((result) => result.result as FeedItemId[]),
	);
}
