import { ShowSidebarButton } from "@/components/ShowSidebarButton";
import { Button } from "@/components/ui/button";
import { useAppContext } from "@/contexts/AppContext";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

export const Tables = observer(() => {
	const appContext = useAppContext();
	return (
		<div className="relative flex h-full min-h-0 w-full flex-col">
			<Helmet>
				<title>Tables - Village</title>
			</Helmet>
			{/* Header */}
			<div className="flex h-14 w-full items-center gap-2 border-b px-2">
				<div className="flex h-full items-center">
					{!appContext.showSidebar && <ShowSidebarButton />}
				</div>
				<span className="font-semibold text-lg">Tables</span>
			</div>
			{/* Body */}
			<div className="flex flex-col gap-2 p-2">
				<Button
					className="w-fit"
					onClick={() =>
						appContext.createTable({
							tableName: "New Table",
							tablePrimaryColumnName: "Primary",
						})
					}
				>
					Create Table
				</Button>
				<div>
					{appContext.workspace?.tables === undefined ||
					appContext.workspace.tables.size === 0
						? "No tables found"
						: Array.from(appContext.workspace.tables.values()).map((table) => (
								<div key={table.table_id}>{table.file_name}</div>
							))}
				</div>
			</div>
		</div>
	);
});
