import { IS_DEV } from "@/config";
import type { PDFViewerState } from "@/contexts/PDFViewerContext";
import type { FeedChannelId, UploadId } from "@/idGenerators";
import { searchHandler } from "@api/fastAPI";
import {
	type SearchFeedItemsResultOutput as SearchFeedItemsResult,
	type SearchLibraryResultOutput as SearchLibraryResult,
	SearchMode,
	type SearchResponseOutput as SearchResponse,
	type Upload,
} from "@api/schemas";
import { Circuitry, Key } from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createContext, useContext, useEffect, useRef } from "react";
import { type SetURLSearchParams, useSearchParams } from "react-router-dom";
import { toast } from "sonner";

export const SearchModeMeta = {
	[SearchMode.keyword]: {
		title: "Keyword",
		description: "Matches exact quotes and phrases",
		icon: <Key className=" text-neutral-500" weight="duotone" />,
	},
	[SearchMode.semantic]: {
		title: "Semantic",
		description: "Finds content with similar meaning",
		icon: <Circuitry className="text-neutral-500" weight="duotone" />,
	},
	[SearchMode.hybrid]: {
		title: "Neural",
		description: "Finds content based on meaning",
		icon: <Circuitry className="text-neutral-500" weight="duotone" />,
	},
};

interface SearchConfig {
	query: string;
	search_mode: SearchMode;

	// these are all optional for schema compatibility
	// in case users have old search configs without these fields
	include_library?: boolean;
	included_upload_ids?: UploadId[];
	include_feeds?: boolean;
	included_feed_channel_ids?: FeedChannelId[];
	result_id?: string;
}

function searchConfigKey(config: SearchConfig) {
	return JSON.stringify({
		query: config.query,
		search_mode: config.search_mode,
		include_library: config.include_library,
		included_upload_ids: (config.included_upload_ids ?? []).slice().sort(),
		include_feeds: config.include_feeds,
		included_feed_channel_ids: (config.included_feed_channel_ids ?? [])
			.slice()
			.sort(),
	});
}

export interface SearchLibraryResultWithUpload extends SearchLibraryResult {
	upload: Upload;
}
export class SearchState {
	query = "";
	searchMode: SearchMode = SearchMode.hybrid;
	includeLibrary = true;
	includedUploadIds: Set<UploadId> = new Set();
	includeFeeds = true;
	includedFeedChannelIds: Set<FeedChannelId> = new Set();
	groupResultsByUpload = false;

	searchInputElement: HTMLInputElement | null = null;

	searchResults: SearchResponse | null = null;
	searchLoading = false;

	activeSearchResult:
		| SearchLibraryResultWithUpload
		| SearchFeedItemsResult
		| null = null;

	viewerState: PDFViewerState | null = null;

	searchHistory: SearchConfig[] = [];

	showCommandList = false;

	setShowCommandList(show: boolean) {
		this.showCommandList = show;
	}

	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: "SearchState",
			properties: ["searchHistory"],
			storage: window.localStorage,
		});
	}

	get uniqueSearchHistory() {
		const seen = new Set<string>();
		return this.searchHistory
			.filter((config) => {
				// If we haven't seen this configuration before, add it to the set and keep it
				if (!seen.has(searchConfigKey(config))) {
					seen.add(searchConfigKey(config));
					return true;
				}

				// If we've seen this configuration before, filter it out
				return false;
			})
			.reverse(); // Reverse to show most recent searches first
	}

	updateSearchParams(setSearchParams: SetURLSearchParams) {
		setSearchParams((params) => {
			params.set("q", this.query);
			params.set("mode", this.searchMode);

			this.includeLibrary &&
				params.set("include_library", this.includeLibrary.toString());
			this.includedUploadIds &&
				params.set("upload_ids", [...this.includedUploadIds].join(","));

			this.groupResultsByUpload &&
				params.set(
					"group_results_by_upload",
					this.groupResultsByUpload.toString(),
				);

			this.includeFeeds &&
				params.set("include_feeds", this.includeFeeds.toString());
			this.includedFeedChannelIds &&
				params.set("channel_ids", [...this.includedFeedChannelIds].join(","));
			return params;
		});
	}

	handleSearch(setSearchParams: SetURLSearchParams, eagerRedirect: boolean) {
		if (this.query === "") {
			toast.error("Please enter a search query");
			return;
		}
		if (this.searchLoading) {
			toast.error("Please wait for the current search to finish");
			return;
		}
		if (!this.includeLibrary && !this.includeFeeds) {
			toast.error("Please select at least one document type");
			return;
		}
		if (eagerRedirect) {
			this.updateSearchParams(setSearchParams);
		}
		this.searchInputElement?.blur();
		this.searchLoading = true;
		searchHandler({
			query: this.query,
			search_mode: this.searchMode,
			include_library: this.includeLibrary,
			included_upload_ids: [...this.includedUploadIds],
			include_feeds: this.includeFeeds,
			included_feed_channel_ids: [...this.includedFeedChannelIds],
		})
			.then((res) => {
				runInAction(() => {
					this.searchResults = res.data;
					this.searchLoading = false;
					if (!eagerRedirect) {
						this.updateSearchParams(setSearchParams);
					}
					this.searchHistory.push({
						query: this.query,
						search_mode: this.searchMode,
						include_library: this.includeLibrary,
						included_upload_ids: [...this.includedUploadIds],
						include_feeds: this.includeFeeds,
						included_feed_channel_ids: [...this.includedFeedChannelIds],
						result_id: res.data.result_id,
					});
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
				if (IS_DEV) {
					toast.error(`${err}`);
				} else {
					toast.error("Failed to fetch search results");
				}
			});
	}

	// This is a bit messy and it would be better to store unique search items only
	// But I was unsure about result_id's
	removeSearchHistoryItem(config: SearchConfig) {
		const seen = new Set<string>();
		seen.add(searchConfigKey(config));

		runInAction(() => {
			this.searchHistory = this.searchHistory.filter((config) => {
				if (seen.has(searchConfigKey(config))) {
					return false;
				}

				return true;
			});
		});
	}

	clearSearchHistory() {
		runInAction(() => {
			this.searchHistory = [];
		});
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const SearchContext = createContext<SearchState>(null as any);

export const useSearchContext = () => {
	const context = useContext(SearchContext);
	if (!context) {
		throw new Error(
			"useSearchContext must be used within an SearchContextProvider",
		);
	}
	return context;
};

export const SearchProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const searchState = useRef(new SearchState());
	const [searchParams, setSearchParams] = useSearchParams();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(
		function init() {
			// Prevents double search with strictmode
			if (searchState.current.searchLoading) {
				return;
			}
			// read search string from URL
			const query = searchParams.get("q");
			const mode = searchParams.get("mode");
			if (mode) {
				searchState.current.searchMode = mode as SearchMode;
			}

			const includeLibrary = searchParams.get("include_library");
			if (includeLibrary) {
				searchState.current.includeLibrary = includeLibrary === "true";
			}

			const uploadIds = searchParams.get("upload_ids");
			if (uploadIds) {
				searchState.current.includedUploadIds = new Set(
					uploadIds.split(",") as UploadId[],
				);
			}

			const includeFeeds = searchParams.get("include_feeds");
			if (includeFeeds) {
				searchState.current.includeFeeds = includeFeeds === "true";
			}

			const channelIds = searchParams.get("channel_ids");
			if (channelIds) {
				searchState.current.includedFeedChannelIds = new Set(
					channelIds.split(",") as FeedChannelId[],
				);
			}

			const groupResultsByUpload = searchParams.get("group_results_by_upload");
			if (groupResultsByUpload) {
				searchState.current.groupResultsByUpload =
					groupResultsByUpload === "true";
			}

			if (query) {
				searchState.current.query = query;
				searchState.current.handleSearch(setSearchParams, false);
			}
		},
		[
			searchParams.get("q"),
			searchParams.get("mode"),
			searchParams.get("upload_ids"),
		],
	);

	return (
		<SearchContext.Provider value={searchState.current}>
			{children}
		</SearchContext.Provider>
	);
};
