import { UploadCoverImage } from "@/components/UploadCoverImage";
import { useAppContext } from "@/contexts/AppContext";
import type { UploadId } from "@/idGenerators";
import { formatAuthors, formatTitle } from "@/lib/utils";
import type { UserMessageActionOutput as UserMessageAction } from "@api/schemas";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

export const UserMessage = observer(
	({ userMessage }: { userMessage: UserMessageAction }) => {
		const appContext = useAppContext();

		return (
			<div className="flex w-full min-w-0 max-w-full flex-col items-end gap-2">
				{userMessage.focus_upload_ids && (
					<div className="flex max-w-full flex-col items-end gap-1">
						{userMessage.focus_upload_ids.map((uploadId) => {
							const upload = appContext.getUploadById(uploadId as UploadId);
							if (!upload) {
								return null;
							}
							return (
								<div
									key={uploadId}
									className="flex min-w-0 max-w-full items-center gap-1 rounded-lg border bg-white px-2 py-1.5"
								>
									<div>
										<UploadCoverImage
											size={128}
											upload_id={upload.upload_id as UploadId}
											upload_status={upload.upload_status}
											className={(uploadStatus) =>
												clsx(
													"h-8 max-w-6 rounded-xs",
													uploadStatus === "ready" && "shadow",
												)
											}
										/>
									</div>

									<div className="min-w-0 truncate">
										<h2 className="min-w-0 truncate text-sm leading-4">
											{formatTitle({
												title: upload.upload_title,
												subtitle: upload.upload_subtitle,
												filename: upload.file_name,
											})}
										</h2>
										<h3 className="text-neutral-500 text-sm leading-4">
											{formatAuthors(upload.upload_authors ?? [])}
											{upload.upload_year_published &&
												`, ${upload.upload_year_published}`}
										</h3>
									</div>
								</div>
							);
						})}
					</div>
				)}
				<p className="max-w-max rounded-3xl bg-neutral-100 px-4 py-2 text-sm">
					{userMessage.content}
				</p>
			</div>
		);
	},
);
