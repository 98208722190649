import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const formatAuthors = (authors: string[]) => {
	let authorsString = "Unknown author";

	if (authors.length > 0) {
		authorsString = `${authors[0]} + ${authors.length - 1}`;

		if (authors.length === 1) {
			authorsString = authors[0];
		} else if (authors.length === 2) {
			authorsString = `${authors[0]} and ${authors[1]}`;
		} else if (authors.length > 2) {
			authorsString = `${authors[0]} + ${authors.length - 1}`;
		}
	}

	return authorsString;
};

export const formatTitle = ({
	title,
	subtitle,
	filename,
}: {
	title: string | null;
	subtitle: string | null;
	filename: string;
}) => {
	if (title) {
		if (subtitle) {
			return `${title}: ${subtitle}`;
		}
		return title;
	}
	return filename;
};

export const formatPages = (page_indices: Array<number>) =>
	page_indices.length > 1
		? `${page_indices[0] + 1}-${page_indices[page_indices.length - 1] + 1}`
		: `${page_indices[0] + 1}`;

export const formatDate = (date: string) => {
	const dateObj = new Date(date);
	return dateObj.toLocaleDateString();
};
