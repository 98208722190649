import { type ImgHTMLAttributes, useEffect, useState } from "react";

interface ImageWithFallbackProps extends ImgHTMLAttributes<HTMLImageElement> {
	src: string;
	fallbackSrc: string;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
	src,
	fallbackSrc,
	alt,
	...props
}) => {
	const [imgSrc, setImgSrc] = useState(src);

	useEffect(() => {
		setImgSrc(src);
	}, [src]);

	return (
		<img
			{...props}
			src={imgSrc}
			alt={alt}
			onError={() => setImgSrc(fallbackSrc)}
		/>
	);
};
