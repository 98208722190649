import { useSearchContext } from "@/contexts/SearchContext";
import type { SearchFeedItemsResultOutput as SearchFeedItemsResult } from "@api/schemas";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import Markdown from "react-markdown";

export const ResultWithinFeedItemsGroup = observer(
	({
		result,
	}: {
		result: SearchFeedItemsResult;
	}) => {
		const searchContext = useSearchContext();

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const onSelect = useCallback(() => {
			runInAction(() => {
				searchContext.activeSearchResult = {
					...result,
				};
			});
		}, [result]);

		return (
			<div
				key={result.feed_item_chunk_id}
				className={clsx(
					"group flex w-full cursor-pointer items-start justify-center overflow-hidden rounded-md border border-neutral-200",

					searchContext.activeSearchResult?.type === "feed_items" &&
						searchContext.activeSearchResult?.feed_item_chunk_id ===
							result.feed_item_chunk_id
						? "border-blue-300 bg-blue-50 ring-2 ring-blue-100"
						: "hover:border-blue-200 hover:bg-blue-50",
				)}
				onClick={onSelect}
				onKeyDown={onSelect}
			>
				<Markdown className="prose prose-neutral prose-sm h-28 w-[175%] min-w-0 shrink-0 scale-50 opacity-70">
					{result.feed_item_chunk_text}
				</Markdown>
			</div>
		);
	},
);
