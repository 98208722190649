import { Skeleton } from "@/components/ui/skeleton";
import { Switch } from "@/components/ui/switch";
import { useAppContext } from "@/contexts/AppContext";
import { useSearchContext } from "@/contexts/SearchContext";
import type { FeedChannelId, FeedItemId, UploadId } from "@/idGenerators";
import { SearchComboboxCommandList } from "@/pages/Search/SearchComboboxCommandList";
import { SearchFeedItemsResultComponent } from "@/pages/Search/SearchFeedItemsResultComponent";
import { SearchFeedItemsResultGroup } from "@/pages/Search/SearchFeedItemsResultGroup";
import { SearchLibraryResultComponent } from "@/pages/Search/SearchLibraryResultComponent";
import { SearchLibraryResultGroup } from "@/pages/Search/SearchLibraryResultGroup";
import type {
	SearchFeedItemsResultOutput as SearchFeedItemsResult,
	SearchLibraryResultOutput as SearchLibraryResult,
	SearchResponseOutput as SearchResponse,
} from "@api/schemas";
import * as Sentry from "@sentry/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import {
	type MutableRefObject,
	createRef,
	useEffect,
	useId,
	useMemo,
	useRef,
	useState,
} from "react";
import { useSearchParams } from "react-router-dom";

export const SEARCH_RESULTS_CONTAINER_ID = "search_results_container";

interface SearchResultsProps {
	searchResults: SearchResponse;
}

const SearchResults = ({ searchResults }: SearchResultsProps) => {
	const appContext = useAppContext();
	const [_, setSearchParams] = useSearchParams();
	const searchContext = useSearchContext();
	const { groupResultsByUpload } = searchContext;
	const containerId = useId();
	const [focusedIndex, setFocusedIndex] = useState(0);
	const [searchResultsContainerIsFocused, setSearchResultsContainerIsFocused] =
		useState(false);

	console.log(searchResults.feed_items_results);

	const mergedResults = useMemo(() => {
		const merged = [
			...searchResults.library_results,
			...(searchResults.feed_items_results ?? []),
		];
		merged.sort((a, b) => (b.score ?? 0) - (a.score ?? 0));
		return merged;
	}, [searchResults.library_results, searchResults.feed_items_results]);

	const resultRefs = useRef<MutableRefObject<HTMLDivElement | null>[]>(
		mergedResults.map(() => createRef<HTMLDivElement>()),
	);

	const handleKeyDown = (e: KeyboardEvent) => {
		if (!appContext.workspace) {
			return;
		}
		if (searchContext.showCommandList) {
			return;
		}
		switch (e.key) {
			case "ArrowDown":
				setFocusedIndex((prevIndex) => {
					const newFocusedIndex = (prevIndex + 1) % mergedResults.length;
					resultRefs.current[newFocusedIndex].current?.scrollIntoView({
						behavior: "instant",
						block: "nearest",
					});

					return newFocusedIndex;
				});
				break;
			case "ArrowUp":
				setFocusedIndex((prevIndex) => {
					const newFocusedIndex =
						(prevIndex - 1 + mergedResults.length) % mergedResults.length;
					resultRefs.current[newFocusedIndex].current?.scrollIntoView({
						behavior: "instant",
						block: "nearest",
					});
					return newFocusedIndex;
				});
				break;
			case "Enter":
				setFocusedIndex((prevIndex) => {
					runInAction(() => {
						const result = mergedResults[prevIndex];

						if (result.type === "feed_items") {
						} else if (result.type === "library") {
							// biome-ignore lint/style/noNonNullAssertion:
							const upload = appContext.workspace!.uploads.get(
								result.upload_id as UploadId,
							);

							if (!upload) {
								Sentry.captureMessage(
									"Upload from search result not found in appContext",
									"error",
								);
								return;
							}
							searchContext.activeSearchResult = {
								...result,
								upload: upload,
							};
						} else {
							//
						}
					});
					return prevIndex;
				});

				break;
			default:
				break;
		}
	};

	// When there are new search results, we want to focus on the results container
	// That way the user can immediately start navigating the results with the keyboard
	// As a side effect, this also solves the problem of the search input being focused
	// when a search is run, although this is not the ideal solution
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const container = document.getElementById(SEARCH_RESULTS_CONTAINER_ID);

		const handleFocus = () => {
			setSearchResultsContainerIsFocused(true);
		};

		const handleBlur = () => {
			setSearchResultsContainerIsFocused(false);
		};

		if (container) {
			container.addEventListener("focus", handleFocus);
			container.addEventListener("blur", handleBlur);
			container.addEventListener("keydown", handleKeyDown);

			container.focus();

			return () => {
				container.removeEventListener("focus", handleFocus);
				container.removeEventListener("blur", handleBlur);
				container?.removeEventListener("keydown", handleKeyDown);
			};
		}
	}, [mergedResults]);

	if (mergedResults.length === 0) {
		return (
			<div className="flex h-full w-full items-center justify-center">
				<h1 className="text-neutral-500">No results found</h1>
			</div>
		);
	}

	const resultsHeader = (
		<div className="flex items-center justify-between border-b bg-neutral-50 px-2 py-1.5 text-neutral-500 text-sm">
			<div>
				{mergedResults.length} result
				{mergedResults.length > 1 ? "s" : ""}
			</div>
			<div className="flex items-center gap-2 text-sm">
				Group by upload
				<Switch
					checked={searchContext.groupResultsByUpload}
					onCheckedChange={(checked) => {
						runInAction(() => {
							searchContext.groupResultsByUpload = checked;
							setSearchParams((params) => {
								params.set(
									"group_results_by_upload",
									searchContext.groupResultsByUpload.toString(),
								);
								return params;
							});
						});
					}}
				/>
			</div>
		</div>
	);

	if (groupResultsByUpload) {
		const groupedResults = mergedResults.reduce((acc, result) => {
			if (result.type === "feed_items") {
				const feedChannelResults = acc.get(result.feed_channel_id);

				if (!feedChannelResults) {
					acc.set(result.feed_channel_id, [result]);
				} else {
					acc.set(result.feed_channel_id, [...feedChannelResults, result]);
				}
			} else if (result.type === "library") {
				const uploadResults = acc.get(result.upload_id);

				if (!uploadResults) {
					acc.set(result.upload_id, [result]);
				} else {
					acc.set(result.upload_id, [...uploadResults, result]);
				}
			}
			return acc;
		}, new Map<string, (SearchLibraryResult | SearchFeedItemsResult)[]>());

		return (
			<>
				{resultsHeader}
				<div
					// the key forces a rerender and a scroll reset when we switch between grouped and ungrouped results
					// or when the results change as indicated by result_id
					key={`grouped-${searchResults.result_id}`}
					className="relative flex grow flex-col overflow-y-auto"
					id={containerId}
				>
					{[...groupedResults].map(([uploadId, results]) => {
						// Kind of funky
						if (results[0].type === "feed_items") {
							if (!appContext.workspace?.feedChannels) {
								return null;
							}
							const feedChannel = appContext.workspace?.feedChannels.get(
								uploadId as FeedChannelId,
							);
							if (!feedChannel) {
								Sentry.captureMessage(
									"Feed channel from search result not found in appContext",
									"error",
								);
								return null;
							}
							return (
								<SearchFeedItemsResultGroup
									key={uploadId}
									feedChannel={feedChannel}
									results={results as SearchFeedItemsResult[]}
									containerId={containerId}
								/>
							);
						}
						if (results[0].type === "library") {
							const upload = appContext.workspace?.uploads.get(
								uploadId as UploadId,
							);
							if (!upload) {
								Sentry.captureMessage(
									"Upload from search result not found in appContext",
									"error",
								);
								return null;
							}
							return (
								<SearchLibraryResultGroup
									key={uploadId}
									upload={upload}
									results={results as SearchLibraryResult[]}
									containerId={containerId}
								/>
							);
						}
						return null;
					})}
				</div>
			</>
		);
	}

	return (
		<>
			{resultsHeader}
			<div
				// the key forces a rerender and a scroll reset when we switch between grouped and ungrouped results
				// or when the results change as indicated by result_id
				key={`ungrouped-${searchResults.result_id}`}
				className="flex grow flex-col divide-y overflow-y-auto"
			>
				{mergedResults.map((result, i) => {
					if (result.type === "feed_items") {
						if (!appContext.workspace?.feedItems) {
							return null;
						}
						const feedItem = appContext.workspace?.feedItems.get(
							result.feed_item_id as FeedItemId,
						);
						if (!feedItem) {
							Sentry.captureMessage(
								"Feed item from search result not found in appContext",
								"error",
							);
							return null;
						}
						const feedChannel = appContext.workspace?.feedChannels.get(
							result.feed_channel_id as FeedChannelId,
						);
						if (!feedChannel) {
							Sentry.captureMessage(
								"Feed channel from search result not found in appContext",
								"error",
							);
							return null;
						}
						return (
							<SearchFeedItemsResultComponent
								key={result.feed_item_chunk_id}
								result={result}
								feedItem={feedItem}
								feedChannel={feedChannel}
								focused={i === focusedIndex}
								ref={resultRefs.current[i]}
								searchResultsContainerIsFocused={
									searchResultsContainerIsFocused
								}
								selectCallback={() => {
									setFocusedIndex(i);
								}}
							/>
						);
					}

					if (result.type === "library") {
						const upload = appContext.workspace?.uploads.get(
							result.upload_id as UploadId,
						);
						if (!upload) {
							Sentry.captureMessage(
								"Upload from search result not found in appContext",
								"error",
							);
							return null;
						}
						return (
							<SearchLibraryResultComponent
								key={result.chunk_id}
								result={result}
								upload={upload}
								focused={i === focusedIndex}
								ref={resultRefs.current[i]}
								searchResultsContainerIsFocused={
									searchResultsContainerIsFocused
								}
								selectCallback={() => {
									setFocusedIndex(i);
								}}
							/>
						);
					}

					return null;
				})}
			</div>
		</>
	);
};

export const SearchBody = observer(() => {
	const searchContext = useSearchContext();
	const { searchLoading, searchResults } = searchContext;

	if (searchResults) {
		return <SearchResults searchResults={searchResults} />;
	}

	if (searchLoading) {
		return (
			<div className="relative flex grow flex-col overflow-y-auto">
				{[...Array(6)].map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="flex w-full flex-col space-y-2 border-b p-4"
					>
						<div className="flex space-x-2 ">
							<Skeleton className="h-8 w-6" />
							<div className="flex min-w-0 grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full max-w-48" />
							</div>
						</div>
						<div className="flex w-full flex-col rounded-md border">
							<div className="flex grow flex-col space-y-2 p-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
							</div>
							<div className="h-4 w-full rounded-b-md border-t bg-neutral-50" />
						</div>
					</div>
				))}
			</div>
		);
	}

	return <SearchComboboxCommandList />;
});
