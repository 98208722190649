import { MetadataEditDialogContent } from "@/components/MetadataEditDialog";
import { ShowSidebarButton } from "@/components/ShowSidebarButton";
import { UploadCoverImage } from "@/components/UploadCoverImage";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuSeparator,
	ContextMenuTrigger,
} from "@/components/ui/context-menu";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/AppContext";
import type {
	AnyDirectoryNode,
	FolderDirectoryNode,
	UploadDirectoryNode,
} from "@/contexts/AppContext/TreeHandlers";
import { useLibraryContext } from "@/contexts/LibraryContext";
import type { FolderId, UploadId } from "@/idGenerators";
import { formatTitle } from "@/lib/utils";
import { FileUploadDialogContent } from "@/pages/Library/FileUploadDialogContent";
import type { Folder, Upload } from "@api/schemas";
import {
	ArrowLineRight,
	ArrowUpRight,
	DownloadSimple,
	Folder as FolderIcon,
	FolderOpen,
	FolderPlus,
	FolderSimple,
	House,
	PencilSimple,
	Trash,
	UploadSimple,
	Warning,
} from "@phosphor-icons/react";
import clsx from "clsx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { runInAction, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
	type NodeApi,
	type NodeRendererProps,
	Tree,
	type TreeApi,
} from "react-arborist";
import {
	NavLink,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import useResizeObserver from "use-resize-observer";

dayjs.extend(relativeTime);

export const FolderNode = observer(
	({
		node,
		preview,
	}: { node: NodeApi<FolderDirectoryNode>; preview?: boolean }) => {
		const navigate = useNavigate();

		const folder = node.data.item.data;

		return (
			<div
				className={clsx(
					"group flex h-full w-full items-center rounded-md px-2 py-1",
				)}
				key={folder.folder_id}
				onDoubleClick={() => {
					node.tree.deselectAll();
					navigate(`/library/${folder.folder_id}`);
				}}
			>
				<div className="flex grow items-center">
					<button
						type="button"
						onClick={() => {
							node.toggle();
						}}
						className="flex w-4 items-center justify-center"
					>
						{node.isOpen ? (
							<FolderOpen
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						) : (
							<FolderSimple
								weight="duotone"
								className="text-lg text-neutral-500"
							/>
						)}
					</button>
					<h2
						className={clsx(
							"ml-1 max-h-max min-w-0 truncate px-1 py-0.5 text-neutral-800 text-sm leading-4",
							preview && "bg-blue-700 text-white",
						)}
					>
						{folder.file_name}
					</h2>
				</div>
			</div>
		);
	},
);

export const UploadNode = observer(
	({
		node,
		preview,
	}: { node: NodeApi<UploadDirectoryNode>; preview?: boolean }) => {
		const [_, setSearchParams] = useSearchParams();

		const upload = node.data.item.data;

		return (
			<div
				className={clsx(
					"group flex h-full w-full min-w-0 items-center gap-1 truncate px-2 py-1",
				)}
				key={upload.upload_id}
				onDoubleClick={() => {
					setSearchParams((prev) => {
						prev.set("upload_id", upload.upload_id as UploadId);
						return prev;
					});
				}}
			>
				<div className="flex w-4 shrink-0 justify-center">
					<UploadCoverImage
						className={(uploadStatus) =>
							clsx(
								"h-4 max-w-6 rounded-xs",
								uploadStatus === "ready" && "shadow-lg",
							)
						}
						upload_id={upload.upload_id as UploadId}
						upload_status={upload.upload_status}
						size={128}
					/>
				</div>

				<div className="min-w-0 truncate">
					<h2 className="flex min-w-0 items-center gap-1 truncate text-neutral-800 text-sm leading-4">
						<span
							className={clsx(
								"min-w-0 truncate rounded-md px-1 py-0.5",
								preview && "bg-blue-700 text-white",
							)}
						>
							{formatTitle({
								title: upload.upload_title,
								subtitle: upload.upload_subtitle,
								filename: upload.file_name,
							})}
						</span>
						{!upload.file_name && (
							<Tooltip>
								<TooltipTrigger>
									<Warning weight="fill" className="text-amber-500 text-base" />
								</TooltipTrigger>
								<TooltipContent>
									This upload is missing a title. Edit the metadata to add one.
								</TooltipContent>
							</Tooltip>
						)}
					</h2>
				</div>
			</div>
		);
	},
);

const formatCreationDate = (date: string) => {
	const parsedDate = dayjs(date);
	const today = dayjs();

	if (parsedDate.isAfter(today.subtract(1, "day"))) {
		return parsedDate.fromNow(); // e.g., "2 hours ago", "5 minutes ago"
	}
	if (parsedDate.isAfter(today.subtract(1, "week"))) {
		return parsedDate.format("dddd [at] h:mm A"); // e.g., "Tuesday at 2:30 PM"
	}
	if (parsedDate.isAfter(today.subtract(1, "month"))) {
		return parsedDate.format("MMM D [at] h:mm A"); // e.g., "Sep 5 at 2:30 PM"
	}

	if (parsedDate.year() === today.year()) {
		return parsedDate.format("MMM D [at] h:mm A"); // e.g., "Mar 15 at 2:30 PM"
	}
	return parsedDate.format("MMM D, YYYY [at] h:mm A"); // e.g., "Sep 12, 2023 at 2:30 PM"
};

export const LibraryTreeNode = observer(
	({
		node,
		style,
		dragHandle,
		preview,
	}: NodeRendererProps<AnyDirectoryNode>) => {
		const libraryContext = useLibraryContext();
		const isRightClicked = libraryContext.rightClickedNode?.id === node.id;

		return (
			<div
				style={style}
				ref={dragHandle}
				className={clsx(
					"mx-2 flex h-8 items-center rounded-md border",
					(libraryContext.rightClickedNodeIsSelected && node.isSelected) ||
						isRightClicked
						? "border-blue-300"
						: "border-transparent",
					node.isSelected && !preview && "bg-blue-100",
					node.willReceiveDrop && !preview && "bg-blue-50",
				)}
				onContextMenu={() => {
					libraryContext.setRightClickedNode(node);
				}}
				onClick={() => {
					libraryContext.setRightClickedNode(null);
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						node.toggle();
					}
				}}
			>
				<div className="flex w-2/3 shrink-0 items-center">
					{(new Array(node.level).fill(0) as number[]).map((_, i) => (
						<div
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							key={i}
							className="mr-2 ml-0.5 h-8 w-4 shrink-0 border-neutral-200 border-r-2"
						/>
					))}
					{node.data.item.type === "folder" ? (
						<FolderNode node={node} preview={preview} />
					) : (
						<UploadNode node={node} preview={preview} />
					)}
				</div>
				<div className="w-1/3 shrink-0 truncate text-neutral-500 text-sm">
					{node.data.item.type === "upload" &&
						formatCreationDate(node.data.item.data.file_created_at)}
					{node.data.item.type === "folder" &&
						formatCreationDate(node.data.item.data.file_created_at)}
				</div>
			</div>
		);
	},
);

const RenameFolderDialog = observer(
	({
		onOpenChange,
		folder,
	}: {
		onOpenChange: (open: boolean) => void;
		folder: Folder;
	}) => {
		const appContext = useAppContext();
		const [folderName, setFolderName] = useState(folder.file_name);

		return (
			<Dialog open onOpenChange={onOpenChange}>
				<DialogContent
					// Is there a cleaner way to prevent the dialog from affecting the tree?
					onClick={(e) => {
						e.stopPropagation();
					}}
					onSelect={(e) => {
						e.stopPropagation();
					}}
					onKeyDown={(e) => {
						e.stopPropagation();
					}}
				>
					<DialogHeader>
						<DialogTitle>Edit folder</DialogTitle>
					</DialogHeader>
					<div className="grid grid-cols-4 items-center gap-4">
						<Label htmlFor="folderName" className="text-right text-neutral-900">
							Name
						</Label>
						<Input
							id="folderName"
							value={folderName}
							className="col-span-3"
							type="text"
							onChange={(e) => setFolderName(e.currentTarget.value)}
						/>
					</div>
					<div className="flex justify-end">
						<DialogClose
							className="mt-4 rounded-md bg-black px-8 py-2 text-white"
							onClick={() => {
								appContext.renameFolder({
									folderId: folder.folder_id as FolderId,
									folderName,
								});
							}}
						>
							Apply changes
						</DialogClose>
					</div>
				</DialogContent>
			</Dialog>
		);
	},
);

const DeleteFolderDialog = observer(
	({
		onOpenChange,
		folder,
	}: {
		onOpenChange: (open: boolean) => void;
		folder: Folder;
	}) => {
		const appContext = useAppContext();

		return (
			<AlertDialog open onOpenChange={onOpenChange}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Delete folder?</AlertDialogTitle>
						<AlertDialogDescription>
							This will delete the folder and all its contents.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancel</AlertDialogCancel>
						<AlertDialogAction
							onClick={() => {
								appContext.deleteFolder({
									folderId: folder.folder_id as FolderId,
								});
							}}
						>
							Continue
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		);
	},
);

const DeleteUploadDialog = observer(
	({
		onOpenChange,
		upload,
	}: {
		onOpenChange: (open: boolean) => void;
		upload: Upload;
	}) => {
		const appContext = useAppContext();

		return (
			<AlertDialog open onOpenChange={onOpenChange}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Delete upload?</AlertDialogTitle>
						<AlertDialogDescription>
							This will remove the upload from the library.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancel</AlertDialogCancel>
						<AlertDialogAction
							onClick={() => {
								appContext.deleteUpload({
									uploadId: upload.upload_id as UploadId,
								});
							}}
						>
							Continue
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		);
	},
);

const DeleteMultipleDialog = observer(
	({ onOpenChange }: { onOpenChange: (open: boolean) => void }) => {
		const appContext = useAppContext();
		const libraryContext = useLibraryContext();

		return (
			<AlertDialog open onOpenChange={onOpenChange}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Delete selected?</AlertDialogTitle>
						<AlertDialogDescription>
							This will remove the selected folders and uploads from the
							library.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancel</AlertDialogCancel>
						<AlertDialogAction
							onClick={() => {
								const folderIds = [...libraryContext.selectedNodes.values()]
									.map(
										(node) =>
											node.data.item.type === "folder" &&
											(node.data.item.data.folder_id as FolderId),
									)
									.filter(Boolean) as FolderId[];
								const uploadIds = [...libraryContext.selectedNodes.values()]
									.map(
										(node) =>
											node.data.item.type === "upload" &&
											(node.data.item.data.upload_id as UploadId),
									)
									.filter(Boolean) as UploadId[];

								appContext.deleteMultiple({
									folderIds,
									uploadIds: uploadIds,
								});
							}}
						>
							Continue
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		);
	},
);

const EditUploadMetaDialog = observer(
	({
		onOpenChange,
		upload,
	}: {
		onOpenChange: (open: boolean) => void;
		upload: Upload;
	}) => {
		return (
			<Dialog open onOpenChange={onOpenChange}>
				<MetadataEditDialogContent uploadId={upload.upload_id as UploadId} />
			</Dialog>
		);
	},
);

const UploadToFolderDialog = observer(
	({
		onOpenChange,
		folder,
	}: {
		onOpenChange: (open: boolean) => void;
		folder: Folder;
	}) => {
		return (
			<Dialog open onOpenChange={onOpenChange}>
				<FileUploadDialogContent folder={folder} />
			</Dialog>
		);
	},
);

const ContextMenuActions = observer(() => {
	const libraryContext = useLibraryContext();
	const appContext = useAppContext();

	const node = libraryContext.rightClickedNode;
	const selectedNodes = Array.from(libraryContext.selectedNodes.values());
	const [, setSearchParams] = useSearchParams();

	const [openDialog, setOpenDialog] = useState<
		| { type: "renameFolder"; props: { folder: Folder } }
		| { type: "uploadToFolder"; props: { folder: Folder } }
		| { type: "editUploadMeta"; props: { upload: Upload } }
		| { type: "deleteUpload"; props: { upload: Upload } }
		| { type: "deleteFolder"; props: { folder: Folder } }
		| { type: "deleteMultiple" }
		| null
	>(null);

	if (!node) {
		return null;
	}

	const item = node.data.item;

	return (
		<>
			<ContextMenuContent>
				{item.type === "folder" && (
					<>
						<ContextMenuItem
							onClick={() => {
								node.toggle();
							}}
							className="flex items-center gap-2"
						>
							{node.isOpen ? (
								<>
									<FolderIcon weight="bold" /> Close folder
								</>
							) : (
								<>
									<FolderOpen weight="bold" /> Open folder
								</>
							)}
						</ContextMenuItem>
						<ContextMenuSeparator />
						<ContextMenuItem
							onClick={() => {
								setOpenDialog({
									type: "renameFolder",
									props: {
										folder: item.data,
									},
								});
							}}
							className="flex items-center gap-2"
						>
							<PencilSimple weight="bold" /> Rename folder
						</ContextMenuItem>
						<ContextMenuItem
							className="flex items-center gap-2"
							onClick={() => {
								setOpenDialog({
									type: "uploadToFolder",
									props: {
										folder: item.data,
									},
								});
							}}
						>
							<UploadSimple weight="bold" />
							Upload files to folder
						</ContextMenuItem>
					</>
				)}
				{item.type === "upload" && (
					<>
						<ContextMenuItem
							onClick={() => {
								setSearchParams((prev) => {
									prev.set("upload_id", item.data.upload_id as UploadId);
									return prev;
								});
							}}
							className="flex items-center gap-2"
						>
							<ArrowUpRight weight="bold" /> Open upload
						</ContextMenuItem>
						<ContextMenuSeparator />
						<ContextMenuItem
							onClick={() => {
								setOpenDialog({
									type: "editUploadMeta",
									props: {
										upload: item.data,
									},
								});
							}}
							className="flex items-center gap-2"
						>
							<PencilSimple weight="bold" /> Edit upload metadata
						</ContextMenuItem>
						<ContextMenuItem
							className="flex items-center gap-2"
							onClick={() => {
								appContext.downloadUploadPdf(item.data.upload_id as UploadId);
							}}
						>
							<DownloadSimple weight="bold" /> Processed PDF
						</ContextMenuItem>
						<ContextMenuItem
							className="flex items-center gap-2"
							onClick={() => {
								appContext.downloadOriginalUploadFile(
									item.data.upload_id as UploadId,
								);
							}}
						>
							<DownloadSimple weight="bold" /> Original{" "}
							{item.data.upload_filetype.toUpperCase()}
						</ContextMenuItem>
					</>
				)}
				<ContextMenuSeparator />
				{libraryContext.rightClickedNodeIsSelected &&
				libraryContext.selectedNodes.size > 1 ? (
					<ContextMenuItem
						onClick={() => {
							setOpenDialog({ type: "deleteMultiple" });
						}}
						className="flex items-center gap-2"
					>
						<Trash weight="bold" />
						Delete {selectedNodes.length} selected
					</ContextMenuItem>
				) : (
					<>
						{item.type === "folder" && (
							<ContextMenuItem
								onClick={() => {
									setOpenDialog({
										type: "deleteFolder",
										props: {
											folder: item.data,
										},
									});
								}}
								className="flex items-center gap-2"
							>
								<Trash weight="bold" />
								Delete folder
							</ContextMenuItem>
						)}
						{item.type === "upload" && (
							<ContextMenuItem
								onClick={() => {
									setOpenDialog({
										type: "deleteUpload",
										props: {
											upload: item.data,
										},
									});
								}}
								className="flex items-center gap-2"
							>
								<Trash weight="bold" />
								Delete upload
							</ContextMenuItem>
						)}
					</>
				)}
			</ContextMenuContent>
			{openDialog?.type === "renameFolder" && (
				<RenameFolderDialog
					onOpenChange={(open) => {
						if (!open) {
							setOpenDialog(null);
						}
					}}
					folder={openDialog.props.folder}
				/>
			)}
			{openDialog?.type === "deleteFolder" && (
				<DeleteFolderDialog
					folder={openDialog.props.folder}
					onOpenChange={(open) => {
						if (!open) {
							setOpenDialog(null);
						}
					}}
				/>
			)}
			{openDialog?.type === "deleteUpload" && (
				<DeleteUploadDialog
					upload={openDialog.props.upload}
					onOpenChange={(open) => {
						if (!open) {
							setOpenDialog(null);
						}
					}}
				/>
			)}
			{openDialog?.type === "deleteMultiple" && (
				<DeleteMultipleDialog
					onOpenChange={(open) => {
						if (!open) {
							setOpenDialog(null);
						}
					}}
				/>
			)}
			{openDialog?.type === "editUploadMeta" && (
				<EditUploadMetaDialog
					upload={openDialog.props.upload}
					onOpenChange={(open) => {
						if (!open) {
							setOpenDialog(null);
						}
					}}
				/>
			)}
			{openDialog?.type === "uploadToFolder" && (
				<UploadToFolderDialog
					folder={openDialog.props.folder}
					onOpenChange={(open) => {
						if (!open) {
							setOpenDialog(null);
						}
					}}
				/>
			)}
		</>
	);
});

export const DirectoryListing = observer(() => {
	const appContext = useAppContext();
	const libraryContext = useLibraryContext();
	const { ref, height, width } = useResizeObserver<HTMLDivElement>();
	const { activeFolderId } = useParams<{
		activeFolderId?: FolderId;
	}>();
	const treeRef = useRef<TreeApi<AnyDirectoryNode>>();

	const [searchQuery, setSearchQuery] = useState("");
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const matchedUploadIds = useMemo(() => {
		return appContext.searchUploadsByMetadata(searchQuery);
	}, [searchQuery]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const activeFolderPath = useMemo(() => {
		let parent = libraryContext.activeFolder;
		if (!parent) {
			return [];
		}

		const path: NodeApi<FolderDirectoryNode>[] = [parent];

		while (parent) {
			parent = parent.parent;
			// exclude the root node
			if (parent !== null && parent.level > -1) {
				path.unshift(parent);
			}
		}
		return path;
	}, [toJS(libraryContext.activeFolder)]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (activeFolderId) {
			runInAction(() => {
				libraryContext.activeFolder =
					treeRef.current?.get(activeFolderId) ?? null;
			});
			return;
		}
		runInAction(() => {
			libraryContext.activeFolder = null;
		});
	}, [activeFolderId, appContext.folderImmediateChildren]);

	const [searchParams] = useSearchParams();
	const activeUploadId = searchParams.get("upload_id");

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (activeUploadId) {
			runInAction(() => {
				libraryContext.activeUploadId = activeUploadId as UploadId;
			});
		}
	}, [activeUploadId]);

	return (
		<div className="relative mx-auto flex h-full w-full flex-col">
			<nav
				className={clsx(
					"flex h-14 w-full shrink-0 items-center justify-between gap-2 border-b bg-white/90 px-2 backdrop-blur",
				)}
			>
				<div className="flex items-center">
					{!appContext.showSidebar && <ShowSidebarButton />}

					<Breadcrumb>
						<BreadcrumbList className="p-4">
							<BreadcrumbItem key="root">
								<BreadcrumbLink asChild>
									<NavLink to="/library" className="flex items-center gap-1">
										<House weight="duotone" />
										Home
									</NavLink>
								</BreadcrumbLink>
							</BreadcrumbItem>
							{activeFolderPath?.map((node) => (
								<Fragment key={node.id}>
									<BreadcrumbSeparator />
									<BreadcrumbItem>
										<BreadcrumbLink asChild>
											<NavLink
												to={`/library/${node.data.item.data.folder_id}`}
												className="flex items-center gap-1 text-neutral-600"
											>
												<FolderSimple weight="duotone" />
												{node.data.item.data.file_name}
											</NavLink>
										</BreadcrumbLink>
									</BreadcrumbItem>
								</Fragment>
							))}
						</BreadcrumbList>
					</Breadcrumb>

					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								type="button"
								variant="ghost"
								className="gap-2"
								onClick={() => {
									appContext.createFolder({
										parentFolderId: null,
										folderName: "New folder",
									});
								}}
								disabled={appContext.rootDirectoryNodes === null}
							>
								<FolderPlus className="text-lg" weight="duotone" />
							</Button>
						</TooltipTrigger>
						<TooltipContent align="start">New folder</TooltipContent>
					</Tooltip>
					<Tooltip>
						<Dialog modal>
							<TooltipTrigger asChild>
								<DialogTrigger
									disabled={appContext.rootDirectoryNodes === null}
									asChild
								>
									<Button
										type="button"
										variant="ghost"
										className="gap-2"
										disabled={appContext.rootDirectoryNodes === null}
									>
										<UploadSimple className="text-lg" />
									</Button>
								</DialogTrigger>
							</TooltipTrigger>
							<FileUploadDialogContent
								folder={libraryContext.activeFolder?.data.item.data ?? null}
							/>
						</Dialog>
						<TooltipContent>Upload files</TooltipContent>
					</Tooltip>
				</div>
				{libraryContext.activeUploadId && (
					<button
						type="button"
						className="rounded-lg p-2 text-neutral-500 text-xl hover:bg-neutral-100 hover:text-neutral-900"
						onClick={() => {
							runInAction(() => {
								libraryContext.activeUploadId = null;
							});
						}}
					>
						<ArrowLineRight weight="bold" />
					</button>
				)}
			</nav>

			<div className="px-3 pt-3">
				<Input
					placeholder="Search uploads..."
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
			</div>

			<div className="mt-2 flex w-full items-center border-b px-4 pb-2 text-neutral-700 text-sm">
				<div className="w-2/3">Name</div>
				<div className="w-1/3">Date added</div>
			</div>

			<div className="max-h-full min-h-0 grow">
				{appContext.rootDirectoryNodes === null ? (
					<div className="flex flex-col gap-1.5 p-3">
						<Skeleton className="h-8 w-full" />
						<Skeleton className="h-8 w-full" />
						<Skeleton className="h-8 w-full" />
					</div>
				) : (
					<div className="h-full" ref={ref}>
						<ContextMenu>
							<ContextMenuTrigger asChild>
								<Tree
									ref={treeRef}
									data={
										libraryContext.activeFolder
											? appContext.folderImmediateChildren.get(
													libraryContext.activeFolder.id as FolderId,
												)
											: appContext.rootDirectoryNodes
									}
									idAccessor={(node) => node.node_id}
									childrenAccessor={(node) =>
										appContext.treeChildrenAccessor(node)
									}
									onMove={(moveProps) => appContext.treeMoveHandler(moveProps)}
									openByDefault={false}
									width={width}
									height={height}
									rowHeight={32}
									paddingTop={0}
									paddingBottom={0}
									padding={8}
									indent={0}
									onSelect={(nodes) => {
										libraryContext.setSelectedNodes(nodes);
									}}
									searchTerm={searchQuery}
									searchMatch={(node) => {
										if (node.data.item.type === "upload") {
											return matchedUploadIds.has(
												node.data.item.data.upload_id as UploadId,
											);
										}
										return false;
									}}
								>
									{LibraryTreeNode}
								</Tree>
							</ContextMenuTrigger>
							<ContextMenuActions />
						</ContextMenu>
					</div>
				)}
			</div>
		</div>
	);
});
