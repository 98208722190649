import { type AppState, useAppContext } from "@/contexts/AppContext";
import type {
	AnyDirectoryNode,
	FolderDirectoryNode,
} from "@/contexts/AppContext/TreeHandlers";
import type { UploadId } from "@/idGenerators";
import { makeAutoObservable } from "mobx";
import { createContext, useContext, useRef } from "react";
import type { NodeApi } from "react-arborist";

export class LibraryState {
	appState: AppState;
	activeUploadId: UploadId | null = null;
	rightClickedNode: NodeApi<AnyDirectoryNode> | null = null;
	selectedNodes: Map<string, NodeApi<AnyDirectoryNode>> = new Map();
	activeFolder: NodeApi<FolderDirectoryNode> | null = null;

	constructor(appState: AppState) {
		this.appState = appState;
		makeAutoObservable(this);
	}

	get selectedUpload() {
		if (!this.activeUploadId) {
			return null;
		}
		return this.appState.getUploadById(this.activeUploadId);
	}

	setRightClickedNode(node: NodeApi<AnyDirectoryNode> | null) {
		this.rightClickedNode = node;
	}

	setSelectedNodes(nodes: NodeApi<AnyDirectoryNode>[]) {
		this.selectedNodes = new Map(nodes.map((node) => [node.id, node]));
	}

	get rightClickedNodeIsSelected() {
		return (
			this.rightClickedNode && this.selectedNodes.has(this.rightClickedNode.id)
		);
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const LibraryContext = createContext<LibraryState>(null as any);

export const useLibraryContext = () => {
	const context = useContext(LibraryContext);
	if (!context) {
		throw new Error("useLibraryContext must be used within an LibraryProvider");
	}

	return context;
};

export const LibraryProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const appContext = useAppContext();
	const libraryState = useRef(new LibraryState(appContext));

	return (
		<LibraryContext.Provider value={libraryState.current}>
			{children}
		</LibraryContext.Provider>
	);
};
