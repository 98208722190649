import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";
import { useAppContext } from "@/contexts/AppContext";
import type { FeedItemId } from "@/idGenerators";
import { useGetFeedItem } from "@api/fastAPI";
import { ArrowUpRight, DotOutline, Hourglass } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import type { ComponentPropsWithoutRef } from "react";
import Markdown from "react-markdown";

// Custom link component
const CustomLink = ({
	href,
	children,
	...props
}: ComponentPropsWithoutRef<"a">) => {
	return (
		<a href={href} target="_blank" rel="noopener noreferrer" {...props}>
			{children}
		</a>
	);
};

const _FeedItemViewer = observer(
	({ feedItemId }: { feedItemId: FeedItemId }) => {
		const { data: feedItem } = useGetFeedItem({ feed_item_id: feedItemId });

		if (!feedItem) {
			return null;
		}

		return (
			<>
				<div className="flex h-14 w-full min-w-0 shrink-0 truncate border-b px-4 text-sm">
					<HoverCard>
						<HoverCardTrigger className="flex flex-col justify-center truncate text-sm">
							<h1 className="min-w-0 truncate font-semibold text-neutral-700">
								{feedItem.data.file_name}
							</h1>
							{feedItem.data.feed_item_link && (
								<h2>
									<a
										href={feedItem.data.feed_item_link}
										target="_blank"
										rel="noreferrer"
										className="flex items-center gap-1 truncate text-blue-500 hover:underline"
									>
										View original
										<ArrowUpRight weight="bold" />
									</a>
								</h2>
							)}
						</HoverCardTrigger>
						<HoverCardContent align="start" className="w-96 gap-3 text-sm">
							{feedItem.data.feed_item_og_image && (
								<img
									src={feedItem.data.feed_item_og_image}
									alt={feedItem.data.file_name}
									className="mb-3 flex w-full shrink-0 items-center justify-center rounded object-cover text-xs"
								/>
							)}
							<h1 className="min-w-0 font-semibold text-neutral-700">
								{feedItem.data.file_name}
							</h1>
							{feedItem.data.feed_item_author && (
								<h2 className="mt-0.5 flex items-center text-neutral-500">
									By {feedItem.data.feed_item_author}
									{feedItem.data.feed_item_wordcount && (
										<>
											<DotOutline weight="fill" className="shrink-0" />
											<h2 className="text-neutral-500">
												{feedItem.data.feed_item_wordcount} word
												{feedItem.data.feed_item_wordcount > 1 ? "s" : ""}
											</h2>
										</>
									)}
								</h2>
							)}
							<div className="mt-2 w-full rounded-lg bg-neutral-100 px-2 py-1">
								{feedItem.data.feed_item_pub_date && (
									<h2 className="text-neutral-500">
										Published{" "}
										{dayjs(feedItem.data.feed_item_pub_date).format(
											"MMM D, YYYY h:mm A",
										)}
									</h2>
								)}
								<h2 className="text-neutral-500">
									Retrieved{" "}
									{dayjs(feedItem.data.file_created_at).format(
										"MMM D, YYYY h:mm A",
									)}
								</h2>
							</div>
						</HoverCardContent>
					</HoverCard>
				</div>
				{feedItem.data.feed_item_status === "pending" ? (
					<div className="flex h-full w-full grow flex-col items-center justify-center bg-neutral-100 p-8 text-neutral-500">
						<Hourglass className="text-6xl text-neutral-600" weight="duotone" />
						<h1 className="mt-4">This article is currently being indexed.</h1>
						<h2>Please check back later.</h2>
					</div>
				) : (
					<motion.div
						key={feedItemId}
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={{
							hidden: { opacity: 0 },
							visible: { opacity: 1 },
						}}
						transition={{ duration: 0.2 }}
						className="w-full overflow-y-scroll"
					>
						<Markdown
							className="prose-sm mx-auto max-w-screen-md grow px-6 pt-8 pb-16"
							components={{
								a: CustomLink,
							}}
						>
							{feedItem.data.feed_item_markdown}
						</Markdown>
					</motion.div>
				)}
			</>
		);
	},
);

export const FeedItemViewer = observer(
	({ feedItemId }: { feedItemId: FeedItemId }) => {
		const appContext = useAppContext();

		if (!appContext.feedItemsById) {
			return null;
		}

		const feedItemMeta = appContext.feedItemsById.get(feedItemId);

		if (!feedItemMeta) {
			return (
				<div className="flex h-full w-full grow items-center justify-center bg-neutral-100">
					<h1 className="text-neutral-500">Article not found</h1>
				</div>
			);
		}

		if (feedItemMeta.feed_item_status === "pending") {
			return (
				<div className="flex h-full w-full grow flex-col items-center justify-center bg-neutral-100 p-8 text-neutral-500">
					<Hourglass className="text-6xl text-neutral-600" weight="duotone" />
					<h1 className="mt-4">This article is currently being indexed.</h1>
					<h2>Please check back later.</h2>
				</div>
			);
		}

		return <_FeedItemViewer feedItemId={feedItemId} />;
	},
);
