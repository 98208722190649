import { convert } from "html-to-text";

export const cleanXmlText = (text: string): string => {
	return convert(text);
};

export function cleanUrlForDisplay(url: string) {
	// trim starting http:// or https://
	let cleandUrl = url.replace(/^https?:\/\//, "");
	// trim trailing slash
	cleandUrl = cleandUrl.replace(/\/$/, "");
	return cleandUrl;
}
