// Adopted from https://github.com/slavianap/Smith-Waterman-Algorithm/blob/master/Script.py

/** Represents a block in the sequence alignment */
export type AlignedPosition = GapPosition | CharPosition;

/** Represents a single gap in the sequence alignment */
type GapPosition = { type: "gap" };

/** Represents a single character in the sequence alignment */
type CharPosition = { type: "char"; char: string; originalIndex: number };

// Enum for scoring values
enum Score {
	MATCH = 1,
	MISMATCH = -1,
	GAP = -1,
}

// Enum for traceback directions
enum Trace {
	STOP = 0,
	LEFT = 1,
	UP = 2,
	DIAGONAL = 3,
}

/**
 * Performs the Smith-Waterman algorithm for local sequence alignment.
 *
 * @param seq1 - The first sequence to align
 * @param seq2 - The second sequence to align
 * @returns A tuple containing the aligned sequences as arrays of Position objects
 */
export function smithWaterman(
	seq1: string,
	seq2: string,
): [AlignedPosition[], AlignedPosition[]] {
	const rows = seq1.length + 1;
	const cols = seq2.length + 1;

	// Initialize scoring matrix and tracing matrix
	const matrix = Array(rows)
		.fill(null)
		.map(() => Array(cols).fill(0));
	const tracingMatrix = Array(rows)
		.fill(null)
		.map(() => Array(cols).fill(0));

	let maxScore = -1;
	let maxIndex: [number, number] = [-1, -1];

	// Fill the matrix
	for (let i = 1; i < rows; i++) {
		for (let j = 1; j < cols; j++) {
			// Calculate match/mismatch score
			const matchValue =
				seq1[i - 1] === seq2[j - 1] ? Score.MATCH : Score.MISMATCH;

			// Calculate scores for different moves
			const diagonalScore = matrix[i - 1][j - 1] + matchValue;
			const verticalScore = matrix[i - 1][j] + Score.GAP;
			const horizontalScore = matrix[i][j - 1] + Score.GAP;

			// Choose the maximum score
			matrix[i][j] = Math.max(0, diagonalScore, verticalScore, horizontalScore);

			// Set the tracing direction
			if (matrix[i][j] === 0) {
				tracingMatrix[i][j] = Trace.STOP;
			} else if (matrix[i][j] === horizontalScore) {
				tracingMatrix[i][j] = Trace.LEFT;
			} else if (matrix[i][j] === verticalScore) {
				tracingMatrix[i][j] = Trace.UP;
			} else if (matrix[i][j] === diagonalScore) {
				tracingMatrix[i][j] = Trace.DIAGONAL;
			}

			// Update max score and its position
			if (matrix[i][j] >= maxScore) {
				maxIndex = [i, j];
				maxScore = matrix[i][j];
			}
		}
	}

	// Traceback
	let alignedSeq1: AlignedPosition[] = [];
	let alignedSeq2: AlignedPosition[] = [];
	let [maxI, maxJ] = maxIndex;

	// Add the part after the common subsequence
	for (let i = 0; i < seq2.length - maxJ; i++) {
		alignedSeq1.push({ type: "gap" });
	}
	for (let i = maxI; i < seq1.length; i++) {
		alignedSeq1.push({ type: "char", char: seq1[i], originalIndex: i });
	}
	for (let j = maxJ; j < seq2.length; j++) {
		alignedSeq2.push({ type: "char", char: seq2[j], originalIndex: j });
	}
	for (let i = 0; i < seq1.length - maxI; i++) {
		alignedSeq2.push({ type: "gap" });
	}

	// Perform the traceback
	while (tracingMatrix[maxI][maxJ] !== Trace.STOP) {
		if (tracingMatrix[maxI][maxJ] === Trace.DIAGONAL) {
			// Match or mismatch
			alignedSeq1.unshift({
				type: "char",
				char: seq1[maxI - 1],
				originalIndex: maxI - 1,
			});
			alignedSeq2.unshift({
				type: "char",
				char: seq2[maxJ - 1],
				originalIndex: maxJ - 1,
			});
			maxI--;
			maxJ--;
		} else if (tracingMatrix[maxI][maxJ] === Trace.UP) {
			// Gap in seq2
			alignedSeq1.unshift({
				type: "char",
				char: seq1[maxI - 1],
				originalIndex: maxI - 1,
			});
			alignedSeq2.unshift({ type: "gap" });
			maxI--;
		} else if (tracingMatrix[maxI][maxJ] === Trace.LEFT) {
			// Gap in seq1
			alignedSeq1.unshift({ type: "gap" });
			alignedSeq2.unshift({
				type: "char",
				char: seq2[maxJ - 1],
				originalIndex: maxJ - 1,
			});
			maxJ--;
		}
	}

	// Add the part before the common subsequence
	const seq1Before: AlignedPosition[] = seq1
		.slice(0, maxI)
		.split("")
		.map((char, index) => ({ type: "char", char, originalIndex: index }));
	const seq2Before: AlignedPosition[] = seq2
		.slice(0, maxJ)
		.split("")
		.map((char, index) => ({ type: "char", char, originalIndex: index }));

	// Combine the before, aligned, and after parts
	alignedSeq1 = [...seq1Before, ...alignedSeq1];
	for (let i = 0; i < maxI; i++) {
		alignedSeq2.unshift({ type: "gap" });
	}
	alignedSeq2 = [...seq2Before, ...alignedSeq2];
	for (let i = 0; i < maxJ; i++) {
		alignedSeq1.unshift({ type: "gap" });
	}

	return [alignedSeq1, alignedSeq2];
}

export const logAlignment = (alignment: AlignedPosition[]) => {
	const alignmentStr = alignment
		.map((pos) => {
			if (pos.type === "char") {
				return pos.char;
			}
			return "-";
		})
		.join("");
	console.log(alignmentStr);
};
