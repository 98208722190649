/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export type UploadType = typeof UploadType[keyof typeof UploadType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadType = {
  article: 'article',
  book: 'book',
} as const;
