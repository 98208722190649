import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/AppContext";
import { CaretDoubleRight } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";

export const ShowSidebarButton = observer(() => {
	const appContext = useAppContext();

	return (
		<Tooltip>
			<TooltipTrigger>
				<button
					type="button"
					className="flex shrink-0 items-center justify-center rounded-lg p-3 text-lg text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900"
					onClick={() =>
						runInAction(() => {
							appContext.showSidebar = true;
						})
					}
				>
					<CaretDoubleRight className="shrink-0" weight="bold" />
				</button>
			</TooltipTrigger>
			<TooltipContent side="right">Show sidebar</TooltipContent>
		</Tooltip>
	);
});
