import { UploadCoverImage } from "@/components/UploadCoverImage";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";
import { useSearchContext } from "@/contexts/SearchContext";
import type { UploadId } from "@/idGenerators";
import { formatAuthors, formatTitle } from "@/lib/utils";
import type {
	SearchLibraryResultOutput as SearchLibraryResult,
	Upload,
} from "@api/schemas";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { forwardRef, useCallback } from "react";

export const SearchLibraryResultComponent = observer(
	forwardRef(
		(
			{
				result,
				upload,
				focused,
				searchResultsContainerIsFocused,
				selectCallback,
			}: {
				result: SearchLibraryResult;
				upload: Upload;
				focused: boolean;
				searchResultsContainerIsFocused: boolean;
				selectCallback: () => void;
			},
			ref: React.Ref<HTMLDivElement>,
		) => {
			const searchContext = useSearchContext();

			// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
			const onSelect = useCallback(() => {
				runInAction(() => {
					searchContext.activeSearchResult = {
						...result,
						upload: upload,
					};
				});
				selectCallback();
			}, [result, upload]);

			return (
				<HoverCard open={false}>
					<HoverCardTrigger>
						<div
							className={clsx(
								"cursor-pointer p-4",
								searchContext.activeSearchResult?.type === "library" &&
									searchContext.activeSearchResult?.chunk_id === result.chunk_id
									? "border-neutral-200 bg-neutral-100"
									: "hover:bg-neutral-50",
							)}
							ref={ref}
							style={{
								boxShadow: focused
									? searchResultsContainerIsFocused
										? "inset 4px 0 0 #a3a3a3"
										: "inset 4px 0 0 #e5e5e5"
									: "",
							}}
							onClick={onSelect}
							onKeyDown={onSelect}
						>
							<div className="ml-1 flex items-center gap-2">
								<div>
									<UploadCoverImage
										size={128}
										upload_id={upload.upload_id as UploadId}
										upload_status={upload.upload_status ?? "pending"}
										className={(uploadStatus) =>
											clsx(
												"h-8 max-w-6 rounded-xs",
												uploadStatus === "ready" && "shadow",
											)
										}
									/>
								</div>

								<div className="min-w-0 truncate">
									<h2 className="min-w-0 truncate text-sm leading-4">
										{formatTitle({
											title: upload.upload_title,
											subtitle: upload.upload_subtitle,
											filename: upload.file_name,
										})}
									</h2>
									<h3 className="text-neutral-500 text-sm leading-4">
										{formatAuthors(upload.upload_authors ?? [])}
										{upload.upload_year_published &&
											`, ${upload.upload_year_published}`}
									</h3>
								</div>
							</div>

							<div className="mt-2 rounded-md border bg-neutral-50">
								<p className="line-clamp-4 rounded-t-md bg-white px-1.5 py-0.5 font-thin text-neutral-600 text-xs">
									{result.chunk_text}
								</p>
								<div className="rounded-b-md border-t px-1.5 py-0.5 text-neutral-600 text-xs">
									{result.chunk_page_indices.length > 1 ? (
										<>
											Pages {result.chunk_page_indices[0] + 1}-
											{result.chunk_page_indices[
												result.chunk_page_indices.length - 1
											] + 1}
										</>
									) : (
										<>Page {result.chunk_page_indices[0] + 1}</>
									)}
								</div>
							</div>
						</div>
					</HoverCardTrigger>
					<HoverCardContent
						side="bottom"
						align="start"
						className="w-96 rounded-lg p-0"
						collisionPadding={8}
					>
						<div className="flex items-center justify-start gap-4 p-4 text-sm">
							<div>
								<UploadCoverImage
									size={256}
									upload_id={upload.upload_id as UploadId}
									upload_status={upload.upload_status}
									className={(uploadStatus) =>
										clsx(
											"h-12 max-w-12 rounded-xs",
											uploadStatus === "ready" && "shadow",
										)
									}
								/>
							</div>

							<div className="min-w-0 truncate">
								<h2 className="min-w-0 truncate font-semibold">
									{formatTitle({
										title: upload.upload_title,
										subtitle: upload.upload_subtitle,
										filename: upload.file_name,
									})}
								</h2>
								<h3 className="text-neutral-900">
									{formatAuthors(upload.upload_authors ?? [])}
									{upload.upload_year_published &&
										`, ${upload.upload_year_published}`}
								</h3>
								{upload.upload_publisher && (
									<h3 className="text-neutral-500">
										{upload.upload_publisher}
									</h3>
								)}
							</div>
						</div>
						<div className="rounded-b-lg border-t">
							<p className="max-h-64 overflow-y-scroll bg-neutral-50 px-4 py-3 text-neutral-600 text-sm shadow-inner">
								{result.chunk_text}
							</p>
							<div className="rounded-b-lg border-t px-4 py-1 text-neutral-600 text-xs">
								{result.chunk_page_indices.length > 1 ? (
									<>
										Pages {result.chunk_page_indices[0] + 1}-
										{result.chunk_page_indices[
											result.chunk_page_indices.length - 1
										] + 1}
									</>
								) : (
									<>Page {result.chunk_page_indices[0] + 1}</>
								)}
							</div>
						</div>
					</HoverCardContent>
				</HoverCard>
			);
		},
	),
);
