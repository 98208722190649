import { ulid } from "ulidx";

export type ActionId = string & { _brand: "ActionId" };
export type StepId = string & { _brand: "StepId" };
export type ChatId = string & { _brand: "ChatId" };
export type UploadId = string & { _brand: "UploadId" };
export type ChunkId = string & { _brand: "ChunkId" };
export type FolderId = string & { _brand: "FolderId" };
export type FeedChannelId = string & { _brand: "FeedChannelId" };
export type FeedItemId = string & { _brand: "FeedItemId" };
export type TableId = string & { _brand: "TableId" };
export type TableColumnId = string & { _brand: "TableColumnId" };
export type TableRowId = string & { _brand: "TableRowId" };
export type FileId =
	| ChatId
	| UploadId
	| FolderId
	| FeedChannelId
	| FeedItemId
	| TableId;

type IdType =
	| "step"
	| "action"
	| "feed-channel"
	| "feed-item"
	| "folder"
	| "chat"
	| "table"
	| "table-column"
	| "upload"
	| "file";

type IdMap = {
	step: StepId;
	action: ActionId;
	"feed-channel": FeedChannelId;
	"feed-item": FeedItemId;
	folder: FolderId;
	chat: ChatId;
	table: TableId;
	"table-column": TableColumnId;
	upload: UploadId;
	file: FileId;
};

const generateId = <T extends IdType>(prefix: T): IdMap[T] => {
	return `${prefix}_${ulid()}` as IdMap[T];
};

export const newStepId = () => generateId("step");
export const newActionId = () => generateId("action");
export const newFeedChannelId = () => generateId("feed-channel");
export const newFeedItemId = () => generateId("feed-item");
export const newFolderId = () => generateId("folder");
export const newChatId = () => generateId("chat");
export const newTableId = () => generateId("table");
export const newTableColumnId = () => generateId("table-column");
export const newUploadId = () => generateId("upload");
