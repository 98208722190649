import { type AppState, useAppContext } from "@/contexts/AppContext";
import type { UploadId } from "@/idGenerators";
import { makeAutoObservable } from "mobx";
import { createContext, useContext, useRef } from "react";

export class DocumentSelectorState {
	appState: AppState;
	selectedUploadIds: Set<UploadId>;
	previewedUploadId: UploadId | null = null;

	constructor(appState: AppState, selectedUploadIds: Set<UploadId>) {
		this.appState = appState;
		this.selectedUploadIds = selectedUploadIds;
		makeAutoObservable(this);
	}

	get previewedUpload() {
		if (!this.previewedUploadId) {
			return null;
		}
		return this.appState.getUploadById(this.previewedUploadId);
	}
}

export const DocumentSelectorContext = createContext<DocumentSelectorState>(
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	null as any,
);

export const useDocumentSelectorContext = () => {
	const context = useContext(DocumentSelectorContext);
	if (!context) {
		throw new Error("useLibraryContext must be used within an LibraryProvider");
	}

	return context;
};

export const DocumentSelectorProvider: React.FC<{
	children: React.ReactNode;
	selectedUploadIds: Set<UploadId>;
}> = ({ children, selectedUploadIds }) => {
	const appContext = useAppContext();
	const documentSelectorState = useRef(
		new DocumentSelectorState(appContext, selectedUploadIds),
	);

	return (
		<DocumentSelectorContext.Provider value={documentSelectorState.current}>
			{children}
		</DocumentSelectorContext.Provider>
	);
};
