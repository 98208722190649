import { Button } from "@/components/ui/button";
import {
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { SearchModeMeta, useSearchContext } from "@/contexts/SearchContext";
import { X } from "@phosphor-icons/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";

interface SearchComboboxCommandListProps {
	setShowCommandList?: (show: boolean) => void;
	disabled?: boolean;
}

export const SearchComboboxCommandList = observer(
	({ setShowCommandList, disabled }: SearchComboboxCommandListProps) => {
		const [_, setSearchParams] = useSearchParams();
		const searchContext = useSearchContext();

		return (
			<CommandList className="max-h-full">
				{searchContext.query && (
					<CommandGroup className="p-2">
						<CommandItem
							key={"current_search"}
							value={searchContext.query}
							className="flex w-full items-center gap-2 rounded-md"
							onSelect={() => {
								if (disabled) return;
								if (!searchContext.showCommandList) return;
								runInAction(() => {
									searchContext.handleSearch(setSearchParams, true);
								});
								setShowCommandList?.(false);
							}}
						>
							<div className="w-full min-w-0">
								<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
									Search for "{searchContext.query}"
								</h3>
								<p className="flex items-center gap-1 text-neutral-500 text-xs">
									<span className="text-sm">
										{SearchModeMeta[searchContext.searchMode].icon}
									</span>
									{searchContext.includedUploadIds.size > 0 ? (
										<>
											{searchContext.includedUploadIds.size}
											{searchContext.includedUploadIds.size === 1 ? "" : "s"}
										</>
									) : (
										"All uploads"
									)}
								</p>
							</div>
						</CommandItem>
					</CommandGroup>
				)}

				<CommandGroup
					className="p-2"
					heading={
						<span className="flex items-baseline justify-between gap-2">
							<h2 className="flex items-center gap-1">
								<span className="text-xs">Recent searches</span>
							</h2>{" "}
							<Button
								className="h-max rounded p-1 text-xs"
								variant="ghost"
								onClick={() => {
									runInAction(() => {
										searchContext.clearSearchHistory();
									});
								}}
							>
								Clear
							</Button>
						</span>
					}
				>
					<CommandEmpty>No recent searches</CommandEmpty>
					{searchContext.uniqueSearchHistory.map((search, idx) => {
						return (
							<CommandItem
								key={search.result_id}
								// we use the index to ensure that the value is unique,
								// otherwise hovering on one item will highlight all items with the same query
								// (but possibly different search modes)
								value={`${search.query} ${idx}`}
								className="group flex w-full items-center gap-2 rounded-md"
								onSelect={() => {
									if (disabled) return;
									runInAction(() => {
										searchContext.query = search.query;
										searchContext.searchMode = search.search_mode;
										searchContext.includedUploadIds = new Set(
											search.included_upload_ids ?? [],
										);
										searchContext.includeLibrary =
											search.include_library ?? true;
										searchContext.includeFeeds = search.include_feeds ?? true;
										searchContext.includedFeedChannelIds = new Set(
											search.included_feed_channel_ids ?? [],
										);

										searchContext.handleSearch(setSearchParams, true);
									});
									setShowCommandList?.(false);
								}}
							>
								<div className="w-full min-w-0">
									<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
										{search.query}
									</h3>
									<p className="flex items-center gap-1 text-neutral-500 text-xs">
										<span className="text-sm">
											{SearchModeMeta[search.search_mode].icon}
										</span>
										{(search.included_upload_ids ?? []).length > 0 ? (
											<>
												{(search.included_upload_ids ?? []).length} upload
												{(search.included_upload_ids ?? []).length === 1
													? ""
													: "s"}
											</>
										) : (
											"All uploads"
										)}
									</p>
								</div>
								<button
									type="button"
									className="rounded-md p-2 opacity-0 hover:bg-neutral-200 group-hover:opacity-100"
									onClick={(e) => {
										e.stopPropagation();
										runInAction(() => {
											searchContext.removeSearchHistoryItem(search);
										});
									}}
								>
									<X size={16} />
								</button>
							</CommandItem>
						);
					})}
				</CommandGroup>
			</CommandList>
		);
	},
);
