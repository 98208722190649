import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useAppContext } from "@/contexts/AppContext";
import type { ChatId } from "@/idGenerators";
import type { ChatMetadata } from "@api/schemas";
import {
	Copy,
	DotsThree,
	Export,
	PencilSimple,
	Trash,
} from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

export const ChatLink = observer(({ chat }: { chat: ChatMetadata }) => {
	const [showOptions, setShowOptions] = useState(false);
	const appContext = useAppContext();
	const navigate = useNavigate();
	const { activeChatId } = useParams();

	const [renaming, setRenaming] = useState(false);
	const [chatTitle, setChatTitle] = useState(chat.file_name);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		// if the chat title gets updated from the server, update the local state
		if (chat.file_name !== chatTitle) {
			setChatTitle(chat.file_name);
		}
	}, [chat.file_name]);

	return (
		<NavLink
			className={({ isActive }) =>
				clsx(
					"group relative flex w-full min-w-0 shrink-0 items-center justify-between gap-1 truncate rounded-lg p-[0.125rem] text-xs",
					isActive
						? "bg-neutral-200 text-neutral-900"
						: "text-neutral-600 hover:bg-neutral-200 hover:text-neutral-900",
				)
			}
			to={`/research/${chat.chat_id}`}
			key={chat.chat_id}
			onClick={(e) => {
				if (renaming) {
					e.preventDefault();
				}
			}}
		>
			{renaming ? (
				<input
					value={chatTitle || "New conversation"}
					onChange={(e) => {
						setChatTitle(e.target.value);
					}}
					onBlur={() => {
						if (renaming) {
							appContext.renameChat({
								chatId: chat.chat_id as ChatId,
								chatTitle: chatTitle || "New conversation",
							});
							setRenaming(false);
						}
					}}
					onClick={(e) => {
						if (renaming) {
							e.stopPropagation();
							e.preventDefault();
						}
					}}
					className={clsx(
						"min-w-0 grow truncate rounded-md border border-transparent p-1",
						renaming
							? "border-neutral-400 bg-white"
							: "bg-transparent outline-none",
					)}
				/>
			) : (
				<div className="min-w-0 grow truncate rounded-md border border-transparent p-1">
					{chat.file_name}
				</div>
			)}
			{/* Hidden element to shift the chat titles when the chat is hovered, otherwise using `hidden` on the actual trigger will cause the dropdown to jump around */}
			<div
				className={clsx(
					"shrink-0 rounded-md p-1 text-neutral-500 text-xl opacity-0 outline-none hover:text-neutral-900",
					showOptions ? "flex" : "hidden group-hover:flex",
				)}
			>
				<DotsThree weight="bold" className="h-1 w-1" />
			</div>
			<DropdownMenu open={showOptions} onOpenChange={setShowOptions}>
				<DropdownMenuTrigger
					className={clsx(
						"absolute right-0 rounded-md p-2 text-neutral-500 text-xl outline-none hover:text-neutral-900",
						showOptions ? "flex" : "invisible group-hover:visible",
					)}
				>
					<DotsThree weight="bold" />
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start">
					<DropdownMenuItem
						onClick={(e) => {
							e.stopPropagation();
							setRenaming(true);
						}}
					>
						<button type="button" className="flex items-center gap-2 ">
							<PencilSimple weight="bold" />
							Rename
						</button>
					</DropdownMenuItem>
					<DropdownMenuItem
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
						className="p-0"
					>
						<Dialog>
							<DialogTrigger
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="flex w-full items-center gap-2 rounded-md px-2 py-1.5"
							>
								<Export weight="bold" />
								Share
							</DialogTrigger>
							<DialogContent>
								<DialogHeader>
									<DialogTitle>Share conversation</DialogTitle>
								</DialogHeader>
								<DialogDescription>
									Other users will be able to view this chat using a link if
									they are logged in. They will not be able to send messages or
									edit the chat.
								</DialogDescription>
								<div className="flex max-w-max items-center gap-3 rounded-lg bg-neutral-100 px-4 py-2 text-neutral-700">
									Make conversation public
									<Switch
										checked={chat.chat_is_public}
										onCheckedChange={(checked) => {
											appContext.toggleChatPublic({
												chatId: chat.chat_id as ChatId,
												isPublic: checked,
											});
										}}
									/>
								</div>
								{chat.chat_is_public && (
									<div className="w-full min-w-0 truncate">
										<Label className="block font-medium text-neutral-700 text-sm">
											Link to view
										</Label>
										<div className="mt-1 flex w-full min-w-0 items-center gap-2 truncate rounded-lg border text-sm">
											<button
												type="button"
												className="flex shrink-0 items-center gap-2 rounded-l-lg border-r bg-neutral-50 p-2 font-semibold text-neutral-500 hover:bg-neutral-100 hover:text-neutral-700"
												onClick={() => {
													navigator.clipboard.writeText(
														appContext.formatChatPublicLink(
															chat.chat_id as ChatId,
														),
													);
													toast.success("Copied link to clipboard");
												}}
											>
												<Copy weight="bold" /> Copy
											</button>
											<span className="select-none truncate p-1 font-mono text-neutral-500">
												{appContext.formatChatPublicLink(
													chat.chat_id as ChatId,
												)}
											</span>
										</div>
									</div>
								)}
							</DialogContent>
						</Dialog>
					</DropdownMenuItem>
					<DropdownMenuItem
						onClick={(e) => {
							e.stopPropagation();
						}}
						className="p-0"
					>
						<AlertDialog>
							<AlertDialogTrigger
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="flex w-full items-center gap-2 rounded-md px-2 py-1.5 text-red-500 hover:bg-red-50 hover:text-red-500"
							>
								<Trash weight="bold" />
								Delete
							</AlertDialogTrigger>
							<AlertDialogContent>
								<AlertDialogHeader>
									<AlertDialogTitle>Delete conversation?</AlertDialogTitle>
									<AlertDialogDescription>
										This will delete the conversation and remove it from your
										list.
									</AlertDialogDescription>
								</AlertDialogHeader>
								<AlertDialogFooter>
									<AlertDialogCancel>Cancel</AlertDialogCancel>
									<AlertDialogAction
										onClick={() => {
											if (activeChatId === chat.chat_id) {
												navigate("/research");
											}

											appContext.deleteChat({ chatId: chat.chat_id as ChatId });
										}}
									>
										Continue
									</AlertDialogAction>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</NavLink>
	);
});
