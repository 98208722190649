import type { AppState } from "@/contexts/AppContext";
import { createSyncedAction } from "@/contexts/SyncedActions";
import { type TableId, newTableColumnId, newTableId } from "@/idGenerators";
import { createTable } from "@api/fastAPI";
import type { TableMetadata } from "@api/schemas";

export const createTableAction = createSyncedAction<
	AppState,
	{
		tableName: string;
		tablePrimaryColumnName: string;
	},
	TableMetadata,
	TableMetadata
>({
	async local(args) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		const tableId = newTableId();
		const newTable: TableMetadata = {
			table_id: tableId,
			file_name: args.tableName,
			table_primary_column_id: newTableColumnId(),
			file_created_at: new Date().toISOString(),
			file_updated_at: new Date().toISOString(),
			file_deleted: false,
			file_creator_id: this.workspace.userId,
			file_type: "table",
		};
		this.workspace.tables.set(tableId, newTable);
		return newTable;
	},
	async remote(args, localResult) {
		const table = await createTable({
			table_id: localResult.table_id,
			table_name: args.tableName,
			table_primary_column_name: args.tablePrimaryColumnName,
		});
		return table.data;
	},
	rollback(_, localResult) {
		this.workspace?.tables.delete(localResult.table_id as TableId);
	},
	onRemoteSuccess(_localArgs, _localResult, remoteResult) {
		this.workspace?.tables.set(remoteResult.table_id as TableId, remoteResult);
	},
});
