import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { API_ENDPOINT_HTTP } from "@/config";
import { useAppContext } from "@/contexts/AppContext";
import { useChatContext } from "@/contexts/ChatContext";
import {
	type HighlightProps,
	PDFViewerProvider,
	type PDFViewerState,
	usePDFViewerContext,
} from "@/contexts/PDFViewerContext";
import { useSearchContext } from "@/contexts/SearchContext";
import { computeGappedHighlight, computeHighlight } from "@/highlight";
import type { UploadId } from "@/idGenerators";
import { formatAuthors, formatTitle } from "@/lib/utils";
import { downloadPdf } from "@api/fastAPI";
import type { Upload } from "@api/schemas";
import {
	DownloadSimple,
	MagnifyingGlassMinus,
	MagnifyingGlassPlus,
} from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import clsx from "clsx";
import type { DebouncedFunc } from "lodash";
import debounce from "lodash.debounce";
import { runInAction, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import type { PDFDocumentProxy } from "pdfjs-dist";
import type React from "react";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	Document,
	type PageProps,
	Page as ReactPDFPage,
	pdfjs,
} from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { BarLoader } from "react-spinners";
import { VariableSizeList } from "react-window";

const PAGE_PADDING = 4;

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

const PDFNavbar = observer(() => {
	const pdfViewerContext = usePDFViewerContext();
	const { currentPageIndex, pdf, upload } = pdfViewerContext;
	const appContext = useAppContext();

	const [currentPageNumber, setCurrentPageNumber] = useState(
		currentPageIndex + 1,
	);

	useEffect(() => {
		setCurrentPageNumber(currentPageIndex + 1);
	}, [currentPageIndex]);

	if (!pdf) return null;

	return (
		<div className="z-20 flex h-14 shrink-0 select-none items-center justify-between truncate border-neutral-300 border-b bg-white px-3">
			<HoverCard>
				<HoverCardTrigger className="flex min-w-0 shrink items-center truncate">
					<img
						alt="Upload thumbnail"
						src={`${API_ENDPOINT_HTTP}/uploads/cover_image/${upload.upload_id}/128.jpg`}
						className="h-8 max-w-8 rounded-xs shadow"
					/>
					<div className="ml-2 flex min-w-0 flex-col truncate">
						<h1 className="min-w-0 truncate pr-4 font-semibold text-neutral-800 text-sm">
							{formatTitle({
								title: upload.upload_title,
								subtitle: upload.upload_subtitle,
								filename: upload.file_name,
							})}
						</h1>
						<h2 className="min-w-0 truncate text-neutral-600 text-sm">
							{formatAuthors(upload.upload_authors ?? [])}
							{upload.upload_year_published &&
								`, ${upload.upload_year_published}`}
						</h2>
					</div>
				</HoverCardTrigger>
				<HoverCardContent align="start" className="w-96">
					<div className="flex items-center gap-4">
						<img
							alt="Upload thumbnail"
							src={`${API_ENDPOINT_HTTP}/uploads/cover_image/${upload.upload_id}/128.jpg`}
							className="h-16 max-w-10 rounded-xs shadow"
						/>
						<div className="flex min-w-0 flex-col">
							<h1 className="line-clamp-2 min-w-0 whitespace-break-spaces pr-4 font-semibold text-neutral-800 text-sm">
								{formatTitle({
									title: upload.upload_title,
									subtitle: upload.upload_subtitle,
									filename: upload.file_name,
								})}
							</h1>
							<h2 className="min-w-0 text-neutral-600 text-sm">
								{formatAuthors(upload.upload_authors ?? [])}
								{upload.upload_year_published &&
									`, ${upload.upload_year_published}`}
							</h2>
							{upload.upload_publisher && (
								<h2 className="min-w-0 truncate text-neutral-600 text-sm">
									{upload.upload_publisher}
								</h2>
							)}
						</div>
					</div>
				</HoverCardContent>
			</HoverCard>
			<div className="flex shrink-0 items-center">
				<div className="flex select-none items-center text-xl ">
					<Tooltip>
						<TooltipTrigger
							className="ml-0.5 rounded-lg px-3 py-2 text-neutral-500 hover:bg-neutral-200 hover:text-neutral-900"
							onClick={() => {
								runInAction(() => {
									appContext.pdfScale = Math.max(
										appContext.pdfScale - 0.25,
										0.25,
									);
								});
							}}
						>
							<MagnifyingGlassMinus weight="bold" />
						</TooltipTrigger>
						<TooltipContent>Zoom out</TooltipContent>
					</Tooltip>
					<Tooltip>
						<TooltipTrigger
							className="rounded-lg px-3 py-2 text-neutral-500 hover:bg-neutral-200 hover:text-neutral-900"
							onClick={() => {
								runInAction(() => {
									appContext.pdfScale = appContext.pdfScale + 0.25;
								});
							}}
						>
							<MagnifyingGlassPlus weight="bold" />
						</TooltipTrigger>
						<TooltipContent>Zoom in</TooltipContent>
					</Tooltip>
				</div>
				<h2 className="ml-8 pr-4 text-neutral-600 text-sm">
					Page{" "}
					<input
						value={currentPageNumber}
						onChange={(e) => {
							const value = Number.parseInt(e.target.value);
							if (value > pdf.document.numPages || value < 1) return;
							setCurrentPageNumber(value);
						}}
						disabled={!pdf}
						className="w-10 rounded border bg-white px-1 py-0.5 shadow-inner outline-none [appearance:textfield] focus:border-neutral-300 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
						type="number"
						onBlur={() => {
							if (currentPageNumber === currentPageIndex + 1) return;
							pdfViewerContext.listRef?.scrollToItem(currentPageNumber - 1);
						}}
					/>{" "}
					of {pdf.document.numPages}
				</h2>

				<DropdownMenu>
					<DropdownMenuTrigger className="rounded-lg px-3 py-2 text-neutral-500 text-xl hover:bg-neutral-200 hover:text-neutral-900">
						<DownloadSimple weight="bold" />
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						<DropdownMenuItem
							onClick={() => {
								appContext.downloadUploadPdf(upload.upload_id as UploadId);
							}}
						>
							Processed PDF
						</DropdownMenuItem>
						<DropdownMenuItem
							onClick={() => {
								appContext.downloadOriginalUploadFile(
									upload.upload_id as UploadId,
								);
							}}
						>
							Original {upload.upload_filetype.toUpperCase()}
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
		</div>
	);
});

type ItemData = {
	pdfScale: number;
	pages: Map<
		{
			pageNumber: number;
		},
		HTMLElement
	>;
	triggerResize: DebouncedFunc<() => void>;
};

enum SpanClass {
	outside = "outside",
	left_edge = "left_edge",
	inside = "inside",
	right_edge = "right_edge",
}

const ClassifySpan = ({
	pageIndex,
	itemIndex,
	firstSpan,
	lastSpan,
}: {
	pageIndex: number;
	itemIndex: number;
	firstSpan: {
		pageIndex: number;
		itemIndex: number;
	};
	lastSpan: {
		pageIndex: number;
		itemIndex: number;
	};
}): SpanClass => {
	// Check if outside the span range
	if (pageIndex < firstSpan.pageIndex || pageIndex > lastSpan.pageIndex) {
		return SpanClass.outside;
	}

	// Check if on the first page of the span
	if (pageIndex === firstSpan.pageIndex) {
		if (itemIndex < firstSpan.itemIndex) return SpanClass.outside;
		if (itemIndex === firstSpan.itemIndex) {
			return SpanClass.left_edge;
		}
	}

	// Check if on the last page of the span
	if (pageIndex === lastSpan.pageIndex) {
		if (itemIndex > lastSpan.itemIndex) return SpanClass.outside;
		if (itemIndex === lastSpan.itemIndex) return SpanClass.right_edge;
	}

	// If none of the above conditions are met, it's inside the span
	return SpanClass.inside;
};

const PDFPage: React.FC<{
	index: number;
	data: ItemData;
	style: React.CSSProperties;
}> = memo(function PDFPage({ index, data, style }) {
	const { pdfScale } = data;
	const pdfViewerContext = usePDFViewerContext();

	const [rendered, setRendered] = useState(false);

	const onRenderSuccess = useCallback<
		NonNullable<PageProps["onRenderSuccess"]>
	>(() => {
		setRendered(true);
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const textRenderer = useCallback<
		NonNullable<PageProps["customTextRenderer"]>
	>(
		(textItem) => {
			if (!pdfViewerContext.highlightResult) return textItem.str;

			const { highlightResult } = pdfViewerContext;

			const { firstSpan, lastSpan, firstSpanCharIdx, lastSpanCharIdx } =
				highlightResult;

			const spanClass = ClassifySpan({
				pageIndex: textItem.pageIndex,
				itemIndex: textItem.itemIndex,
				firstSpan,
				lastSpan,
			});

			if (spanClass === SpanClass.outside) return textItem.str;
			if (spanClass === SpanClass.left_edge) {
				return `${textItem.str.slice(0, firstSpanCharIdx)}<mark>${textItem.str.slice(
					firstSpanCharIdx,
				)}</mark>`;
			}
			if (spanClass === SpanClass.inside) return `<mark>${textItem.str}</mark>`;
			if (spanClass === SpanClass.right_edge) {
				return `<mark>${textItem.str.slice(0, lastSpanCharIdx)}</mark>${textItem.str.slice(
					lastSpanCharIdx,
				)}`;
			}
			throw new Error(`Invalid span class: ${spanClass}`);
		},
		[toJS(pdfViewerContext.highlightResult)],
	);

	return (
		<div {...{ style }} key={index}>
			<div
				className="flex items-center justify-center"
				style={{
					padding: PAGE_PADDING,
				}}
			>
				{/* Used to position the loading state */}
				<div className="relative max-h-max max-w-max">
					<ReactPDFPage
						{...{ pageNumber: index + 1 }}
						{...{ scale: pdfScale }}
						renderAnnotationLayer
						onRenderSuccess={onRenderSuccess}
						className={clsx(
							"overflow-hidden rounded shadow-sm",
							rendered ? "opacity-100" : "opacity-0",
						)}
						customTextRenderer={textRenderer}
					/>
				</div>
			</div>
		</div>
	);
});

const OPTIONS = {
	cMapPacked: true,
	devicePixelRatio: 1,
};

const PDFLoading = () => {
	return (
		<div className="flex grow items-center justify-center">
			<div className="w-48">
				<BarLoader color={"#4A5568"} loading={true} height={4} width={"100%"} />
			</div>
		</div>
	);
};

const PDFViewer: React.FC = memo(
	observer(function PDFViewer() {
		const pdfViewerContext = usePDFViewerContext();

		const pdf = pdfViewerContext.pdf;
		const uploadId = pdfViewerContext.upload.upload_id;

		const appContext = useAppContext();
		const pdfScale = appContext.pdfScale;

		const scrollHeight = useRef(0);
		const [listHeight, setListHeight] = useState(0);

		// If we have a highlight, we need to scroll to it without first loading the document
		// on the first page. Otherwise, we can just initialize the scroll offset to 0.
		const [initialScrollOffset, setInitialScrollOffset] = useState<
			number | null
		>(pdfViewerContext.highlightProps ? null : 0);

		const virtualizedListRef = useRef<VariableSizeList>(null);
		const documentRef = useRef<HTMLDivElement>(null);
		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const elementObserver = useMemo(() => {
			return new ResizeObserver(() => {
				debounce(() => {
					if (!documentRef.current) return;
					const newHeight = documentRef.current.clientHeight;

					if (newHeight !== listHeight) {
						setListHeight(newHeight);
					}
				}, 1000)();
			});
		}, [documentRef]);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(
			function setRef() {
				pdfViewerContext.listRef = virtualizedListRef.current;
			},
			[virtualizedListRef.current],
		);

		useEffect(
			function updateHeight() {
				if (!documentRef) return;
				const element = documentRef.current;
				if (!element) return;

				setListHeight(element.clientHeight);
				elementObserver.observe(element);
				return () => {
					elementObserver.unobserve(element);
				};
			},
			[elementObserver],
		);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			if (!pdfViewerContext.highlightProps) return;
			if (!pdfViewerContext.pdf) return;

			const { pageIndicesToSearch, textToHighlight } =
				pdfViewerContext.highlightProps;
			const pdf = pdfViewerContext.pdf;

			const promises = pageIndicesToSearch.map(async (pageIndex) => {
				const page = await pdf.document.getPage(pageIndex + 1);
				const pageText = await page.getTextContent();
				return {
					pageIndex: pageIndex,
					pageText: pageText,
				};
			});

			Promise.all(promises).then((pageTexts) => {
				const { textStart, textEnd } = textToHighlight;

				const highlightResult = textEnd
					? computeGappedHighlight(textStart, textEnd, pageTexts)
					: computeHighlight(textStart, pageTexts);

				runInAction(() => {
					pdfViewerContext.highlightResult = highlightResult;

					if (initialScrollOffset === null) {
						let totalHeight = 0;
						for (let i = 0; i < highlightResult.firstSpan.pageIndex; i++) {
							const dimension = pdf.pageDimensions.get(i);
							if (!dimension) {
								Sentry.captureMessage(
									`No dimension found for page ${i} in PDFViewer`,
								);
								return;
							}
							totalHeight += dimension[1] * pdfScale;
						}
						setInitialScrollOffset(totalHeight);
					} else {
						pdfViewerContext.listRef?.scrollToItem(
							pdfViewerContext.highlightResult.firstSpan.pageIndex,
						);
					}
				});
			});
		}, [pdfViewerContext.highlightProps, pdfViewerContext.pdf]);

		const setPdf = (document: PDFDocumentProxy) => {
			const promises = Array.from(
				{ length: document.numPages },
				(_, i) => i + 1,
			).map((pageNumber) => {
				return document.getPage(pageNumber);
			});

			// Assuming all pages may have different heights. Otherwise we can just
			// load the first page and use its height for determining all the row
			// heights.
			Promise.all(promises).then((pages) => {
				const pageDimensions = new Map<number, [number, number]>();

				let totalPageHeight = 0;

				for (const page of pages) {
					const w = page.view[2] - page.view[0] + PAGE_PADDING * 2;
					const h = page.view[3] - page.view[1] + PAGE_PADDING * 2;
					totalPageHeight += h;

					pageDimensions.set(page._pageIndex, [w, h]);
				}

				const averagePageHeight = totalPageHeight / document.numPages;

				runInAction(() => {
					pdfViewerContext.pdf = {
						document,
						pageDimensions,
						averagePageHeight,
					};
				});
			});
		};

		const [oldScale, setOldScale] = useState(pdfScale);

		useEffect(
			function handleResize() {
				if (!virtualizedListRef.current) {
					return;
				}
				virtualizedListRef.current.resetAfterIndex(0);
				if (oldScale === pdfScale) return;
				virtualizedListRef.current.scrollTo(
					(scrollHeight.current * pdfScale) / oldScale,
				);
				setOldScale(pdfScale);
			},
			[pdfScale, oldScale],
		);

		const [file, setFile] = useState<{ data: Uint8Array } | null>(null);
		const didInit = useRef(false);
		useEffect(() => {
			if (didInit.current) return;
			didInit.current = true;

			downloadPdf(uploadId, {
				responseType: "arraybuffer",
			}).then((resp) => {
				setFile({ data: new Uint8Array(resp.data as ArrayBuffer) });
			});
		}, [uploadId]);

		return (
			<div className="flex h-full w-full flex-col">
				<PDFNavbar />
				<div
					ref={documentRef}
					className="min-h-0 grow overflow-y-auto scroll-smooth bg-neutral-100 shadow-inner"
				>
					<Document
						file={file}
						onLoadSuccess={(document: PDFDocumentProxy) => {
							setPdf(document);
						}}
						loading={<PDFLoading />}
						noData={<PDFLoading />}
						options={OPTIONS}
						className="flex h-full grow flex-col items-center justify-center"
						onItemClick={(e) => {
							if (!virtualizedListRef.current) {
								Sentry.captureMessage(
									"Virtualized list ref not found in PDFViewer on item click",
								);
								return;
							}
							virtualizedListRef.current.scrollToItem(e.pageIndex);
						}}
					>
						{/* Don't render the document until we have all page dimensions! */}
						{pdf && initialScrollOffset !== null && (
							<VariableSizeList
								ref={virtualizedListRef}
								width={"100%"}
								height={listHeight}
								itemCount={pdf.document.numPages ?? 0}
								itemSize={(index: number) => {
									const dimension = pdf.pageDimensions.get(index);
									if (!dimension) {
										console.error("No dimension found for page", index);
										return 768;
									}
									return dimension[1] * pdfScale;
								}}
								overscanCount={2}
								className={clsx("py-2 transition-opacity duration-500")}
								itemData={{
									pdfScale,
								}}
								onItemsRendered={({ visibleStopIndex }) => {
									pdfViewerContext.setCurrentPageIndex(visibleStopIndex);
								}}
								onScroll={({ scrollOffset }) => {
									scrollHeight.current = scrollOffset;
								}}
								initialScrollOffset={initialScrollOffset}
								// This allows the list to inititate with the correct total height,
								// preventing a flicker when scrolling down
								estimatedItemSize={pdf.averagePageHeight * pdfScale}
							>
								{PDFPage}
							</VariableSizeList>
						)}
					</Document>
				</div>
			</div>
		);
	}),
);

export const SearchPDFViewer: React.FC<{
	upload: Upload;
	highlight: HighlightProps | null;
}> = ({ upload, highlight }) => {
	const searchContext = useSearchContext();
	return (
		<PDFViewerProvider
			{...{ upload: upload, highlight }}
			key={upload.upload_id}
			connectToParent={(viewerState: PDFViewerState) => () => {
				searchContext.viewerState = viewerState;
				return () => {
					searchContext.viewerState = null;
				};
			}}
		>
			<PDFViewer />
		</PDFViewerProvider>
	);
};

export const ResearchPDFViewer: React.FC<{
	upload: Upload;
	highlight: HighlightProps | null;
}> = ({ upload, highlight }) => {
	const chatContext = useChatContext();
	return (
		<PDFViewerProvider
			{...{ upload: upload, highlight }}
			key={upload.upload_id}
			connectToParent={(viewerState: PDFViewerState) => () => {
				chatContext.viewerState = viewerState;
				return () => {
					chatContext.viewerState = null;
				};
			}}
		>
			<PDFViewer />
		</PDFViewerProvider>
	);
};

export const LibraryPDFViewer: React.FC<{
	upload: Upload;
}> = ({ upload }) => {
	return (
		<PDFViewerProvider
			{...{ upload: upload }}
			highlight={null}
			key={upload.upload_id}
			connectToParent={(_: PDFViewerState) => () => () => {}}
		>
			<PDFViewer />
		</PDFViewerProvider>
	);
};
