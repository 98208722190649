import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { useAppContext } from "@/contexts/AppContext";
import recessionChatHistory from "@/data/20thCenturyRecessionsInquiry.json";
import type { ChatHistoryItem } from "@api/schemas";
import { ArrowSquareOut, BookOpenText, Info } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";

export const ChatEmptyState = observer(() => {
	const appContext = useAppContext();

	const action_history = (
		recessionChatHistory.history as Array<ChatHistoryItem>
	)
		.sort((a, b) => a.step_idx - b.step_idx)
		.slice();

	// copy the action history to avoid modifying the original
	const initialMessages = action_history.slice();

	return (
		<div className="flex h-full min-h-0 max-w-xl flex-col items-center justify-center space-y-6 px-4">
			<div className="flex flex-col items-center space-y-2">
				<div className="flex items-center justify-center rounded-md border p-2">
					<BookOpenText size={32} className="text-neutral-600" />
				</div>
				<h1 className="text-center font-semibold text-2xl text-neutral-800">
					Ask a research question
				</h1>
				<a
					className="flex items-center space-x-1 text-neutral-600 text-sm"
					href={
						"https://vllg.notion.site/How-does-the-research-assistant-work-77ae2b1dc43a48a69ce3260e68d3b4e8"
					}
					target="_blank"
					rel="noreferrer"
				>
					<span>How does the research assistant work?</span>
					<ArrowSquareOut size={16} className="shrink-0" />
				</a>
			</div>

			<div className="grid grid-cols-1 gap-4">
				<button
					className="flex flex-col space-y-4 overflow-hidden rounded-lg border p-5 shadow hover:bg-neutral-50"
					type="button"
					onClick={() => {
						appContext.navigateToNewChat({
							initialMessages: initialMessages,
							chatTitle: "US Recessions",
						});
					}}
				>
					<Badge variant="secondary">Use Case</Badge>

					<div className="flex flex-col space-y-1 px-2 text-left text-neutral-950">
						<h2 className="text-left font-semibold text-base text-neutral-800">
							Running searches in parallel over a table
						</h2>
						<span className="text-sm">
							This example conversation shows how you would create a table of US
							recessions and determine if they were correctly forecasted.
						</span>
					</div>
					<Alert className="bg-neutral-50">
						<Info size={18} />
						<AlertTitle className="mb-1 text-left font-medium text-neutral-900 leading-none tracking-tight">
							Tip: Break apart complex queries
						</AlertTitle>
						<AlertDescription className="text-left text-neutral-600 text-xs">
							Iterating with the research assistant step-by-step gives you the
							chance to correct mistakes and avoid ambiguity.
						</AlertDescription>
					</Alert>
				</button>
			</div>
		</div>
	);
});
