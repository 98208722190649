import { Favicon } from "@/components/Favicon";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";
import { useSearchContext } from "@/contexts/SearchContext";
import { formatDate } from "@/lib/utils";
import type {
	FeedChannel,
	FeedItemMetadata,
	SearchFeedItemsResultOutput as SearchFeedItemsResult,
} from "@api/schemas";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { forwardRef, useCallback } from "react";
import Markdown from "react-markdown";

export const SearchFeedItemsResultComponent = observer(
	forwardRef(
		(
			{
				result,
				feedItem,
				feedChannel,
				focused,
				searchResultsContainerIsFocused,
				selectCallback,
			}: {
				result: SearchFeedItemsResult;
				feedItem: FeedItemMetadata;
				feedChannel: FeedChannel;
				focused: boolean;
				searchResultsContainerIsFocused: boolean;
				selectCallback: () => void;
			},
			ref: React.Ref<HTMLDivElement>,
		) => {
			const searchContext = useSearchContext();

			// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
			const onSelect = useCallback(() => {
				runInAction(() => {
					searchContext.activeSearchResult = {
						...result,
					};
				});
				selectCallback();
			}, [result]);

			return (
				<HoverCard open={false}>
					<HoverCardTrigger>
						<div
							className={clsx(
								"cursor-pointer p-4",
								searchContext.activeSearchResult?.type === "feed_items" &&
									searchContext.activeSearchResult?.feed_item_chunk_id ===
										result.feed_item_chunk_id
									? "border-neutral-200 bg-neutral-100"
									: "hover:bg-neutral-50",
							)}
							ref={ref}
							style={{
								boxShadow: focused
									? searchResultsContainerIsFocused
										? "inset 4px 0 0 #a3a3a3"
										: "inset 4px 0 0 #e5e5e5"
									: "",
							}}
							onClick={onSelect}
							onKeyDown={onSelect}
						>
							<div className="ml-1 flex items-center gap-2">
								{feedChannel.feed_channel_link ? (
									<Favicon
										url={feedChannel.feed_channel_link || ""}
										alt={feedChannel.file_name || ""}
										className="h-8 w-8 shrink-0 rounded-md bg-neutral-200"
									/>
								) : (
									<div className="h-8 w-8 shrink-0 rounded-md bg-neutral-200" />
								)}

								<div className="min-w-0 truncate">
									<h2 className="min-w-0 truncate text-sm leading-4">
										{feedItem.file_name}
									</h2>
									<h3 className="text-neutral-500 text-sm leading-4">
										{feedItem.feed_item_author}
										{feedItem.feed_item_pub_date &&
											`, ${formatDate(feedItem.feed_item_pub_date)}`}
									</h3>
								</div>
							</div>

							<div className="mt-2 flex w-full items-start justify-center overflow-hidden rounded-lg border border-neutral-200 bg-white">
								<Markdown className="prose prose-neutral prose-sm h-28 w-[175%] min-w-0 shrink-0 scale-50 opacity-70">
									{result.feed_item_chunk_text}
								</Markdown>
							</div>
						</div>
					</HoverCardTrigger>
					<HoverCardContent
						side="bottom"
						align="start"
						className="w-96 rounded-lg p-0"
						collisionPadding={8}
					>
						<div className="flex items-center justify-start gap-4 p-4 text-sm">
							{feedChannel.feed_channel_link ? (
								<Favicon
									url={feedChannel.feed_channel_link}
									alt={feedChannel.file_name}
									className="h-12 w-12 shrink-0 rounded-md bg-neutral-200"
								/>
							) : (
								<div className="h-12 w-12 shrink-0 rounded-md bg-neutral-200" />
							)}

							<div className="min-w-0 truncate">
								<h2 className="min-w-0 truncate font-semibold">
									{feedItem.file_name}
								</h2>
								<h3 className="text-neutral-900">
									{feedItem.feed_item_author}
									{feedItem.feed_item_pub_date &&
										`, ${formatDate(feedItem.feed_item_pub_date)}`}
								</h3>
								{feedChannel?.file_name && (
									<h3 className="text-neutral-500">{feedChannel.file_name}</h3>
								)}
							</div>
						</div>

						<div className="mt-2 flex w-full items-start justify-center overflow-hidden rounded-b-lg border-neutral-200 border-t">
							<Markdown className="prose prose-neutral prose-sm h-64 w-[175%] min-w-0 shrink-0 scale-50 opacity-70">
								{result.feed_item_chunk_text}
							</Markdown>
						</div>
					</HoverCardContent>
				</HoverCard>
			);
		},
	),
);
