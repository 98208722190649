import { Favicon } from "@/components/Favicon";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Skeleton } from "@/components/ui/skeleton";
import { useAppContext } from "@/contexts/AppContext";
import type { FeedChannelId } from "@/idGenerators";
import type { FeedChannel } from "@api/schemas";
import { CheckCircle } from "@phosphor-icons/react";
import clsx from "clsx";
import flexsearch from "flexsearch";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";

const index = new flexsearch.Document({
	tokenize: "full",
	document: {
		id: "feed_channel_id",
		index: [
			"feed_channel_title",
			"feed_channel_subtitle",
			"feed_channel_description",
			"feed_channel_link",
		],
	},
});

interface MultiSelectProps {
	selectedFeedChannelIds: Set<FeedChannelId>;
	onChange: React.Dispatch<FeedChannelId[]>;
	popoverTrigger: React.ReactNode;
	className?: string;
}

export const FeedSelector: React.FC<MultiSelectProps> = observer(
	({
		selectedFeedChannelIds,
		onChange,
		popoverTrigger,
		className,
		...props
	}) => {
		const appContext = useAppContext();
		const [open, setOpen] = React.useState(false);

		const [feedsQuery, setFeedsQuery] = useState("");

		useEffect(() => {
			for (const channel of appContext.sortedFeedChannels ?? []) {
				index.add(channel);
			}
		}, [appContext.sortedFeedChannels]);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		const matchedChannels = useMemo(() => {
			if (!feedsQuery.trim()) {
				return appContext.sortedFeedChannels;
			}

			const searchResults = index.search(feedsQuery);

			const feedChannelIds = searchResults
				.flatMap((result) => result.result)
				.filter((uploadId, index, self) => self.indexOf(uploadId) === index);

			const feedChannels = feedChannelIds
				.map((id) => appContext.feedChannelsById?.get(id as FeedChannelId))
				.filter(Boolean) as FeedChannel[];
			return feedChannels;
		}, [feedsQuery, appContext.sortedFeedChannels]);

		return (
			<Popover open={open} onOpenChange={setOpen} {...props}>
				<PopoverTrigger asChild>
					<Button
						variant="ghost"
						aria-expanded={open}
						onClick={() => setOpen(!open)}
						className="w-full p-0"
					>
						{popoverTrigger}
					</Button>
				</PopoverTrigger>
				<PopoverContent
					align={"start"}
					collisionPadding={8}
					className="relative flex max-h-[24rem] max-w-[30rem] flex-col overflow-hidden rounded-xl p-0"
					style={{
						width: "var(--radix-popover-content-available-width)",
						height: "var(--radix-popover-content-available-height)",
					}}
				>
					<div className="w-full px-2 pt-2">
						<Input
							placeholder="Search feeds..."
							value={feedsQuery}
							onChange={(e) => {
								setFeedsQuery(e.target.value);
							}}
						/>
					</div>
					<div className="flex flex-col gap-0.5 p-2">
						{matchedChannels ? (
							matchedChannels.map((channel) => {
								const numItems =
									appContext.sortedFeedItemsByChannel?.get(
										channel.feed_channel_id as FeedChannelId,
									)?.length ?? 0;

								const selected = selectedFeedChannelIds.has(
									channel.feed_channel_id as FeedChannelId,
								);
								return (
									<button
										type="button"
										key={channel.feed_channel_id}
										className={clsx(
											"group flex w-full cursor-pointer items-center gap-2 rounded-lg p-1.5 text-sm",
											selected ? "bg-blue-100" : "hover:bg-blue-50",
										)}
										onClick={() => {
											if (
												selectedFeedChannelIds.has(
													channel.feed_channel_id as FeedChannelId,
												)
											) {
												selectedFeedChannelIds.delete(
													channel.feed_channel_id as FeedChannelId,
												);
												onChange([...selectedFeedChannelIds]);
											} else {
												onChange([
													...selectedFeedChannelIds,
													channel.feed_channel_id as FeedChannelId,
												]);
											}
										}}
									>
										<div className="relative overflow-hidden rounded-lg border border-transparent">
											{channel.feed_channel_link ? (
												<Favicon
													url={channel.feed_channel_link}
													alt={channel.file_name}
													className="h-8 w-8 shrink-0 rounded bg-neutral-100"
												/>
											) : (
												<div className="h-8 w-8 shrink-0 rounded bg-neutral-200" />
											)}
											{selected && (
												<div className="absolute inset-0 z-10 flex h-8 w-8 items-center justify-center bg-blue-500/90 text-center text-white text-xl">
													<CheckCircle weight="fill" />
												</div>
											)}
										</div>
										<div className="min-w-0 grow truncate text-left">
											<h2 className="truncate">{channel.file_name}</h2>
											<h3 className="text-neutral-500">
												{numItems}
												{numItems !== 0 ? " articles" : " article"}
											</h3>
										</div>
									</button>
								);
							})
						) : (
							<>
								<div className="flex w-full gap-2 p-1.5">
									<Skeleton className="h-8 w-8" />
									<Skeleton className="h-8 w-72" />
								</div>
								<div className="flex w-full gap-2 p-1.5">
									<Skeleton className="h-8 w-8" />
									<Skeleton className="h-8 w-72" />
								</div>
								<div className="flex w-full gap-2 p-1.5">
									<Skeleton className="h-8 w-8" />
									<Skeleton className="h-8 w-72" />
								</div>
							</>
						)}
					</div>
				</PopoverContent>
			</Popover>
		);
	},
);
