import type { AppState } from "@/contexts/AppContext";

export enum CommandKPage {
	DEFAULT = "DEFAULT",
	RSS = "RSS",
}

export function popCmdKPage(this: AppState) {
	this.cmdKPages.pop();
}

export function pushCmdKPage(this: AppState, page: CommandKPage) {
	this.cmdKPages.push(page);
}

export function setCmdKOpen(this: AppState, open: boolean) {
	this.cmdKOpen = open;
}

export function openCmdKPage(this: AppState, route: CommandKPage[]) {
	this.cmdKPages = route;
	this.cmdKOpen = true;
}

export function getLastCmdKPage(this: AppState) {
	return this.cmdKPages[this.cmdKPages.length - 1];
}
